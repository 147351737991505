/*Components*/
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./layout/layout.component";

// google charts
import { GoogleChartsModule } from "angular-google-charts";

/*Modules*/
import { MaterialModule } from "./shared/modules/materialmodule.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Inject} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { appRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  InfiniteScrollModule,
  InfiniteScrollDirective
} from "ngx-infinite-scroll";
//import {LoginModule} from "../app/login/login.module";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
/*Services*/
import { TranslateService } from "./shared/services/translateservice.service";
//import { LoginComponent } from "./login/login.component";
import { AuthService } from "./shared/services/auth.service";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { DefaultGuardService } from "./shared/services/defaulguard.service";
import { AuthModule, OidcSecurityService, OidcConfigService, LogLevel, PublicEventsService} from "angular-auth-oidc-client";
import { Http, HttpModule, Headers, Response } from "@angular/http";
import { environment } from "../environments/environment";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { CookieService } from "ngx-cookie-service";
import { SsouserprofileComponent } from "./ssouserprofile/ssouserprofile.component";
import { APIService } from "./shared/services/httpservicefactory.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidationMethods } from "./common/utils/validationmethods";
import { HttpConfigInterceptor } from "./common/interceptors/httpconfig.interceptor.service";
import { SharedModule } from "./shared/modules/sharedmodule.module";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from './shared/classes/calendarformat.component';
import { LogoutComponent } from './shared/logout/logout.component';
import {Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { EmodalHeaderModule } from 'emodal-header';
import {POVTruckGroupModule} from './POVTruckGroup/povtruckGroup.module';
// export function setupTranslateFactory(service: TranslateService): Function {
//   return () => service.use("en", "scpa");
// }
// export function loadConfig(oidcConfigService: OidcConfigService) {
//   return () =>
//     oidcConfigService.load_using_stsServer(environment.identityserverurl);
// }
export function configureAuth(oidcConfigService: OidcConfigService) {
  return () => {
    let client:any = environment.portsconfigs.find(a => a.instance == window.location.origin);
    let clientId = environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim();
    if (client.authConfig)
     {
      oidcConfigService.withConfig({
        stsServer: client.authConfig.identityserverurl,
        redirectUrl: window.location.origin + client.authConfig.portcheckurl,
        postLoginRoute: client.authConfig.postloginroute,
        clientId: clientId,
        responseType: client.authConfig.responsetype,
        scope: client.authConfig.scopessupported,
      postLogoutRedirectUri: clientId && (clientId == "PCGWRR" || clientId == "PCNYNJ" || clientId == "PCPOV" || clientId == "PCSCPA") ? environment.portsconfigs.find(a => a.instance == window.location.origin).cmsRedirectUri : window.location.origin + client.authConfig.portchecklogouturl,
       silentRenew: true,
       silentRenewUrl: `${window.location.origin}/silent-renew.html`,
     useRefreshToken:client.authConfig.useRefreshToken,
        logLevel: LogLevel.Error,
        storage: localStorage,
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/login',
        maxIdTokenIatOffsetAllowedInSeconds: client.authConfig.max_id_token_iat_offset_allowed_in_seconds,
        historyCleanupOff: true,
        issValidationOff: true,
        disableIatOffsetValidation:true,
        renewTimeBeforeTokenExpiresInSeconds: 15          
        // autoUserinfo: false,
    });
     
  }
  else{
    oidcConfigService.withConfig({
          stsServer: environment.identityserverurl,
          redirectUrl: window.location.origin + environment.portcheckurl,
          postLoginRoute: environment.postloginroute,
          clientId: clientId,
          responseType: environment.responsetype,
          scope: environment.scopessupported,
        postLogoutRedirectUri: clientId && (clientId == "PCGWRR" || clientId == "PCNYNJ" || clientId == "PCPOV" || clientId == "PCSCPA") ? environment.portsconfigs.find(a => a.instance == window.location.origin).cmsRedirectUri : window.location.origin + environment.portchecklogouturl,
          silentRenew: true,
          silentRenewUrl: `${window.location.origin}/silent-renew.html`,
          //useRefreshToken:true,
          logLevel: LogLevel.Error,
          storage: localStorage,
          forbiddenRoute: '/forbidden',
          unauthorizedRoute: '/login',
          maxIdTokenIatOffsetAllowedInSeconds: environment.max_id_token_iat_offset_allowed_in_seconds,
          historyCleanupOff: true,
          issValidationOff: true,
          disableIatOffsetValidation:true,
          renewTimeBeforeTokenExpiresInSeconds: 15          
          // autoUserinfo: false,
      });
    }
  }
}


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ForbiddenComponent,
    SsouserprofileComponent,
    LogoutComponent,

    // CustomToolTipComponent,
    // ToolTipRendererDirective
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    SharedModule,
    appRoutingModule,
    HttpModule,
    AuthModule.forRoot(),
    GoogleChartsModule.forRoot(),
    EmodalHeaderModule,
    POVTruckGroupModule,
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },

    AuthService,
    AuthGuardService,
    DefaultGuardService,
    TranslateService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setupTranslateFactory,
    //   deps: [TranslateService],
    //   multi: true
    // },
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    InfiniteScrollDirective,
    InfiniteScrollModule,
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {
  errorMessage: any;
  clientid:string;
  constructor(
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService,
    private oidcConfigService: OidcConfigService,
    private apiService: APIService,
    private route: ActivatedRoute,
    private validate: ValidationMethods,
    private aPIService: APIService,
    private titleService:Title,
    private readonly eventService: PublicEventsService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {


 
    let _evn = this.cookieService.get("_evn");
    if(!_evn){
      localStorage.setItem("pathname", window.location.pathname);
      localStorage.setItem("origin", window.location.origin);

    }
    this.clientid=environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim();
    this.getAppInstanceDetails(environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim());
    this.titleService.setTitle(environment.portsconfigs.find(a => a.instance == window.location.origin).title);
    let ipath:string= environment.portsconfigs.find(a => a.instance == window.location.origin).iconpath;
    this._document.getElementById('appFavicon').setAttribute('href',ipath);
    if(!localStorage.getItem("version_number")){
      let ver=environment.portsconfigs.find(a => a.instance == window.location.origin).versionnumber;
      localStorage.setItem("version_number",ver);
    }
    if(!localStorage.getItem("googleanalytics_id")){
      let googleanalytics=environment.portsconfigs.find(a=>a.instance==window.location.origin).analyticsTrackingId;
     localStorage.setItem("googleanalytics_id",googleanalytics);
    }
  }
  //for new tab
  transfersessions() {
    var sessionStorage_transfer = function (event) {
      if (!event) { event = window.event; } // ie suq
      if (!event.newValue) return;          // do nothing if no value to work with
      if (event.key == 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
      } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue);
        for (var key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
      //window.attachEvent("onstorage", sessionStorage_transfer);
      (<any>window).attachEvent("onstorage", sessionStorage_transfer);
    };


    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage');
    };
  }

  getAppInstanceDetails(clientid: string): any {
    localStorage.setItem("appInstance_Name", this.validate.EnvironmentConfig().clientid);
    this.apiService.getAppInstance(clientid).subscribe(data => {
      localStorage.setItem("app_instance", JSON.stringify(data));
      
    });
  
  }
}
