import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    
    // format(date: Date, displayFormat: Object): string {
    //     if (displayFormat == "input") {
    //         return date.toDateString();
    //     } else {
    //         return date.toDateString();
    //     }
    // }
    getFirstDayOfWeek(): number {
        return 1;
    }

    // getDayOfWeekNames() {
    //     return ["SU","MO", "TU", "WE", "TH", "FR", "SA"];
    // }
}