import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

export const slideInAnimation =
  // trigger('routeAnimations', [
  //   transition('* <=> *', [
  //     style({ position: 'relative' }),
  //     query(':enter, :leave', [
  //       style({
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         width: '100%'
  //       })
  //     ]),
  //     query(':enter', [
  //       style({ left: '-100%'})
  //     ]),
  //     query(':leave', animateChild()),
  //     group([
  //       query(':leave', [
  //         animate('550ms ease-out', style({ left: '100%'}))
  //       ]),
  //       query(':enter', [
  //         animate('550ms ease-out', style({ left: '0%'}))
  //       ])
  //     ]),
  //     query(':enter', animateChild()),
  //    ])
     trigger('fadeAnimation', [
        transition('* => *', [
               query(
              ':enter',
              [style({ opacity: 0 })],
              { optional: true }
            ),
            query(
              ':leave',
              // here we apply a style and use the animate function to apply the style over 0.3 seconds
              [style({ opacity: 0 }), animate('0.3s', style({ opacity: .5 }))],
              { optional: true }
            ),
            query(
              ':enter',
              [style({ opacity: 1 }), animate('0.7s', style({ opacity: 1 }))],
              { optional: true }
            )
          ])       
  ]);