import { Component, OnInit, Inject, Input } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import {
  SaveUserViewModel,
  UserViewModel,
  CustomViewInputModel
} from "../../Interfaces/customview.interface";
import { APIService } from "../../services/httpservicefactory.service";
import { TranslateService } from "../../services/translateservice.service";
import { SharedService } from "../../services/shared.service";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-customviewsave",
  templateUrl: "./customviewsave.component.html",
  styleUrls: ["./customviewsave.component.scss"]
})
export class CustomviewsaveComponent implements OnInit {
  Interface_Uid: string = "";
  View_Data: any = {};
  customViewModel: CustomViewInputModel;
  _userViewModel: UserViewModel;
  errorMessage: any;
  ShowLoader: boolean = false;
  showDuplicateViewMsg: boolean = false;
  iscustomviewempty: boolean = false;
  viewname = "";
  isDefaultView = false;
  loading = false;
  constructor(
    public locale: TranslateService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<CustomviewsaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomViewInputModel,
    private ApiService: APIService,
    private router: Router
  ) {
    this.customViewModel = data;
    this._userViewModel = data.UserInterfaceView;
    this.Interface_Uid = data.Interface_Uid;
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(this.customViewModel);
    });
  }

  ngOnInit() {
    let element = document.getElementsByClassName("cdk-overlay-backdrop")[0];
    element.setAttribute("style", "opacity:0");
    this.viewname = this._userViewModel.UserView_Nm;
    this.isDefaultView = this._userViewModel.Default_Flg;
  }

  onNoClick(): void {
    this._userViewModel.UserView_Nm = this.viewname;
    this._userViewModel.Default_Flg = this.isDefaultView;
    this.customViewModel.UserInterfaceView.UserView_Nm = this.viewname;
    this.customViewModel.UserInterfaceView.Default_Flg = this.isDefaultView;
    this.dialogRef.close(this.customViewModel);
  }

  SaveView(overrideName: boolean): void {
    this._userViewModel.UserView_Nm = this._userViewModel.UserView_Nm.trim();
    if (this._userViewModel.UserView_Nm != "") {
      this.loading = true;
      this.iscustomviewempty = false;
      this.ShowLoader = true;
      let customjson = this._userViewModel;
      let requestjson: SaveUserViewModel = {
        UserView_Id: customjson.UserView_Id,
        AppInterface_Id: customjson.AppInterface_Id,
        User_Uid: this.customViewModel.User_Uid,
        UserView_Nm: customjson.UserView_Nm,
        UserView_Json: JSON.stringify(customjson.UserView_Json),
        Default_Flg: customjson.Default_Flg,
        Active_Flg: true
      };
      if (!overrideName) {
        var duplicaterecord = this.customViewModel.UserSavedViews.find(
          v =>
            v.UserView_Nm == customjson.UserView_Nm &&
            v.UserView_Id != customjson.UserView_Id
        );
        if (duplicaterecord) {
          this.showDuplicateViewMsg = true;
          this.ShowLoader = false;
          return;
        }
      }
      this.ApiService.saveUserInterfaceView(requestjson).subscribe(
        data => {
          let interfaceName = this.router.url.replace("/", "");
          this._userViewModel = JSON.parse(data);
          this._userViewModel.UserView_Json = JSON.parse(
            this._userViewModel.UserView_Json
          );
          this.customViewModel.UserInterfaceView = this._userViewModel;
          localStorage.setItem("customView_" + interfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid, "");
          this.loadUserSavedViews(
            this.customViewModel.AppInterface_Id,
            this.customViewModel.Interface_Uid,
            interfaceName
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.errorMessage = <any>error;
          return false;
        }
      );
    } else {
      this.iscustomviewempty = true;
    }
  }

  loadUserSavedViews(
    lAppInterface_Id: number,
    Interface_Uid: string,
    InterfaceName: string
  ) {
    try {
      this.sharedService.loadCustomViewData(
        lAppInterface_Id,
        Interface_Uid,
        InterfaceName
      );
      setTimeout(() => {
        if (!!localStorage.getItem("customView_" + InterfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)) {
          let customViewData = JSON.parse(
            localStorage.getItem("customView_" + InterfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
          );
          this.customViewModel.UserSavedViews = customViewData.UserSavedViews;
          this.dialogRef.close(this.customViewModel);
          this.ShowLoader = false;
        }
      }, 1000);
    } catch (error) {
      this.errorMessage = <any>error;
      this.ShowLoader = false;
    } finally {
      this.ShowLoader = false;
    }
  }

  onValueChange(value:string){
    if(value.length > 0)
    this.iscustomviewempty=false;
    else
    this.iscustomviewempty=true;

    }
}
