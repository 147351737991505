import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { SharedService } from "./shared.service";

@Injectable()
export class AuthService {
  userProfile: object;
  isAuthorizedSubscription: Subscription;
  isAuthorized: boolean;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService,
    private sharedService: SharedService
  ) { }

  ngOnInit() { }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  getToken() {
    this.oidcSecurityService.getToken();
  }

  clearsession() {
    //this.cookieService.deleteAll();
    
    if(this.cookieService.get("AuthCookie")){
      var v=JSON.parse(this.cookieService.get("AuthCookie"));
      if(v.client_id == environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim())
      {
        this.cookieService.deleteAll("/", environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name")).domainname);
      }
    }
    if (localStorage.getItem("userInfo"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid) !== "") {
      localStorage.removeItem("userInfo"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    }
    localStorage.removeItem("userIntefaces"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("customView_mybooking"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("customView_mycontainer"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("customView_vesselschedule"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("customView_mygroupcodes"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("customView_myjobs"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("DrayUnitStatuses"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("PortFacilities"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("PreGateStatuses"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("ShippingLines"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("MoveTypes"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
    localStorage.removeItem("SizeTypes"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
    localStorage.removeItem("MoveFieldMap"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
    localStorage.removeItem("MoveFieldMask"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
    localStorage.removeItem("TerminalPinConfigurations"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
    localStorage.removeItem("TerminalPregateStatus" +"_"+ environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid);
    localStorage.removeItem("cms_redirect");
    localStorage.removeItem("googleanalytics_id");
    localStorage.removeItem("dr");
    localStorage.removeItem("dv");
  }
}
