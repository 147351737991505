import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService, private oidcSecurityService: OidcSecurityService,) {  }

   ngOnInit() {
    this.authService.clearsession();
    this.oidcSecurityService.logoff();
     
  }


}
