import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from "../../shared/services/translateservice.service";
import { Router } from '@angular/router';
import { APIService } from '../../shared/services/httpservicefactory.service';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from "../../shared/components/confirmdialog/confirmdialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ResponseMessagesComponent } from "../../shared/components/responsemessages/responsemessages.component";
declare var jsPDF: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ]),
    trigger("animationOption2", [
      transition(":leave", [
        animate(500, style({ transform: "translateX(-50%)" }))
      ])

    ])
  ]
})
export class IndexComponent implements OnInit {

  displayedColumns: string[] = ['select', 'GroupName', 'Status', 'MethodOfAssigment', 'Company', 'Remarks', 'dots'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  ShowLoader: boolean = false;
  loading: boolean = false;
  innerheight: any;
  customViewModel: any;
  selectAll: boolean = false
  @ViewChild("watchlisttable") watchlisttable: any;
  pdfcolumnNames: Array<any> = [];
  constructor(public locale: TranslateService, private router: Router, private apiService: APIService,
    public dialog: MatDialog,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.GetTruckGroupByUser();
  }
  expandchild(row: any): any {
  }

  public onScrollDown(): void {
    this.loading = true;
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('edge') > -1) {
      this.ShowLoader = true;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.watchlisttable) {
      let windowheight = window.innerHeight;
      let tableposition = this.watchlisttable._elementRef.nativeElement
        .offsetParent.offsetTop;
      this.innerheight = windowheight - tableposition - 110;
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  Addtrucks() {
    this.router.navigateByUrl('/add-truck-group')
  }

  GetTruckGroupByUser() {
    this.ShowLoader = true;
    this.apiService.GetTruckGroupsByUser("9F4C4E83-6FA1-4023-AF5F-BE00325B8233").subscribe((res: any) => {
      this.ShowLoader = false;
      let array = [];
      res.data.map((el: any, index: any) => {
        array.push({
          GroupName: el.truckgroup_name, MethodOfAssigment: el.methodofassignment, Company: el.truckingcompany_name, Remarks: el.remarks, isCollapsed: 0,
          GroupUID: el.truckgroup_uid, GroupCompanyId: el.truckingcompany_uid,
          trucks: el.trucks, IsSelected: false, active_flg: el.active_flg, DefaultSortIndex: index,
          Status: el.active_flg ? this.locale.data.truckGroupStatusText.active : this.locale.data.truckGroupStatusText.disabled
        });

      });
      this.dataSource.data = array;
      this.selectAll = false;
    });
  }

  onSelectAll() {
    let array = this.dataSource.data;
    array.map((el: any) => {
      el.IsSelected = this.selectAll;
    });
    this.dataSource.data = array;
  }

  OnEdit(Group_Uid: string) {
    this.router.navigate(['edit-truck-group', Group_Uid]);
  }


  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data.sort((a, b) => {
        return this.compare(a.DefaultSortIndex, b.DefaultSortIndex, true);
      });
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'GroupName': return this.compare(a.GroupName, b.GroupName, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);
        case 'MethodOfAssigment': return this.compare(a.MethodOfAssigment, b.MethodOfAssigment, isAsc);
        case 'Company': return this.compare(a.Company, b.Company, isAsc);
        case 'Remarks': return this.compare(a.Remarks, b.Remarks, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  UpdateTruckGroupStatus(groupIds: Array<any>) {
    this.ShowLoader = true;
    this.apiService.UpdateTruckGroupStatus(groupIds).subscribe((res: any) => {
      this.ShowLoader = false;
      const snackBarRef = this.snackBar.openFromComponent(ResponseMessagesComponent, {
        data: { responsemsg: [{ messageDetail:this.locale.data.truckGroupmsg.updateStatusSuccessmsg, messageId: "10001" }], type: 'other' },
        panelClass: "responsemsgs",
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      snackBarRef.afterDismissed().subscribe(info => {
        this.GetTruckGroupByUser();
      });
    });
  }

  OnStatusUpdate(groupUID: string, status: boolean) {
    let confirmdata: any = this.locale.data.truckGroupConfirmBox.singleStatusUpdate;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: confirmdata.title,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "deletepanelclass"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") {
        let data = [{
          TruckGroup_UId: groupUID,
          Active_Flg: !status
        }];
        this.UpdateTruckGroupStatus(data);
      }
    });
  }

  SelectedStatus() {
    let activeselected = this.dataSource.data.filter((el: any) => el.IsSelected == true && el.active_flg == true).length > 0;
    let disabledselected = this.dataSource.data.filter((el: any) => el.IsSelected == true && el.active_flg == false).length > 0;
    return { activeselected: activeselected, disabledselected: disabledselected };
  }

  UpdateStatusMultiple(NewStatus: boolean) {
    let confirmdata: any = this.locale.data.truckGroupConfirmBox.multipleStatusUpdate;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: confirmdata.title,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "deletepanelclass"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") {
        let data = [];
        this.dataSource.data.filter((el: any) => el.IsSelected == true).map((el: any) => {
          data.push({
            TruckGroup_UId: el.GroupUID,
            Active_Flg: NewStatus
          });
        });
        this.UpdateTruckGroupStatus(data);
      }
    });

  }

  DeleteTruckGroup(groupIds: Array<any>) {
    this.ShowLoader = true;
    this.apiService.DeleteTruckGroup(groupIds).subscribe((res: any) => {
      this.ShowLoader = false;
      const snackBarRef = this.snackBar.openFromComponent(ResponseMessagesComponent, {
        data: { responsemsg: [{ messageDetail:this.locale.data.truckGroupmsg.deletetruckgroupSuccessmsg, messageId: "10001" }], type: 'other' },
        panelClass: "responsemsgs",
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      snackBarRef.afterDismissed().subscribe(info => {
        this.GetTruckGroupByUser();
      });
    });
  }

  OnDeleteTruckGroup(groupUID: string) {
    let confirmdata: any = this.locale.data.truckGroupConfirmBox.singleDeleteGroup;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: confirmdata.title,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "deletepanelclass"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") {
        let data = [{
          truckGroup_UId: groupUID,
          delete_Flg: true
        }];
        this.DeleteTruckGroup(data);
      }
    });
  }

  UpdateGroupMultiple() {
    let confirmdata: any = this.locale.data.truckGroupConfirmBox.multipleDeleteGroup;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: confirmdata.title,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "deletepanelclass"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") {
        let data = [];
        this.dataSource.data.filter((el: any) => el.IsSelected == true).map((el: any) => {
          data.push({
            truckGroup_UId: el.GroupUID,
            delete_Flg: true
          });
        });
        this.DeleteTruckGroup(data);
      }
    });

  }


  downloadtoxlsx(): void {
    if (this.dataSource.data.filter((el: any) => el.IsSelected).length <= 0) {
      return;
    }
    this.ShowLoader = true;
    let res: Array<any> = [];
    this.dataSource.data.filter((el: any) => el.IsSelected == true).map(item => {
      res.push(item);
    });
    let xlsxcolumnNames = [];
    this.pdfcolumnNames = [{
      title: this.locale.data.truckGroupColumns.grpName,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupColumns.grpStatus,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupColumns.grpAssigmentMethod,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupColumns.grpCompany,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupColumns.grpRemarks,
      dataKey: ''
    }
    ];
    for (let i = 0; i < res.length; i++) {
      let row = [];
      row.push(res[i].GroupName);
      row.push(res[i].active_flg ? this.locale.data.truckGroupStatusText.active : this.locale.data.truckGroupStatusText.disabled);
      row.push(res[i].MethodOfAssigment);
      row.push(res[i].Company);
      row.push(res[i].Remarks);
      xlsxcolumnNames.push(row);
    }
    this.apiService.exportToExcelFile(
      xlsxcolumnNames,
      "truck_groups",
      this.pdfcolumnNames
    );
    this.ShowLoader = false;
  }

  downloadtopdf(): void {
    if (this.dataSource.data.filter((el: any) => el.IsSelected).length <= 0) {
      return;
    }
    this.ShowLoader = true;
    let res: Array<any> = [];
    this.dataSource.data.filter((el: any) => el.IsSelected == true).map(item => {
      var _tempItem = Object.assign({}, item);
      res.push(_tempItem);
    });
    let row: any;
    row = new MatTableDataSource<any>(res);
    let doc = new jsPDF("l", "in", [1200, 1401]);
    this.pdfcolumnNames = [{
      title: this.locale.data.truckGroupColumns.grpName,
      dataKey: 'grpName'
    },
    {
      title: this.locale.data.truckGroupColumns.grpStatus,
      dataKey: 'grpStatus'
    },
    {
      title: this.locale.data.truckGroupColumns.grpAssigmentMethod,
      dataKey: 'grpAssigmentMethod'
    },
    {
      title: this.locale.data.truckGroupColumns.grpCompany,
      dataKey: 'grpCompany'
    },
    {
      title: this.locale.data.truckGroupColumns.grpRemarks,
      dataKey: 'grpRemarks'
    }
    ];
    let statustext = this.locale.data.truckGroupStatusText;

    doc.autoTable(this.pdfcolumnNames, row.data, {
      createdCell: function (cell, data) {
        if (data.column != null && data.column.index != null) {
          if (data.column.dataKey === "grpName") {
            cell.text = res[data.row.index].GroupName;
          }
          if (data.column.dataKey === "grpStatus") {
            cell.text = res[data.row.index].active_flg ? statustext.active : statustext.disabled;
          }
          if (data.column.dataKey === "grpAssigmentMethod") {
            cell.text = res[data.row.index].MethodOfAssigment;
          }
          if (data.column.dataKey === "grpCompany") {
            cell.text = res[data.row.index].Company;
          }
          if (data.column.dataKey === "grpRemarks") {
            cell.text = res[data.row.index].Remarks;
          }
        }


      }
    });
    doc.save("truck-groups.pdf");
    this.ShowLoader = false;
  }

  IsIconDisabled(): boolean {
    return this.dataSource.data.filter((el: any) => el.IsSelected).length <= 0;
  }

}
