<div class="dismiss">
    <mat-icon class="icondismiss" (click)="dismiss()">close</mat-icon>
</div>
<div *ngIf="type=='other'">
    <ul class="ulmsg">
        <li *ngFor="let msg of data.responsemsg" class="{{'msg_'+ msg.messageId}}">
            <div class="breakword {{'msg_'+ msg.messageId}}">{{msg.messageDetail}}</div>
        </li>
    </ul>
</div>

<div *ngIf="type=='canceljob'">
    <ul class="ulmsg">
        <li *ngFor="let msg of data.responsemsg" class="{{'msg_'+ msg.MessageSeverity}}">
            <div class="breakword {{'msg_'+ msg.MessageSeverity}}">{{msg.MessageText}}</div>
        </li>
    </ul>
</div>