import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {  Component,  OnInit,  ChangeDetectorRef,  HostListener,  NgZone,  ViewChild,  AfterViewInit, Input, Output, EventEmitter} from "@angular/core";
import { FormControl } from "@angular/forms";
import { forkJoin, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { MediaMatcher } from "@angular/cdk/layout";
import {  animate,  state,  style,  transition,  trigger} from "@angular/animations";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import {  Router,  ActivatedRoute,  ChildrenOutletContexts} from "@angular/router";
import {  OidcSecurityService,} from "angular-auth-oidc-client";
import { DomSanitizer } from '@angular/platform-browser';
import { slideInAnimation } from './animation';
import { IInterfaces, IUserInfo, IMenu, IModules, IInterfacesAccess, IInterfaceActions, IUserAffiliations } from '../common/models/userinfomodel';
import { Terminals } from '../common/interfaces/Terminals';
import { UserLandingPageModel, UserLocaleModel, Facility, ShippingLine } from '../common/models/customviewmodel';
import { ExampleFlatNode } from '../common/interfaces/ExampleFlatNode';
import { PaymentCartService } from '../common/services/paymentcart.service';
import { TranslateService } from '../common/services/translate.service';
import { APIService, Languages } from '../common/services/httpservicefactory.service';
import { SharedService } from '../common/services/globalvariable.service';
import { HelpMenu } from '../common/interfaces/HelpMenu';
import { Menu } from '../common/interfaces/Menu';
import { SettingsMenu } from '../common/interfaces/SettingsMenu';
import { PrimaryCompanyRequest } from '../common/interfaces/PrimaryCompanyRequest';
import { CartConfig } from '../common/interfaces/CartConfig';
import { CartItem } from "../common/interfaces/CartItem";

declare var indicateInCartStatus: Function;

@Component({
  selector: "emodal-header",
  templateUrl: "./emodal-header.component.html",
  styleUrls: ["./emodal-header.component.scss"],
  animations: [slideInAnimation],
  providers: [APIService]
  // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]
})
export class EmodalHeaderComponent implements OnInit, AfterViewInit {

  @Output() SearchValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() OnLogOut: EventEmitter<any> = new EventEmitter();
  @Output() OnLanguageChanged: EventEmitter<any> = new EventEmitter();
  @Output() OnInit: EventEmitter<any> = new EventEmitter();
  @Output() OnHelpMenuSelected: EventEmitter<any> = new EventEmitter();
  @Output() UserProfileIConSelected: EventEmitter<any> = new EventEmitter();

  @Input() instance: string;
  @Input() showglobalsearch:boolean = false;
  @Input() headerConfigs: any;
  
  // User Profile Icons or Images
  @Input() enableEmodalProfileImg: boolean = false;
  @Input() profileImgSrc: string = "../../assets/images/user_icon.svg";

  // Payment Cart 
  @Input() enableCart: boolean = false;
  @Input() cartConfigs: CartConfig;
  @Input() cartitems: CartItem = {
    CartEnabled: true,
    CartQuantity: 0,
    CartTotal: 0,
    Format: null,
    SavedQuantity: 0,
    SavedTotal: 0
  };

  // Notification Icons or Images
  @Input() enableNotification: boolean = false;
  @Input() enableNotificationImg: boolean = false;
  @Input() notificationImgSrc: string;

  // Help Icons or Images
  @Input() enableHelp: boolean = false;
  @Input() enableHelpImg: boolean = false;
  @Input() helpImgSrc: string;
  @Input() helpMenus: HelpMenu[] = [];

  // User Profile Menus
  @Input() userProfileMenus: Menu[] = [];

  // Settings
  @Input() enableSetting: boolean = false;
  @Input() enableSettingImg: boolean = false;
  @Input() settingImgSrc: string;
  @Input() languagefile:string;
  @Input() SettingsMenus: SettingsMenu[] = [];

  @Input() title: string = "";
  @Input() logopath: string = "./assets/images/eModal-logo.svg";
  @Input() userinfoendpoint: string = "http://pe1id.uatemodal.com/identity/userinfo";
  @Input() portcheckapibaseurl: string = "http://pe1apigateway.uatemodal.com/PortCheck";
  @Input() emodaldatahubapibaseurl: string = "http://pe1apigateway.uatemodal.com/DataHub";
  @Input() emodalnotificationapibaseurl: string = "http://pe1apigateway.uatemodal.com/Notifications";
  @Input() emodalpregateapibaseurl: string = "http://pe1apigateway.uatemodal.com/Pregate";
  @Input() emodalsecuritymgrurl: string = "http://pe1apigateway.uatemodal.com/SecurityMgr/api";
  
  @Output() ShowCloseChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild("tree") tree: any;

  helpMenu_Uid = '9343de9e-a256-45b4-a4d8-2f97d796ea08';
  userProfileMenu_Uid = '8815f7ac-8377-4b1d-9a2f-a5a954f98411';
  notificationProfile_Uid = '7321c825-5389-485d-a76c-3e2e095f1d50';
  noofreq = 0;
  reqcount = 0;
  overlay = true;
  WindowsHeight: any = "0px";
  selected = "en";
  errorMessage: any;
  over: any;
  showMobileOptions: boolean = false;
  showMobileSearch: boolean = false;
  SelectedGlobalSearch: string = "";
  searchValue: string;
  SerachContainerView: any = [];
  containerno: string = "";
  bookingno: string = "";
  isAction: boolean = false;

  userInfo: IUserInfo;
  iMenu: IMenu;
  lstIMenu: IMenu[];
  iModules: IModules[];
  iInterfaces: IInterfaces[];
  MENU_TREE: Terminals[] = [];
  iInterfacesAccess: IInterfacesAccess[] = [];
  interfaceActions: IInterfaceActions[] = [];
  username: string;
  email: string;
  userLandingPageModel: UserLandingPageModel = null;
  userLocaleModel: UserLocaleModel = null;
  defaultInterfaceUId: string;
  defaultLocale: string;

  DrayUnitStatues: any[] = [];
  PortFacilities: Facility[] = [];
  PreGateStatuses: any[] = [];
  ShippingLines: ShippingLine[] = [];
  adminTitlename: string = "";
  userLandingPage: string="";
  loadOutlet:boolean=false;
  PrimaryCompany:IUserAffiliations[]= [];
  private transformer = (node: Terminals, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      moduleName: node.moduleName,
      name: node.name,
      url: node.url,
      interfaceId: node.interfaceId,
      showInMenuFlg: node.showInMenuFlg,
      interfaceCd: node.interfaceCd,
      interfaceUid: node.interfaceUid,
      is_href: node.is_href,
      level: level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  binding = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  showClose: boolean = false;
  loading: boolean = false;
  typename: string;

  paymentCard_Uid: string = 'af6ea039-2d46-4d1d-ada8-b7e7e8e602bd';
  homepage_UId: string = "30f4101d-dd09-48cc-930a-6af2b05d3a8a";
  language_UId: string = "4e15454f-f659-426d-b4ba-5ab0a0728e5f";
  settings_Module_Uid: string = "2ca9bbb9-6a05-482b-bd39-fab69c04f30f"; 

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public paymentCartService: PaymentCartService,
    private oidcSecurityService: OidcSecurityService,
    private aPIService: APIService,
    private snackBar: MatSnackBar,
    public locale: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    public route: ActivatedRoute,
    //private cookieService: CookieService,
    private zone: NgZone,
    //private validate: ValidationMethods,
    public apiService: APIService,
    public sharedService: SharedService
  ) {
    if(!!localStorage.getItem("appInstance_Name")){
      this.instance = localStorage.getItem("appInstance_Name");
    }
    
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.WindowsHeight = window.innerHeight;
    this.gotosearchdetails();
    
    if (this.searchValue) {
      this.showClose = true;
    } else {
      this.showClose = false;
    }

    this.SearchValueChanged.emit(this.searchValue);
    this.ShowCloseChanged.emit(this.showClose);
  }

  ngOnInit() {
    if(this.headerConfigs){
      this.setConfigs(this.headerConfigs);
    }
    let key = "userInfo_" + this.instance;
    if (this.oidcSecurityService.getToken() != "") {
      if(!localStorage.getItem(key) || localStorage.getItem(key)==""){
        this.getUserIdentityData(this.userinfoendpoint);
      } else {
        this.reuserUserIdentityData();
      }
    }
    window.addEventListener("storage", this.storageEventListener.bind(this), false);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );  
  }
  ngAfterViewInit(): void {
    
    this.apiService.portcheckapibaseurl = this.portcheckapibaseurl;
    this.apiService.emodaldatahubapibaseurl = this.emodaldatahubapibaseurl;
    this.apiService.emodalnotificationapibaseurl = this.emodalnotificationapibaseurl;
    this.apiService.emodalsecuritymgrurl = this.emodalsecuritymgrurl;
    if (this.enableCart && this.cartConfigs.cartUrl && this.cartConfigs.cartIntegrationKey) {
      this.loadScript(
        this.cartConfigs.cartUrl +
        "/Integration/CartIntegrationScript?key="+this.cartConfigs.cartIntegrationKey+"&format=A8&"
      );
      indicateInCartStatus();
    }
    else{
      console.log('cart url or cart integration key is invalid');
    }
  }
  setConfigs(headerConfigs: any) {
    if (headerConfigs.logopath){
      this.logopath = headerConfigs.logopath;
    }

    if (headerConfigs.showglobalsearch){
      this.showglobalsearch = headerConfigs.showglobalsearch;
    }

    if (headerConfigs.enableEmodalProfileImg){
      this.enableEmodalProfileImg = headerConfigs.enableEmodalProfileImg;
      if(headerConfigs.profileImgSrc){
        this.profileImgSrc = headerConfigs.profileImgSrc;
      }
    }

    if (headerConfigs.enableCart){
      this.enableCart = headerConfigs.enableCart;
      if(headerConfigs.cartConfigs){
        this.cartConfigs = headerConfigs.cartConfigs;
      }
    }

    if (headerConfigs.enableNotification){
      this.enableNotification = headerConfigs.enableNotification;
      if (headerConfigs.enableNotificationImg){
        this.enableNotificationImg = headerConfigs.enableNotificationImg;
        this.notificationImgSrc = headerConfigs.notificationImgSrc;
      }
    }

    if (headerConfigs.enableHelp){
      this.enableHelp = headerConfigs.enableHelp;
      if (headerConfigs.enableHelpImg){
        this.enableHelpImg = headerConfigs.enableHelpImg;
        this.helpImgSrc = headerConfigs.helpImgSrc;
      }
    }

    if (headerConfigs.helpMenus){
      this.helpMenus = headerConfigs.helpMenus;
    }

    if (headerConfigs.userProfileMenu){
      this.userProfileMenus = headerConfigs.userProfileMenu;
    }

    if (headerConfigs.enableSetting){
      this.enableSetting = headerConfigs.enableSetting;
      if (headerConfigs.enableSettingImg){
        this.enableSettingImg = headerConfigs.enableSettingImg;
        this.settingImgSrc = headerConfigs.settingImgSrc;
      }
    }

    if (headerConfigs.title){
      this.title = headerConfigs.title;
    }

    if (headerConfigs.userinfoendpoint){
      this.userinfoendpoint = headerConfigs.userinfoendpoint;
    }

    if (headerConfigs.portcheckapibaseurl){
      this.portcheckapibaseurl = headerConfigs.portcheckapibaseurl;
    }

    if (headerConfigs.emodaldatahubapibaseurl){
      this.emodaldatahubapibaseurl = headerConfigs.emodaldatahubapibaseurl;
    }

    if (headerConfigs.emodalnotificationapibaseurl){
      this.emodalnotificationapibaseurl = headerConfigs.emodalnotificationapibaseurl;
    }

    if (headerConfigs.emodalpregateapibaseurl){
      this.emodalpregateapibaseurl = headerConfigs.emodalpregateapibaseurl;
    }

    if (headerConfigs.emodalsecuritymgrurl){
      this.emodalsecuritymgrurl = headerConfigs.emodalsecuritymgrurl;
    }
    if(headerConfigs.languagefile){
      this.languagefile=headerConfigs.languagefile;
    }

  }

  OnNotificationClicked(){
    this.router.navigate(['/notification']);
  }

  HelpMenuClicked(helpMenu: HelpMenu) {
    if(helpMenu.helpmenu_cd ==="PP" || helpMenu.helpmenu_cd === "CP"){
      var feautures = "height=" + (screen.height) + ",width=" + screen.width + "";
      window.open(helpMenu.helpmenu_url, "_blank", feautures);
    }
    else{
      window.location.href = helpMenu.helpmenu_url;
    }
    this.OnHelpMenuSelected.emit(helpMenu);
  }

  UserProfileIConClicked(userProfileMenu: Menu){
    if(userProfileMenu.menu_desc.includes("Log ")){
      this.logout();
    }
    else{
      window.location.href = userProfileMenu.menu_url;
    }
    this.UserProfileIConSelected.emit(userProfileMenu);
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = `var adding = null;
            function PostToCart(items) {
              if (adding == null) {
              }
              $.ajax({
                type: "POST",
                url: "${this.cartConfigs.addItemsUrl}",
                data: { items: items },
                xhrFields: { withCredentials: true },
                success: function(data, status) {
                          var event = new CustomEvent("addToCart", { detail: { param1: data } });
                          window.dispatchEvent(event);
                          indicateInCartStatus();
                        }
              });
              
            }

            function indicateInCartStatus() {
              $.ajax({
                url: "${this.cartConfigs.cartItemsUrl}",
                cache: false,
                xhrFields: { withCredentials: true }
                }).done(function(cartContent) {
                  var event = new CustomEvent("cartItems", {
                  detail: { param1: cartContent }
                  });
              window.dispatchEvent(event);
              });
            }`;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  
  //public loadScript(url: string) {
  //  const body = <HTMLDivElement>document.body;
  //  const script = document.createElement("script");
  //  script.innerHTML = "";
  //  script.src = url;
  //  script.async = false;
  //  script.defer = true;
  //  body.appendChild(script);
  //}
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  myControl = new FormControl();

  GlobalSearchOptions:any[]=[];

  noptions: any[] = [
    {
      name: "Container#",
      value: "mycontainer",
      validate: true,
      Interface_Uid:'b5b7cb3c-f2dd-4543-8ea3-d058511cf826',
    },
    {
      name: "Booking#",
      value: "mybooking",
      validate: false,
      Interface_Uid: 'd347c2ce-a155-46d0-b64f-1c71b1261789',
    },
    {
      name: "GroupCode#",
      value: "mygroupcodes",
      validate: false,
      Interface_Uid:'e75334b6-0644-4512-aa99-f930c5b59f46',
    },
    {
      name: "Vessel",
      value: "vesselschedule",
      validate: false,
      Interface_Uid: '1f740763-d01c-4e5a-8017-ec9d633d7128',
    }
  ];

  languages: Languages[];

  filteredOptions: Observable<any[]>;
  SelectedOption: string = "";
  SearchText: string = "";
  path: string = "";
  readonlyvalue: boolean = false;



  ChangeComponent(item: string) {
    this.SelectedGlobalSearch = item;
    this.showClose = false;
    this.searchValue = "";
    this.SearchValueChanged.emit(this.searchValue);
    this.ShowCloseChanged.emit(this.showClose);
  }

  ClearHeaderSearch(item: string) {
    let i: number = item.indexOf(":");
    const newitem = item.substr(0, i);
    const cno = item.substr(i + 1, item.length);
    if (cno.trim()) {
      for (let i = 0; i < this.noptions.length; i++) {
        if (newitem === this.noptions[i].name) {
          this.router.navigate(["/" + this.noptions[i].value]);
        }
      }
    }
    // this.SelectedOption='';
    this.adminTitlename="";
    this.searchValue = "";
    this.showClose = false;
    this.SearchValueChanged.emit(this.searchValue);
    this.ShowCloseChanged.emit(this.showClose);
  }

  ComponentView(item: string) {
    this.adminTitlename="";
    let i: number = item.indexOf(":");
    if (i == -1) {
      this.snackBar.open("Please enter value for the search", "X", {
        duration: 5000,
        panelClass: "errorMessage",
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      return;
    }
    const newitem = item.substr(0, i);
    const cno = item.substr(i + 1, item.length);
    if (cno.trim()) {
      for (let name of this.noptions) {
        let cont = cno.trim();
        let dname = newitem.replace("#", "");
        dname = dname.trim();
        if (newitem.trim() === name.name && name.validate) {
          if (this.validateinput(cno.trim())) {
            this.router.navigate(["/search" + dname.toLowerCase()], {
              queryParams: { type: dname, value: cont.toUpperCase() }
            });
          } else {
            this.snackBar.open("Invalid " + name.name + " No", "X", {
              duration: 5000,
              panelClass: "errorMessage",
              horizontalPosition: "center",
              verticalPosition: "top"
            });
            return;
          }
        } else if (!name.validate) {
          if (dname == "Vessel") {
            this.router.navigate(["/vesselschedule"], {
              queryParams: { vesselno: cont }
            });
          } else {
            this.router.navigate(["/search" + dname.toLowerCase()], {
              queryParams: { type: dname, value: cont.toUpperCase() }
            });
          }
        }
      }
    }
    if (this.searchValue) {
      this.showClose = true;
    } else {
      this.showClose = false;
    }
    this.SearchValueChanged.emit(this.searchValue);
    this.ShowCloseChanged.emit(this.showClose);
  }
  validateinput(searchinput) {
    if (searchinput.trim() != "") {
      if (searchinput.trim().length == 10 || searchinput.trim().length == 11) {
        let alphabets = searchinput.trim().slice(0, 4);
        let numbers = searchinput.trim().slice(4, searchinput.trim().length);
        if (/^[a-zA-Z]+$/.test(alphabets) && /^[0-9]+$/.test(numbers)) {
          return true;
        }
      }
    }
    return false;
  }

  gotosearchdetails() {
    this.typename = this.route.snapshot.queryParamMap.get("type");
    this.containerno = this.route.snapshot.queryParamMap.get("value");
    this.showClose = this.containerno ? true : false;
    this.searchValue = this.containerno
      ? this.typename + "#:" + this.containerno
      : "";
      this.SearchValueChanged.emit(this.searchValue);
    this.ShowCloseChanged.emit(this.showClose);
  }

  expandMobileOptions() {
    this.showMobileOptions = !this.showMobileOptions;
    this.showMobileSearch = this.showMobileSearch
      ? !this.showMobileSearch
      : false;
  }
  expandMobileSearch() {
    this.showMobileSearch = !this.showMobileSearch;
    this.showMobileOptions = this.showMobileOptions
      ? !this.showMobileOptions
      : false;
  }
  OnDefaultHomepageClicked(menu: SettingsMenu){
    window.location.href = menu.menu_url;
  }
  
  private storageEventListener(event: StorageEvent) {
    let key = "userInfo_" + this.instance;
    if (!localStorage.getItem(key) || localStorage.getItem(key)=="") {
      this.OnLogOut.emit();
      window.location.href = '/login';
    }
  }
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  progressbar(loadval: boolean) {
    this.loading = loadval;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  changeView(nav: any) {

      nav.toggle();
      this.searchValue = "";
      this.showClose = false;
      this.SearchValueChanged.emit(this.searchValue);
      this.ShowCloseChanged.emit(this.showClose);

  }

  setLang(language: string) {
    if(language){
      this.locale.use(language, this.languagefile);
      this.defaultLocale = language;
      console.log(this.defaultLocale);
      this.OnLanguageChanged.emit(language);
    }
  }

  appendedtext: string[] = [];

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.GlobalSearchOptions.filter(
      option => option.name.toLowerCase().indexOf(filterValue) == 0
    );
  }

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  LeftSideNavClicked(item) {
    item.toggle();   
  }


  navigateUser(interfaceurl: string) {
    this.router.navigate(["/adminsettings"], {
      queryParams: { name: interfaceurl }
    });
  }

  logout() {
    this.OnLogOut.emit();
    this.oidcSecurityService.logoff();

  }

  getUserIdentityData(url: string){
   this.aPIService.getuserManagement(url).subscribe(
      async Response => {
        if (Response) {
          let key = "userInfo_" + this.instance;
          localStorage.setItem(key, JSON.stringify(Response));
          this.OnInit.emit();
          this.apiService.UserInfo(key);
          // this.PrimaryCompany=Response.claims.userAffiliations.filter(a=>a.companyType_Cd!='D');
          // console.log( "Primary Company"+this.PrimaryCompany)
          await this.getMenus(Response).then(result => {
            this.loadOutlet=true;
            this.userLandingPage =Response.userPreferences.homePage;
            this.apiService.GetLanguage(Response.app_Uid).subscribe(languages => {
              if(languages){
                this.languages = languages;
              }
            });
           
            this.setLang(Response.userPreferences.language);
            this.SetLandingPage();
            this.getprimarycompany();
          }
          );

        } else {
          this.OnLogOut.emit();
          this.oidcSecurityService.logoff();
        }
      },
      error => {
        console.log(error);
        this.OnLogOut.emit();
        this.oidcSecurityService.logoff();
      }
    );
  }

  reuserUserIdentityData(){
    let key = "userInfo_" + this.instance;
    let userInfo = JSON.parse(localStorage.getItem(key));
    this.OnInit.emit();
    this.apiService.UserInfo(key);
    // this.PrimaryCompany=Response.claims.userAffiliations.filter(a=>a.companyType_Cd!='D');
    // console.log( "Primary Company"+this.PrimaryCompany)
    this.getMenus(userInfo).then(result => {
      this.loadOutlet=true;
      this.userLandingPage =userInfo.userPreferences.homePage;
      this.apiService.GetLanguage(userInfo.app_Uid).subscribe(languages => {
        if(languages){
          this.languages = languages;
        }
      });
     
      this.setLang(userInfo.userPreferences.language);
      this.SetLandingPage();
      this.getprimarycompany();
    }
    );
  }

  hasGlobalSearchAccess() {
    if (localStorage.getItem("userIntefaces_" + this.instance)) {
      var aInterfaces = JSON.parse(localStorage.getItem("userIntefaces_" + this.instance));
      for(let i=0;i<this.noptions.length;i++){
        var intfc=aInterfaces.filter(x => x.interfaceUId == this.noptions[i].Interface_Uid.toLowerCase());
        if(intfc.length > 0){
          this.GlobalSearchOptions.push(this.noptions[i]);
        }
      }
    }

  }

async getMenus(userInfoforMenu: IUserInfo): Promise<void> {
  if (userInfoforMenu) {
    this.username = userInfoforMenu.claims.fullname;
    this.email = userInfoforMenu.email;
    this.sharedService.email = userInfoforMenu.email;
    this.lstIMenu = userInfoforMenu.claims.menu;
    if (this.lstIMenu) {    
    await Promise.all(this.lstIMenu.map(async (module) => {
        this.addModulestoMenu(module.modules);
        this.sharedService.interfaceActions = this.getInterfaceActions(module.modules);
        
        // this.InterfaceActionsLoaded.emit(this.sharedService.interfaceActions);
        // let lstInterfaceAccess = this.getInterfaces(module.modules);
        // if (lstInterfaceAccess.length > 0)
        //  await this.LoadCustomViews(lstInterfaceAccess);
    }));

      if (this.MENU_TREE.length > 0) {
        this.binding.data = this.MENU_TREE;
        setTimeout(asd => {
          try {
            let url = this.router.url;
            if (url.includes("adminsettings")) {
              let stringurl = url.split('name=')[1];
              var re = /%2F/gi;
              url = stringurl.replace(re, "/");
            }
            let node = this.tree.treeControl.dataNodes.filter(a => a.url == url);
            let parentnode = this.tree.treeControl.dataNodes.filter(a => a.moduleName == node[0].moduleName && a.expandable)
            this.tree.treeControl.expand(parentnode[0]);
            if (this.router.url.includes("adminsettings")) {
              this.adminTitlename = "";
              this.adminTitlename = node[0].name;
            }
            else
              this.adminTitlename = "";
          }
          catch{

          }
        }, 1000)
       
      }
    }
  }
}


getInterfaceActions(imodules: IModules[]): IInterfaceActions[] {
  this.helpMenus = [];
  this.userProfileMenus = [];
  this.SettingsMenus = [];
  this.enableCart = false;
  this.enableNotification = false;
  imodules.forEach(imodule => {
    if (imodule.interfaces.length > 0) {
      imodule.interfaces.forEach(element => {
        if (element.showInMenu_Flg && element.interface_Cd != null && imodule.module_Uid == this.helpMenu_Uid)
        {
          this.helpMenus.push(
            {
              helpmenu_cd: element.interface_Cd,
              helpmenu_desc: element.interfaceDsc,
              helpmenu_url: element.interface_Url
            });
        }

        if (element.showInMenu_Flg && imodule.module_Uid === this.settings_Module_Uid)
        {
          this.SettingsMenus.push(
            {
              menu_cd: element.interface_Cd,
              menu_desc: element.interfaceDsc,
              menu_id: '',
              menu_url: element.interface_Url
            });
          
        }

        if(element.showInMenu_Flg && element.interface_Cd != null && element.interface_UId == this.notificationProfile_Uid){
          this.enableNotification = true;
        }

        if(element.showInMenu_Flg && element.interface_UId === this.paymentCard_Uid){
          this.enableCart = true;
        }

        if(element.showInMenu_Flg && imodule.module_Uid == this.userProfileMenu_Uid)
        {
          this.userProfileMenus.push(
            {
              menu_cd: element.interface_Cd,
              menu_desc: element.interfaceDsc,
              menu_url: element.interface_Url
            });
        }
        
        if (!element.showInMenu_Flg && element.interface_Cd != null) {
          this.interfaceActions.push({
            module_Id: imodule.module_Id,
            module_Uid: imodule.module_Uid,
            module_nm: imodule.moduleName,
            interfaces_Id: element.interfaces_Id,
            interface_UId: element.interface_UId,
            interfaceDsc: element.interfaceDsc,
            interface_Cd: element.interface_Cd
          });
        }
      });
    }
  });
  
  if(this.instance === 'PCEMODAL'){
    this.SettingsMenus.push(
      {
        menu_cd: '',
        menu_desc: 'My Primary Company',
        menu_id: 'pcompany',
        menu_url: ''
      });
  }
  
  
  return this.interfaceActions;
}

addModulestoMenu(imodules: IModules[]) {
  this.MENU_TREE = [];
  this.zone.run(() => {
    imodules.forEach(imodule => {
      let modulename: string = imodule.moduleName;
      let children: {
        moduleName: string;
        name: string;
        url: string;
        interfaceId: number;
        interfaceUid: string;
        showInMenuFlg: boolean;
        interfaceCd: string;
        is_href: boolean;
      }[] = [];
      if (
        imodule.interfaces != null &&
        imodule.interfaces != undefined &&
        imodule.interfaces.filter(x => x.showInMenu_Flg == true).length >= 1
      ) {
        if (imodule.showInMenu_Flg) {
          imodule.interfaces.forEach(element => {
            if(element.showInMenu_Flg){
            if (element.interface_Url.includes(this.instance)) {
              element.interface_Url = element.interface_Url.replace(
                this.instance,
                ""
              );
            }

            children.push({
              moduleName: imodule.moduleName,
              name: element.interfaceDsc,
              url: element.interface_Url,
              interfaceId: element.interfaces_Id,
              interfaceUid: element.interface_UId,
              showInMenuFlg: element.showInMenu_Flg,
              interfaceCd: element.interface_Cd,
              is_href: this.isValid(element.interface_Url) ? true : false
            });
            }

          });
          
          this.MENU_TREE.push({
            moduleName: modulename,
            name: modulename ,
            url: "",
            interfaceId: 0,
            interfaceUid: "",
            showInMenuFlg: true,
            interfaceCd: "",
            is_href: false,
            children: children
          });


        }
      }
    });
  });
}

  getInterfaces(imodules: IModules[]): IInterfacesAccess[] {
    this.iInterfacesAccess = [];
    imodules.forEach(imodule => {
      imodule.interfaces.forEach(element => {
        this.iInterfacesAccess.push({
          modShowInMenu_Flg: imodule.showInMenu_Flg,
          interfacesId: element.interfaces_Id,
          interfaceUId: element.interface_UId,
          interfaceDsc: element.interfaceDsc,
          url: element.interface_Url,
          showInMenu_Flg: element.showInMenu_Flg,
          interfaceCd: element.interface_Cd
        });

      });
    });
    localStorage.setItem(
      "userIntefaces_" + this.instance,
      JSON.stringify(this.iInterfacesAccess)
    );
    this.hasGlobalSearchAccess();
    return this.iInterfacesAccess;
  }

  isValid(text) {
    return /\b(http|https)/.test(text);
  }
  SaveLandingPage(item: any) {
    this.userLandingPageModel = {} as UserLandingPageModel;
    this.userLandingPageModel.DefaultInterface_Id = 0;
    this.userLandingPageModel.Interface_Uid = item.interfaceUId;
    this.userLandingPageModel.Interface_Uri = item.url;
    this.userLandingPageModel.User_Uid = this.apiService.User_Uid;
    this.apiService.SaveUserLandingPage(this.userLandingPageModel).subscribe(
      data => {
        this.defaultInterfaceUId = data.Interface_Uid;
        localStorage.setItem("dv", data.Interface_Uid);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  SaveLocale(item: Languages) {
    this.apiService.UpdateLanguage(item.Locale_Cd).subscribe(
      data => {
        if(data){
            this.getUserIdentityData(this.userinfoendpoint);
      } }
    );
  }

  SetLandingPage() {
    if (!localStorage.getItem("dr")){
      localStorage.setItem("dr", this.userLandingPage);     
    }
  }

  @HostListener("window:addToCart", ["$event", "$event.detail.param1"])
  getAddToCart(event, param1){

    console.log('addto cart method called');
    console.log("getAddToCart param1 data 1 ");    
    console.log(event);
    console.log(param1);

    if(param1 != null && param1.Success != null){ //&& param1.Success == false
    const Messages = param1.Messages;
    console.log("getAddToCart param1 data 2  ");
    console.log(Messages);
    let messages = [];
    let messagestring = "";

    for (let i = 0; i < Messages.length; i++) {
      //if (Messages[i].messageseverity == 2) {
        let msg = Messages[i].messagedetail;
        msg = msg + (msg != "" ? " - " + Messages[i].messagetext : Messages[i].messagetext);
        messages.push(msg);
     // }
    }

    console.log("getAddToCart param1 data 3 " )
    console.log(messages);

    if (messages.length > 0) {
      messages.sort((a, b) => { return b.messageId - a.messageId });
      messagestring = messages.join(", ");

      let panelClass = "";
      if(param1.Success == false)
        panelClass = "errorMessage";
      else  panelClass = "sucessMessage";

      console.log("getAddToCart param1 data 4 " )
      console.log(messagestring);

      this.snackBar.open(messagestring, "X", {
        duration: 5000,
        panelClass: panelClass,
        horizontalPosition: "center",
        verticalPosition: "top"
      });
     }          
    }
  }

  @HostListener("window:cartItems", ["$event", "$event.detail.param1"])
  getCartItemsResponse(event, param1) {  

    console.log("param1 data ");    
    console.log(event);
    console.log(param1);
    if(param1 != null && param1.Success == false){
    const Messages = param1.Messages;
    console.log("param1 data " + Messages);
    let messages = [];
    let messagestring = "";

    for (let i = 0; i < Messages.length; i++) {
      if (Messages[i].messageseverity == 2) {
        let msg = Messages[i].messagedetail;
        msg = msg + (msg != "" ? " - " + Messages[i].messagetext : Messages[i].messagetext);
        messages.push(msg);
      }
    }

    if (messages.length > 0) {
      messages.sort((a, b) => { return b.messageId - a.messageId });
      messagestring = messages.join(", ");
    }

    console.log("param1 data " + messagestring);

      this.snackBar.open(messagestring, "X", {
        duration: 5000,
        panelClass: "errorMessage",
        horizontalPosition: "center",
        verticalPosition: "top"
      });
    }
    this.cartitems = event.detail.param1;
  }

  PaymentCartClick() {
    if (this.cartConfigs) {
      window.location.href = this.cartConfigs.cart;
    }
  }

  disableActions(code: string): boolean {
    let lstAction = this.sharedService.interfaceActions.filter(
      x => x.interface_Cd == code
    );
    if (lstAction.length > 0) this.isAction = true;
    else this.isAction = false;
    return this.isAction;
  }

  getprimarycompany(){
    let userinfo:IUserInfo;
    let key = "userInfo_" + this.instance;
    localStorage.getItem(key);
    if(localStorage.getItem(key)){
      userinfo=JSON.parse(localStorage.getItem(key))
     this.PrimaryCompany= userinfo.claims.userAffiliations.filter(a=>a.companyType_Cd!='D');
    }

  }

  changeprimarycompany(company_uid:string , isprimary:boolean){
    let updaterequest:PrimaryCompanyRequest={
      AffiliationStatusCode:"A",
      company_Uid:company_uid,
      isPrimary:isprimary
    }
this.aPIService.updateprimarycompany(updaterequest).subscribe(company=>{
 
    this.getUserIdentityData(this.userinfoendpoint);


})
  }


}
