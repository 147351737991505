<div class="overlay" style="position: fixed;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
z-index: 2;
text-align: center;
background-color: #15191f;">
  <img src="./assets/images/port-logo.gif">
</div>
