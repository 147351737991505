import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "./shared/services/auth.service";
import { Router, RouterOutlet, NavigationEnd } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import {  AuthorizationResult, OidcSecurityService, AuthorizedState } from "angular-auth-oidc-client";
import {
  catchError,
  filter,
  map,
  race,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  tap
} from "rxjs/operators";
import { slideInAnimation } from './animation';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from "./../environments/environment";
import { CookieService } from 'ngx-cookie-service';

declare let ga: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  datacbid:string="";
  title = "PortCheckWebApp";
  tokens: string[] = [];
  themeClass: string;
  analyticsUri: string;
  analyticsTrackingId: string;
  private cookieDiffer: KeyValueDiffer<string, any>;
  private customer: any;
  private clientId:string="";
  cookieChanges:KeyValueChanges<string, any>;
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private overlayContainer: OverlayContainer,
    private cookieService: CookieService,
    private differs: KeyValueDiffers
  ) {
    this.clientId=environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim();
   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log("Google Analytics NavigationEnd "+event.urlAfterRedirects)
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
    
  }

  ngOnInit() {
    let _instance: any;
    this.customer = {};
    
       
    if (!!localStorage.getItem("appInstance_Name")) {
      _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
      this.themeClass = _instance.theme;
      let classList = document.getElementById("root").classList;

      const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(this.themeClass);
    }
  }

  ngOnDestroy(): void {
    // this.oidcSecurityService.resetAuthorizationData(false);
  }


  private onOidcModuleSetup() {
    // console.log("came onOidcModuleSetup");
    // let aCookie = this.cookieService.get("AuthCookie");
    // let _evn = this.cookieService.get("_evn");
    // if (!_evn && aCookie) {
    //   console.log("auth cookie exists");
    //   var key="_isAuthorized_" + localStorage.getItem("appInstance_Name");
    //   if(!localStorage.getItem(key) || localStorage.getItem(key) == "false"){
    //     this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    //   }
    //   let c = JSON.parse(aCookie);
    //   if (c) {
    //     localStorage.getItem("pathname");
    //     this.write("redirect", localStorage.getItem("pathname"));
    //   }
    // }
    // else {
    //   if (window.location) {
    //     let windowHash = window.location.toString();
    //     this.oidcSecurityService.authorizedCallbackWithCode(windowHash);
    //   } else {
    //     this.write("redirect", window.location.pathname);
    //   }
    // }
  }


  private onAuthorizationResultComplete(authorizationResult: AuthorizationResult) {
    // console.log("came onAuthorizationResultComplete");
    const path = this.read('redirect');

    // console.log("authorizationResult : validationResult -- >", authorizationResult.validationResult);
    // console.log("authorizationResult : isRenewProcess -- >", authorizationResult.isRenewProcess);

    let aCookie = this.cookieService.get("AuthCookie");
    let _evn = this.cookieService.get("_evn");
    if (!_evn && aCookie) {
      let c = JSON.parse(aCookie);
      if (c) {
        this.router.navigate([path]);
      } else {
        this.router.navigate(['/login']); // TO DO navigate emodal login page
      }
    }
    else {
      if (authorizationResult.authorizationState === AuthorizedState.Authorized) {
        this.router.navigate([path]);
      } else {
        this.router.navigate(['/login']);
      }
    }
  }

  ngDoCheck(): void {
    if(!this.cookieDiffer && this.cookieService.get("AuthCookie")){
      var v=JSON.parse(this.cookieService.get("AuthCookie"));
      if(v.client_id == this.clientId)
        this.cookieDiffer = this.differs.find(this.customer).create();
    }
    if(this.cookieDiffer && this.cookieService.get("AuthCookie")){
      var v=JSON.parse(this.cookieService.get("AuthCookie"));        
          const changes = this.cookieDiffer.diff(v);
          if (changes) {
            if(v.client_id != this.clientId ){
              this.router.navigate(['/logout']); 
            }
        }            
    }    
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }

    return;
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

}
