import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { UserViewModel, SaveUserViewModel, UserLocaleModel, UserLandingPageModel, CustomViewInputModel } from '../models/customviewmodel';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { apiurls, httpOptions, NA } from '../models/globalconstants';
import { IUserInfo, IUserAffiliations } from '../models/userinfomodel';
import { IFacility,IPort } from '../models/commonmodel';
import { PrimaryCompanyRequest } from '../interfaces/PrimaryCompanyRequest';


export interface Languages {
  Locale_Cd: string;
  Display_Nm: string;
  IsDefault: boolean;
}

export class UserPreferencePayload {
  User_Id: string;
  ApplicationParam_Cd: string;
  Param_Val: string;
}

@Injectable({
  providedIn: 'root'
})

export class APIService implements OnInit {
  //need to add config file for these urls as base url will be change for QA/UAT/PROD
  private getAppInterfaceurl = '/AppInterface/getappinterface';
  private insertUserView = "/CustomView/insertuserview";
  private getUserViews = "/CustomView/getuserviews";
  private deleteUserView = "/CustomView/deleteuserview";
  private getappinstance = '/AppInstance/getappinstance';
  private getUserViewsByUserUid = '/CustomView/getuserviewsbyuseruid';
  private getUserLocale = '/UserLocale/getuserlocale';
  private saveUserLocale = '/UserLocale/saveuserlocale';
  private getUserLandingPage = '/UserLandingPage/getuserlandingpage';
  private saveUserLandingPage = '/UserLandingPage/saveuserlandingpage';
  private getLanguages = "/Common/getLanguage";
  private updateLanguagePreference = "/user/ChangeUserPreference";
  private updateprimarycompanyapi="/user/PrimaryAffiliation";
  public lApp_Id = "";
  public User_Uid = "";
  public UserFacilities = [];
  public adminsettingsTitle = "";
  public usercompanies: {
    app_Id: number,
    companyType_Cd: string,
    company_Id: number,
    company_Nm: string,
    company_Uid: string,
    scac: string,
    interfaces: string[]
  }[] = [];
  preadvicetype: string = "";
  portcheckapibaseurl: string;
  emodalsecuritymgrurl: string;
  emodaldatahubapibaseurl: string;
  emodalnotificationapibaseurl: string;
  appUid: string;
  public facilities: IFacility[] = [];
  public ports: IPort[] = [];  
  public defaultViews: any;
  public customViews: any[] = [];
  errorMessage: any;
  _UserSavedViews: UserViewModel[];
  constructor(private httpClient: HttpClient) {

  }
  ngOnInit() {
  }
  UserInfo(key) {
    if (!!localStorage.getItem(key) && (this.User_Uid == "" || this.ports.length == 0)) {
      let userInfo = JSON.parse(localStorage.getItem(key));
      this.User_Uid = userInfo.user_Uid;
      this.appUid = userInfo.app_Uid;
      this.UserFacilities = userInfo.datascopes;
      this.usercompanies = userInfo.claims.userAffiliations;
      this.ports = [];
      this.facilities = [];
      this.ports = Array.from(new Set(this.UserFacilities.map((item: any) => item.port_Cd)));
      this.facilities = Array.from(new Set(this.UserFacilities.map((item: any) => item.facility_Cd)));
    }
  }
  //Common header attribute
  headers = new HttpHeaders().set("Content-Type", "application/json");

  GetLanguage(appUid): Observable<Languages[]>{
   return this.httpClient.get<Languages[]>(this.emodalsecuritymgrurl + this.getLanguages + '?AppUid=' + appUid).pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  UpdateLanguage(language): Observable<UserPreferencePayload>{
    let payload = new UserPreferencePayload();
    payload.User_Id =  this.User_Uid;
    payload.ApplicationParam_Cd = "L";
    payload.Param_Val = language;

    return this.httpClient.post<UserPreferencePayload>(this.emodalsecuritymgrurl + this.updateLanguagePreference, payload).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );

  }

  GetUserLocale(): Observable<UserLocaleModel> {
    return this.httpClient.get<UserLocaleModel>(this.portcheckapibaseurl
      + this.getUserLocale
      + '?sUser_Uid=' + this.User_Uid)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  SaveUserLocale(userLocaleModel: UserLocaleModel): Observable<any> {
    return this.httpClient.post<string>(this.portcheckapibaseurl + this.saveUserLocale, userLocaleModel).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  SaveUserLandingPage(userLandingPageModel: UserLandingPageModel): Observable<any> {
    return this.httpClient.post<string>(this.portcheckapibaseurl + this.saveUserLandingPage, userLandingPageModel).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getuserManagement(url: string): Observable<IUserInfo> {
    return this.httpClient.get<IUserInfo>(url).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getPortFacilities() {
    return this.httpClient.get<any[]>(this.emodaldatahubapibaseurl + apiurls.portfacilityapi);
  }
  getsizetypes() {
    return this.httpClient.get<any[]>(this.emodaldatahubapibaseurl + apiurls.facilityisoarchtypesapi);
  }

  updateprimarycompany(update:PrimaryCompanyRequest): Observable<IUserAffiliations[]>{
return this.httpClient.post<IUserAffiliations[]>(this.emodalsecuritymgrurl  + this.updateprimarycompanyapi,update).pipe(
  tap(data => {
    return data;
  }),
  catchError(this.handleError)
);
  }

  getViewsByUser(
    lAppInterface_Id: number,
    Interface_Uid: string,
    sUser_Uid: string
  ): Observable<any> {
    if (!!localStorage.getItem("app_instance")  && this.lApp_Id == "") {
      let appinstance = JSON.parse(localStorage.getItem("app_instance"));
      this.lApp_Id = appinstance.appInstance_Id;
    }
    return this.httpClient.get<any[]>(this.portcheckapibaseurl
      + this.getUserViewsByUserUid + '?lApp_Id= ' + this.lApp_Id
      + '&Interface_Uid=' + Interface_Uid
      + '&lAppInterface_Id=' + lAppInterface_Id
      + '&sUser_Uid=' + sUser_Uid).pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  loadCustomViewData(
    lAppInterface_Id: number,
    Interface_Uid: string,
    custromViewType: string
  ) {
    this.UserInfo("userInfo");

    return new Promise(resolve=>{
      this.getViewsByUser(lAppInterface_Id, Interface_Uid, this.User_Uid)
      .subscribe(
        data => {
          this.defaultViews = "";
          this.customViews = [] = [];
          let customViewModel: CustomViewInputModel = null;
          let _DefaultView: UserViewModel = null;

          _DefaultView = {
            UserView_Id: 0,
            AppInterface_Id: 0,
            User_Uid: "",
            UserView_Nm: "",
            UserView_Json: "",
            Default_Flg: false,
            DefaultView_Json: "",
            FacilityView_Flg: false
          };

          customViewModel = {
            Interface_Uid: Interface_Uid,
            AppInterfaceView: _DefaultView,
            UserSavedViews: this._UserSavedViews,
            UserInterfaceView: _DefaultView,
            AppInterface_Id: lAppInterface_Id,
            User_Uid: this.User_Uid,
            FilterCriteria: [],
            ColumnOptions: []
          };

          if (data && data.length > 0) {
            this.defaultViews = data.filter(x => x.FacilityView_Flg == true);
            this.customViews = data.filter(y => y.FacilityView_Flg == false);
            if (!!this.defaultViews) {
              _DefaultView.UserView_Id = 0;
              _DefaultView.AppInterface_Id = this.defaultViews[0].AppInterface_Id;
              _DefaultView.User_Uid = customViewModel.User_Uid;
              _DefaultView.UserView_Nm = "";
              _DefaultView.UserView_Json = JSON.parse(
                this.defaultViews[0].DefaultView_Json
              );
              _DefaultView.Default_Flg = false;
              _DefaultView.DefaultView_Json = JSON.parse(
                this.defaultViews[0].DefaultView_Json
              );

              customViewModel.AppInterfaceView = JSON.parse(
                JSON.stringify(_DefaultView)
              );
            }

            if (!!this.customViews && this.customViews.length > 0) {
              customViewModel.AppInterface_Id = this.customViews[0].AppInterface_Id;
              customViewModel.UserSavedViews = this.customViews;
              customViewModel.UserInterfaceView = this.customViews.find(
                v => v.Default_Flg == true
              );
              if (!!customViewModel.UserInterfaceView) {
                customViewModel.UserInterfaceView.UserView_Json = JSON.parse(
                  customViewModel.UserInterfaceView.DefaultView_Json
                );
              }
            } else {
              customViewModel.UserSavedViews = [];
              customViewModel.UserInterfaceView = Object.assign(
                {},
                _DefaultView
              );
            }

            localStorage.setItem(
              "customView_" + custromViewType,
              JSON.stringify(customViewModel)
            );
            resolve(customViewModel);
          }
        },
        error => {
          this.errorMessage = <any>error;
          resolve({});
        }
      );
    });
    
   
  }

  
  
  //#endregion

  
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }


  

}
