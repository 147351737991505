import { NgModule } from '@angular/core';

import { MatScrollerDirective } from './mat-scroller.directive';

@NgModule({
  declarations: [MatScrollerDirective],
  exports: [MatScrollerDirective],
  imports: [],
  providers: []
})
export class MatInfiniteScrollModule { }
