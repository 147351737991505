import { Component, Inject, OnInit, HostListener, AfterViewInit, AfterViewChecked } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { ValidationMethods } from '../common/utils/validationmethods';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, AfterViewChecked {
  analyticsUri: string;
  analyticsTrackingId: string;
  datacbid: string = "";
  innerheight: any;
  appuid: string;
  cmsvalid: boolean;
  appInstance: string;


  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService,
    public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: any,
    private validate: ValidationMethods,
    private route: ActivatedRoute
  ) {
    this.onResize(Event);
    this.appInstance = environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerheight = window.innerHeight;
    if (this.appInstance == "PCPANAMA") {
      this.innerheight = this.innerheight - 50;
    }
    else if (this.appInstance == "PCSCPA")
      this.innerheight = this.innerheight - 90;
  }
  ngOnInit() {
    this.cmslogin();
    this.createcookieboatgoogleanalytics();
  }

  ngAfterViewChecked() {

  }

  login() {
    if (this.cookieService.get("AuthCookie")) {
      let acookie = JSON.parse(this.cookieService.get("AuthCookie"));
      if (acookie) {
        if (this.appInstance == acookie.client_id) {
          this.authService.login();
        }
        else if (this.appInstance != acookie.client_id) {
          this.router.navigate(["/errorpage"]);
        }
      }
    }

    else {
      this.authService.login();
    }
  }
  register() {
    this.document.location.href = environment.registrationUri + this.validate.EnvironmentConfig().clientid;
  }
  openTrainingPage() {
    let appinstance = localStorage.getItem("appInstance_Name");
    if (appinstance) {
      let portconfig = environment.portsconfigs.find(
        a => a.clientid == appinstance
      );
      window.open(portconfig.emodalvideotutorial, "_blank");
    }
  }

  cmslogin() {
    if (this.appInstance && this.appInstance == "PCGWRR" ) {//Domain for gwrr csm app and pcscpa csm app
      let value;
      this.route.queryParams.subscribe(params => {
        let appuid = params["appuid"];
        let cmsvalid = params["isvalid"];
        value = appuid && cmsvalid ? appuid + cmsvalid : "";
      })

      if (!localStorage.getItem("cms_redirect")) {
        localStorage.setItem("cms_redirect", value);
      }
      let redirect = localStorage.getItem("cms_redirect");
      console.log("Query param:" + redirect)
      if (redirect && value) {
        if (this.cookieService.get("AuthCookie")) {
          let acookie = JSON.parse(this.cookieService.get("AuthCookie"));
          if (acookie) {
            if (this.appInstance == acookie.client_id) {
              this.authService.login();
            }
            else if (this.appInstance != acookie.client_id) {
              this.router.navigate(["/errorpage"]);
            }
          }
        }
        else {
          this.authService.login();
        }
      }

      else {
        localStorage.setItem("cms_redirect", "");
        let cmsuri = environment.portsconfigs.find(a => a.instance == window.location.origin).cmsRedirectUri;
        window.location.href = cmsuri;
      }
    }

   else if (this.appInstance == 'PCEMODAL' || this.appInstance == 'PCSCPA'  || this.appInstance == "PCNYNJ" || this.appInstance == "PCPOV") {
      if (this.cookieService.get("AuthCookie")) {
        let acookie = JSON.parse(this.cookieService.get("AuthCookie"));
        if (acookie) {
          if (this.appInstance == acookie.client_id) {
            this.authService.login();
          }
          else if (this.appInstance != acookie.client_id) {
            this.router.navigate(["/errorpage"]);
          }
        }
      }
      else {
        this.authService.login();
      }
     
    }
  }

  createcookieboatgoogleanalytics(){
    this.analyticsUri = environment.portsconfigs.find(a => a.instance == window.location.origin).analyticsUri;
    this.analyticsTrackingId = environment.portsconfigs.find(a => a.instance == window.location.origin).analyticsTrackingId;
    let enable = environment.portsconfigs.find(a => a.instance == window.location.origin).enablecookiebot;
    if (enable) {
      let datacbid = environment.portsconfigs.find(a => a.instance == window.location.origin).cookiebotid;
      let node = document.createElement('script');
      node.src = 'https://consent.cookiebot.com/uc.js';
      node.setAttribute("data-cbid", datacbid);
      node.type = 'text/javascript';
      node.id = 'Cookiebot';
      // node.setAttribute("data-blockingmode", environment.portsconfigs.find(a => a.instance == window.location.origin).datablockingmode);
      node.async = false;
      console.log(node);
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    //  Global site tag (gtag.js) - Google Analytics 
    let googlrurl = environment.portsconfigs.find(a => a.instance == window.location.origin).analyticsUri;
    let tag = document.createElement('script');
    tag.async = true;
    tag.src = googlrurl;
    tag.type = 'text/plain';
    tag.setAttribute("data-cookieconsent", 'statistics');
    console.log(tag);
    document.getElementsByTagName('head')[0].appendChild(tag);
    let scripttag = document.createElement('script');
    scripttag.src = "googleanalytics-pcscpa.js";
    scripttag.type = 'text/plain';
    scripttag.setAttribute("data-cookieconsent", 'statistics');
    console.log(scripttag);
    document.getElementsByTagName('head')[0].appendChild(scripttag);
  }
}
