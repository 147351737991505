import { Component} from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { SharedService, IInterfacesAccess } from '../shared/services/shared.service';
import { TranslateService } from '../shared/services/translateservice.service';
//import { IUserInfo, IModules, IInterfaceActions } from '../shared/Interfaces/userinfo.interface';
import { Router } from '@angular/router';
import { ValidationMethods } from '../common/utils/validationmethods';
import { Observable, forkJoin } from 'rxjs';
import { APIService } from '../shared/services/httpservicefactory.service';
import { Facility, ITerminalPregatestatus, ShippingLine } from '../shared/Interfaces/customview.interface';
import { IInterfaceActions, IUserInfo, IModules } from 'emodal-header/common/models/userinfomodel';

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent {
  iInterfacesAccess: IInterfacesAccess[] = [];
  interfaceActions: IInterfaceActions[] = [];
  DrayUnitStatues: any[] = [];
  PortFacilities: Facility[] = [];
  PreGateStatuses: any[] = [];
  ShippingLines: ShippingLine[] = [];
  TerminalPregateStatus:ITerminalPregatestatus[]=[];
  emodalHeaderConfigs: any;
  paymentcartitems:number=0;
  GlobalSearchOptions: any[] = [];

  noptions: any[] = [
    {
      name: "Container#",
      value: "mycontainer",
      validate: true,
      Interface_Uid: environment.interfaces.mycontainer,
    },
    {
      name: "Booking#",
      value: "mybooking",
      validate: false,
      Interface_Uid: environment.interfaces.mybooking,
    },
    {
      name: "GroupCode#",
      value: "mygroupcodes",
      validate: false,
      Interface_Uid: environment.interfaces.groupcode,
    },
    {
      name: "Vessel",
      value: "vesselschedule",
      validate: false,
      Interface_Uid: environment.interfaces.vesseschedule,
    }
  ];
    instanceSuffix: string;
    clientid:string;
    jsonfile:string;

  constructor(public authService: AuthService,
    public sharedService: SharedService,
    public locale: TranslateService,
    private router: Router,
    private validate: ValidationMethods,
    private apiService: APIService
  ) {
    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.emodalHeaderConfigs = instanceConfig.emodalHeaderConfigs;
    this.jsonfile=instanceConfig.emodalHeaderConfigs.languagefile;
    this.instanceSuffix = "_" + instanceConfig.clientid;
    this.clientid=instanceConfig.clientid;
    let localstorage_verno=localStorage.getItem("version_number");
    let latest_verno=environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).versionnumber;
    if(localstorage_verno!=latest_verno){
        this.checkversionnumber();
    }
  }

  SearchValue: string = '';
  showclose: boolean = false;

  OnLogOut() {
    this.authService.clearsession();

  }

  OnLanguageChanged(language: any) {
    this.locale.use(language, this.jsonfile);
    let userInfo: IUserInfo = JSON.parse(localStorage.getItem("userInfo" + this.instanceSuffix));
      if (userInfo.userPreferences) {
        this.validate.SetAppDateTimeFormats(userInfo.userPreferences.dateFormat, userInfo.userPreferences.timeFormat);
        this.validate.DatePickerLocale = userInfo.userPreferences.language;
      }
  }

   OnInit() {
    if (!!localStorage.getItem("userInfo" + this.instanceSuffix)) {
      let userInfo: IUserInfo = JSON.parse(localStorage.getItem("userInfo" + this.instanceSuffix));
      if (userInfo.userPreferences) {
        this.locale.use(userInfo.userPreferences.language, this.jsonfile);
        this.validate.SetAppDateTimeFormats(userInfo.userPreferences.dateFormat, userInfo.userPreferences.timeFormat);
        this.validate.DatePickerLocale = userInfo.userPreferences.language;
      }
      let stMenu = userInfo.claims.menu;
      
        this.getModules(stMenu);
    }
  }

  OnHelpMenuClicked(menu) {
    if (menu === "HCON") {

    }
  }


  async LoadCustomViews(iInterfacesAccess: IInterfacesAccess[]) {
    // if (!localStorage.getItem("customView_mycontainer" + this.instanceSuffix) || localStorage.getItem("customView_mycontainer" + this.instanceSuffix) == "" || localStorage.getItem("customView_mycontainer" + this.instanceSuffix) == null) {
    //   let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.mycontainer);
    //   if (item) {
    //     await this.sharedService.loadCustomViewData(
    //       this.validate.EnvironmentConfig().mycontainer_InterfaceId,
    //       item.interfaceUId,
    //       "mycontainer"
    //     );
    //   }
    // }
    if (!localStorage.getItem("customView_mybooking" + this.instanceSuffix) || localStorage.getItem("customView_mybooking" + this.instanceSuffix) == "" || localStorage.getItem("customView_mybooking" + this.instanceSuffix) == null) {
      let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.mybooking);
      if (!!item) {
        await this.sharedService.loadCustomViewData(
          this.validate.EnvironmentConfig().mybooking_InterfaceId,
          item.interfaceUId,
          "mybooking"
        );
      }
    }
    if (!localStorage.getItem("customView_vesselschedule" + this.instanceSuffix) || localStorage.getItem("customView_vesselschedule" + this.instanceSuffix) == "" || localStorage.getItem("customView_vesselschedule" + this.instanceSuffix) == null) {
      let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.vesseschedule);
      if (!!item) {
        await this.sharedService.loadCustomViewData(
          this.validate.EnvironmentConfig().vesselschedule_InterfaceId,
          item.interfaceUId,
          "vesselschedule"
        );
      }
    }
    if (!localStorage.getItem("customView_mygroupcodes" + this.instanceSuffix) || localStorage.getItem("customView_mygroupcodes" + this.instanceSuffix) == "" || localStorage.getItem("customView_mygroupcodes" + this.instanceSuffix) == null) {
      let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.groupcode);
      if (!!item) {
        await this.sharedService.loadCustomViewData(
          this.validate.EnvironmentConfig().groupcodes_InterfaceId,
          item.interfaceUId,
          "mygroupcodes"
        );
      }
    }
    if (!localStorage.getItem("customView_myjobs" + this.instanceSuffix) || localStorage.getItem("customView_myjobs" + this.instanceSuffix) == "" || localStorage.getItem("customView_myjobs" + this.instanceSuffix) == null) {
      let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.myjobs);
      if (!!item) {
        await this.sharedService.loadCustomViewData(
          this.validate.EnvironmentConfig().myjobs_InterfaceId,
          item.interfaceUId,
          "myjobs"
        );
      }
    }
    if (!localStorage.getItem("customView_mygateorder" + this.instanceSuffix) || localStorage.getItem("customView_mygateorder" + this.instanceSuffix) == "" || localStorage.getItem("customView_mygateorder" + this.instanceSuffix) == null) {
      let item = iInterfacesAccess.find(x => x.interfaceUId == environment.interfaces.mygateorder);
      if (!!item) {
        await this.sharedService.loadCustomViewData(
          this.validate.EnvironmentConfig().gateorder_InterfaceId,
          item.interfaceUId,
          "mygateorder"
        );
      }
    }
  }

  async getModules(stMenu) {
    if (stMenu) {
      await Promise.all(stMenu.map(async (module) => {
        this.sharedService.interfaceActions = this.getInterfaceActions(
          module.modules
        );
        let lstInterfaceAccess = this.getInterfaces(module.modules);
        // if (lstInterfaceAccess.length > 0)
        //   await this.LoadCustomViews(lstInterfaceAccess);
      }));
    }
  }

  getInterfaces(imodules: IModules[]): IInterfacesAccess[] {
    imodules.forEach(imodule => {
      imodule.interfaces.forEach(element => {
        this.iInterfacesAccess.push({
          modShowInMenu_Flg: imodule.showInMenu_Flg,
          interfacesId: element.interfaces_Id,
          interfaceUId: element.interface_UId,
          interfaceDsc: element.interfaceDsc,
          url: element.interface_Url,
          showInMenu_Flg: element.showInMenu_Flg,
          interfaceCd: element.interface_Cd
        });

      });
    });
    localStorage.setItem(
      "userIntefaces" + this.instanceSuffix,
      JSON.stringify(this.iInterfacesAccess)
    );
    this.hasGlobalSearchAccess();
    return this.iInterfacesAccess;
  }

  hasGlobalSearchAccess() {
    if (localStorage.getItem("userIntefaces" + this.instanceSuffix)) {
      var aInterfaces = JSON.parse(localStorage.getItem("userIntefaces" + this.instanceSuffix));
      for (let i = 0; i < this.noptions.length; i++) {
        var intfc = aInterfaces.filter(x => x.interfaceUId == this.noptions[i].Interface_Uid.toLowerCase());
        if (intfc.length > 0) {
          this.GlobalSearchOptions.push(this.noptions[i]);
        }
      }
    }

  }

  getInterfaceActions(imodules: IModules[]): IInterfaceActions[] {
    imodules.forEach(imodule => {
      if (imodule.interfaces.length > 0) {
        imodule.interfaces.forEach(element => {
          if (!element.showInMenu_Flg && element.interface_Cd != null) {
            this.interfaceActions.push({
              module_Id: imodule.module_Id,
              module_Uid: imodule.module_Uid,
              module_nm: imodule.moduleName,
              interfaces_Id: element.interfaces_Id,
              interface_UId: element.interface_UId,
              interfaceDsc: element.interfaceDsc,
              interface_Cd: element.interface_Cd
            });
          }
        });
      }
    });

    return this.interfaceActions;
  }


  checkversionnumber(){
    
    let ver=environment.portsconfigs.find(a => a.instance == window.location.origin).versionnumber;
    localStorage.setItem("version_number",ver);
  }
  
}

