import { Component, OnInit, Input, EventEmitter, Output, AfterViewChecked} from "@angular/core";
import { MatDialog} from "@angular/material/dialog";
import { CustomviewfilterComponent } from "./customviewfilter.component";
import { CustomviewsaveComponent } from "./customviewsave.component";
import { animate,style,transition,trigger,query} from "@angular/animations";
import { ViewEncapsulation } from "@angular/core";
import { UserViewModel,  CustomViewInputModel,  ClientCriteria,  BindingOperator,  ColumnOptions} from "../../Interfaces/customview.interface";
import { APIService } from "../../services/httpservicefactory.service";
import { TranslateService } from "../../services/translateservice.service";
import { ValidationMethods } from "../../../common/utils/validationmethods";
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: "app-custom-view",
  templateUrl: "./custom-view.component.html",
  styleUrls: ["./custom-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('test', [
      transition('void => *', [
        style({ overflow: 'hidden' }),
        query('.open-dialog',
          [
            style({ left: '-10%' }),
            animate(
              "1000ms ease-out",
              style({ left: '*' })
            )
          ])
      ]),
      transition('* => void', [
        style({ overflow: 'hidden' }),
        query('.open-dialog',
          [
            style({ left: '*' }),
            animate(
              "1000ms ease-in",
              style({ left: '-10%' })
            )
          ])
      ])
    ])
  ]
})
export class CustomViewComponent implements OnInit {
  @Input() ipModel: CustomViewInputModel;
  @Input() viewName: string;
  // @Input() DefaultView : UserViewModel;
  // @Input() SavedViews : UserViewModel[];
  @Output() ViewDialogClosed: EventEmitter<any> = new EventEmitter<any>();
  SavedViews: UserViewModel[];
  errorMessage: string;
  selectedView: any;
  selectedViewname: string;
  ShowLoader: boolean = false;
  DefaultViewName: string = "";
  instanceSuffix: string;
  public defaultViews: any;
  public customViews: any[] = [];
  _UserSavedViews: UserViewModel[];
  constructor(
    public sharedService: SharedService,
    public locale: TranslateService,
    public dialog: MatDialog,
    private ApiService: APIService,
    private validate: ValidationMethods,
  ) { let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
  this.instanceSuffix = "_" + instanceConfig.clientid;}

  ChangeView(item: UserViewModel): void {
    if (typeof item.UserView_Json == "string")
      item.UserView_Json = JSON.parse(item.UserView_Json);
    this.ipModel.UserInterfaceView = item;
    this.ipModel.FilterCriteria = this.BuildFilterCriteria();
    this.ipModel.ColumnOptions = this.BuildColumnOptions();
    this.selectedViewname = item.UserView_Nm;
    this.ViewDialogClosed.emit(this.ipModel);
  }

  ngOnInit() {
      this.getinitialview();
  }
  Refresh()
  {
    if(this.ipModel!=null)
  this.ViewDialogClosed.emit(this.ipModel);
  }
  getinitialview(){
    if (!this.validate.isEmptyObject(this.ipModel)) {
      this.ipModel = JSON.parse(localStorage.getItem("customView_" + this.viewName + this.instanceSuffix));
    }
    if(this.ipModel.UserSavedViews && this.ipModel.UserSavedViews.length){
      this.SavedViews = this.ipModel.UserSavedViews;
      this.SavedViews = this.SavedViews.sort((a, b) =>
        a.UserView_Nm.localeCompare(b.UserView_Nm)
      );
    }
    this.ipModel.UserInterfaceView = this.BuildUserViewJson();
      this.selectedViewname="";
      if (this.ipModel.UserInterfaceView) {
        this.selectedViewname = this.ipModel.UserInterfaceView.UserView_Nm;
        this.DefaultViewName = this.ipModel.UserInterfaceView.UserView_Nm;
        this.ChangeView(this.ipModel.UserInterfaceView);
      }
      this.ipModel.UserSavedViews.forEach(element => {
        if (element.Default_Flg == true) {
          this.DefaultViewName = element.UserView_Nm;
          this.selectedViewname = element.UserView_Nm;
        }
      });
      this.ipModel.FilterCriteria = this.BuildFilterCriteria();
      this.ipModel.ColumnOptions = this.BuildColumnOptions();
  }

  openFilterDialog(): void {
    let screenwidth = window.innerWidth;
    let popupwidth = "50%";
    let left = "50%";
    if (screenwidth < 750) {
      popupwidth = "80%";
      left = "20%";
    }
    const dialogRef = this.dialog.open(CustomviewfilterComponent, {
      width: popupwidth,
      height: "100%",
      position: { top: "0", left: left },
      data: JSON.parse(JSON.stringify(this.ipModel)),
      panelClass: "myClass",
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementsByClassName("cdk-overlay-backdrop")[0];
      element.setAttribute("style", "opacity:0");
      if (!result) return;

      this.ipModel = result;
      if (
        this.ipModel.UserInterfaceView &&
        this.ipModel.UserInterfaceView.UserView_Nm
      )
        this.selectedViewname = this.ipModel.UserInterfaceView.UserView_Nm;
      else this.selectedViewname = undefined;

      this.ipModel.FilterCriteria = this.BuildFilterCriteria();
      this.ipModel.ColumnOptions = this.BuildColumnOptions();
      this.SavedViews = this.ipModel.UserSavedViews;
      this.SavedViews = this.SavedViews.sort((a, b) =>
        a.UserView_Nm.localeCompare(b.UserView_Nm)
      );
      this.ViewDialogClosed.emit(this.ipModel);
    });
  }

  openSaveDialog(element: any): void {
    const dialogRef = this.dialog.open(CustomviewsaveComponent, {
      width: "450px",
      position: {
        top: element.y + 20 + "px",
        left: window.innerWidth - 460 + "px"
      },
      data: this.ipModel,
      panelClass: "myClass",
      autoFocus: false,
      restoreFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.DefaultViewName = "";
      this.ipModel = result;
      if (this.ipModel.UserInterfaceView)
        this.selectedViewname = this.ipModel.UserInterfaceView.UserView_Nm;
      if (
        this.ipModel.UserInterfaceView &&
        this.ipModel.UserInterfaceView.Default_Flg
      )
        this.DefaultViewName = this.ipModel.UserInterfaceView.UserView_Nm;
      else {
        this.ipModel.UserSavedViews.forEach(element => {
          if (element.Default_Flg == true) {
            this.DefaultViewName = element.UserView_Nm;
          }
        });
      }
      this.SavedViews = this.ipModel.UserSavedViews;
      this.SavedViews = this.SavedViews.sort((a, b) =>
        a.UserView_Nm.localeCompare(b.UserView_Nm)
      );
    });
  }

  MapClientCriteria(
    mem: string,
    oper: BindingOperator,
    vLow: any,
    vHigh: string,
    seprator:string = "AND"
  ): ClientCriteria {
      if (oper == BindingOperator.In) {
        if(mem=="unit_nbr" || mem=="booking_nbr" || mem=="groupcode_nbr" || mem=="preadvice_number" || mem=="gatecode" ||mem=="tags"){
          if(vLow.includes(",")){
            vLow=vLow.split(",");
            vLow = this.MapArraytostring(vLow);
          }
          else if(vLow.includes(" ")){
            vLow=vLow.split(" ");
            vLow = this.MapArraytostring(vLow);
          }
          else if(!(vLow.includes(",")||vLow.includes(" "))){
            if(mem=="unit_nbr" || mem=="booking_nbr" || mem=="groupcode_nbr" ){
              oper = BindingOperator.Contains;
            }
            else if(mem=="preadvice_number" || mem=="gatecode"){
              oper = BindingOperator.Equal;
            }
           // vLow = this.sep(vLow)
          }
        }

        else {
          vLow = this.MapArraytostring(vLow);
        }

      };
    let clientcriteria: ClientCriteria = {
      include: true,
      mem: mem,
      oper: oper,
      vLow: vLow,
      vHigh: vHigh,
      required: true,
      seprator: seprator
    };

    return clientcriteria;
  }

  MapArraytostring(value: string[]): string {
    let sValue = "";
    for (let column of value) {
      if (sValue) sValue = sValue + ",'" + column + "'";
      else sValue = "'" + column + "'";
    }
    return sValue;
  }

  sep(value: any): string {
    let sValue = "";
    let col=value.substr(value.length - 1);
    for (let column of value) {
      if (sValue){
        sValue = sValue + "" + column + "";
      }
      else {
        sValue = "'" + column + "";
      }
    }
    return sValue;
  }


  BuildFilterCriteria(): ClientCriteria[] {

    let clientcriteria: ClientCriteria[] = [];
    if (
      !this.ipModel.UserInterfaceView.Default_Flg &&
      !this.ipModel.UserInterfaceView.FacilityView_Flg &&
      this.ipModel.UserInterfaceView.UserView_Id != 0
    ) {
      if (!this.ipModel.UserInterfaceView.UserView_Json)
        this.ipModel.UserInterfaceView.UserView_Json = JSON.parse(
          this.ipModel.UserInterfaceView.DefaultView_Json
        );
    }
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Container"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Container"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Container"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Container"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Container"]
          .Selected_Value.toUpperCase(),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Booking"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Booking"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Booking"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Booking"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Booking"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCode"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCode"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCode"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCode"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCode"]
            .Selected_Value,
          ""
        )
      );
    if (this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"] && this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"].Visible_Flg && this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"].Selected_Value != "") {
      let inputTradeTypes: string[] = [];
      inputTradeTypes = this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"].Selected_Value;
    }
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TradeType"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ContainerStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ContainerStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ContainerStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ContainerStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ContainerStatus"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingStatus"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLine"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLine"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLine"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLine"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLine"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVessel"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVessel"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVessel"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVessel"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVessel"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVoyage"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVoyage"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVoyage"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVoyage"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingVoyage"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLioyds"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLioyds"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLioyds"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLioyds"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["BookingLioyds"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCodeStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCodeStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCodeStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCodeStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GroupCodeStatus"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Terminal"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Terminal"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Terminal"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Terminal"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Terminal"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Line"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Line"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Line"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Line"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Line"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselName"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselName"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselName"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselName"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselName"]
            .Selected_Value.toLowerCase(),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselCode"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselCode"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselCode"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          'vessel',
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["VesselCode"]
            .Selected_Value.toLowerCase(),
          ""
        )
      );
      if (
        this.ipModel.UserInterfaceView.UserView_Json.Filters["CreatedBy"] &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["CreatedBy"]
          .Visible_Flg &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["CreatedBy"]
          .Selected_Value != ""
      )
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["CreatedBy"]
            .Column_Name,
            BindingOperator.Contains,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["CreatedBy"]
              .Selected_Value.toLowerCase(),
            ""
          )
        );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Voyage"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Voyage"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Voyage"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Voyage"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Voyage"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["PortOfLoad"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["PortOfLoad"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["PortOfLoad"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["PortOfLoad"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["PortOfLoad"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["EmodalPregateStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["EmodalPregateStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["EmodalPregateStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["EmodalPregateStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["EmodalPregateStatus"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TerminalStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TerminalStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TerminalStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TerminalStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TerminalStatus"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Origin"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Origin"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Origin"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Origin"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Origin"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Destination"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Destination"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Destination"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Destination"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Destination"]
            .Selected_Value,
          ""
        )
      );
      if (
        this.ipModel.UserInterfaceView.UserView_Json.Filters["ViewType"] &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["ViewType"]
          .Visible_Flg &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["ViewType"]
          .Selected_Value != ""
      )
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["ViewType"]
              .Column_Name,
            BindingOperator.In,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["ViewType"]
              .Selected_Value,
            ""
          )
        );
    //Vessel Schedule
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Vessel"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Vessel"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Vessel"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Vessel"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Vessel"]
            .Selected_Value,
          ""
        )
      );

    //From Date
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
        .Visible_Flg && this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
        .Visible_Flg &&
      (this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
        .Selected_Value != "" || this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
          .Selected_Value != "")
    ){

      let fromDate = this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"].Selected_Value;
      let toDate = this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"].Selected_Value;
      if(fromDate && toDate){
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
              .Column_Name,
            BindingOperator.Between,
            this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
              .Selected_Value),
            this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
              .Selected_Value)
          )
        );
      }
      else if(fromDate){
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
              .Column_Name,
            BindingOperator.Between,
            this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["FromDate"]
              .Selected_Value),
            this.validate.handleEmptyISODateTime((new Date()).toDateString())
          )
        );
      }
      else if(toDate){
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
              .Column_Name,
            BindingOperator.Between,
            this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
              .Selected_Value),
            this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ToDate"]
              .Selected_Value)
          )
        );
      }


    }

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Voyage"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Voyage"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Voyage"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Voyage"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Vessel_Voyage"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ETA"]
            .Selected_Value),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ETD"]
            .Selected_Value),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ATA"]
            .Selected_Value),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["ATD"]
            .Selected_Value),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Job"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Job"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Job"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Job"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Job"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobStatus"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobStatus"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobOrigin"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobOrigin"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobOrigin"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobOrigin"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobOrigin"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobDestination"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobDestination"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobDestination"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobDestination"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobDestination"]
            .Selected_Value,
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["OriginReservation"]
            .Selected_Value),
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"].Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"]
        .Selected_Value != "" &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"]
        .Selected_Value != null
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["DestinationReservation"]
            .Selected_Value),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TruckingCompany"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TruckingCompany"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TruckingCompany"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TruckingCompany"]
            .Column_Name,
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["TruckingCompany"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Equipment"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Equipment"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Equipment"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Equipment"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["Equipment"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Reference"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["ReferenceType"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Reference"]
        .Visible_Flg && this.ipModel.UserInterfaceView.UserView_Json.Filters["ReferenceType"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["Reference"]
        .Selected_Value != ""
    ){
      let refType = this.ipModel.UserInterfaceView.UserView_Json.Filters["ReferenceType"]
        .Selected_Value;

      if(refType === 'BKG'){
        clientcriteria.push(
          this.MapClientCriteria(
            "bkg_nbr",
            BindingOperator.Equal,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["Reference"]
              .Selected_Value,
            "",
            "AND"
          )
        );
      }
      else if(refType === 'GCD'){
        clientcriteria.push(
          this.MapClientCriteria(
            "gcd_nbr",
            BindingOperator.Equal,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["Reference"]
              .Selected_Value,
            "",
            "AND"
          )
        );
      }

    }

    if (this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"].Visible_Flg &&
      (this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
        .Selected_Value != "" || this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
          .Selected_Value === true || this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
            .Selected_Value === false)){
      if(this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
        .Selected_Value === true || this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
          .Selected_Value === false)
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
              .Column_Name,
            BindingOperator.Equal,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["LineHold"]
              .Selected_Value,
            ""
          )
        );
    }


    if (this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"].Visible_Flg &&
      (this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
        .Selected_Value != "" || this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
          .Selected_Value === true || this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
            .Selected_Value === false)){
      if(this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
        .Selected_Value === true || this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
          .Selected_Value === false)
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
              .Column_Name,
            BindingOperator.Equal,
            this.ipModel.UserInterfaceView.UserView_Json.Filters["CustomHold"]
              .Selected_Value,
            ""
          )
        );
    }

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["LastFreeDate"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["LastFreeDate"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["LastFreeDate"]
        .Selected_Value != ""
    ){
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["LastFreeDate"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["LastFreeDate"]
            .Selected_Value),
          ""
        )
      );
    }


    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["UnloadDate"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["UnloadDate"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["UnloadDate"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["UnloadDate"]
            .Column_Name,
          BindingOperator.Equal,
          this.validate.handleEmptyISODateTime(this.ipModel.UserInterfaceView.UserView_Json.Filters["UnloadDate"]
            .Selected_Value),
          ""
        )
      );

                              if (
                                this.ipModel.UserInterfaceView.UserView_Json.Filters["PreadviceNumber"] &&
                                this.ipModel.UserInterfaceView.UserView_Json.Filters["PreadviceNumber"]
                                  .Visible_Flg &&
                                this.ipModel.UserInterfaceView.UserView_Json.Filters["PreadviceNumber"]
                                  .Selected_Value != ""
                              )
                                clientcriteria.push(
                                  this.MapClientCriteria(
                                    this.ipModel.UserInterfaceView.UserView_Json.Filters["PreadviceNumber"]
                                      .Column_Name,
                                    BindingOperator.In,
                                    this.ipModel.UserInterfaceView.UserView_Json.Filters["PreadviceNumber"]
                                      .Selected_Value,
                                    ""
                                  )
                                );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobPreadviceNumber"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobPreadviceNumber"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobPreadviceNumber"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobPreadviceNumber"]
            .Column_Name,
          BindingOperator.Equal,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobPreadviceNumber"]
            .Selected_Value,
          ""
        )
      );

                                      if (
                                        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateCode"] &&
                                        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateCode"]
                                          .Visible_Flg &&
                                        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateCode"]
                                          .Selected_Value != ""
                                      )
                                        clientcriteria.push(
                                          this.MapClientCriteria(
                                            this.ipModel.UserInterfaceView.UserView_Json.Filters["GateCode"]
                                              .Column_Name,
                                            BindingOperator.In,
                                            this.ipModel.UserInterfaceView.UserView_Json.Filters["GateCode"]
                                              .Selected_Value,
                                            ""
                                          )
                                        );


    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobGateCode"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobGateCode"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["JobGateCode"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobGateCode"]
            .Column_Name,
          BindingOperator.Equal,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["JobGateCode"]
            .Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"]
        .Selected_Value != "" && this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"]
          .Selected_Value != "null"
    ){
      let transship = this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"]
        .Selected_Value;
      if(transship === true){
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["TransshipFlg"]
              .Column_Name,
            BindingOperator.Equal,
            "Y",
            ""
          )
        );
      }
    }

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["AppointmentStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["AppointmentStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["AppointmentStatus"]
        .Selected_Value != ""
    ){
      clientcriteria.push(
        this.MapClientCriteria(
          "org_apptstatus_cd",
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["AppointmentStatus"]
            .Selected_Value,
          "",
          "OR"
        )
      );

      clientcriteria.push(
        this.MapClientCriteria(
          "dest_apptstatus_cd",
          BindingOperator.In,
          this.ipModel.UserInterfaceView.UserView_Json.Filters["AppointmentStatus"]
            .Selected_Value,
          "",
          "AND"
        )
      );
    }
    // Gate order filters
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrder"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrder"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrder"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrder"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters[
            "GateOrder"
          ].Selected_Value.toUpperCase(),
          ""
        )
      );
    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderReferenceNumber"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderReferenceNumber"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderReferenceNumber"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderReferenceNumber"]
          .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters[
            "GateOrderReferenceNumber"
          ].Selected_Value.toUpperCase(),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderStatus"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderStatus"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderStatus"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderStatus"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters[
            "GateOrderStatus"
          ].Selected_Value,
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderFacility"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderFacility"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderFacility"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderFacility"]
          .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters[
            "GateOrderFacility"
          ].Selected_Value.toUpperCase(),
          ""
        )
      );

    if (
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderEquipment"] &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderEquipment"]
        .Visible_Flg &&
      this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderEquipment"]
        .Selected_Value != ""
    )
      clientcriteria.push(
        this.MapClientCriteria(
          this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderEquipment"]
            .Column_Name,
          BindingOperator.Contains,
          this.ipModel.UserInterfaceView.UserView_Json.Filters[
            "GateOrderEquipment"
          ].Selected_Value.toUpperCase(),
          ""
        )
      );

      if (
        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderType"] &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderType"]
          .Visible_Flg &&
        this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderType"]
          .Selected_Value != ""
      )
        clientcriteria.push(
          this.MapClientCriteria(
            this.ipModel.UserInterfaceView.UserView_Json.Filters["GateOrderType"]
              .Column_Name,
            BindingOperator.Contains,
            this.ipModel.UserInterfaceView.UserView_Json.Filters[
              "GateOrderType"
            ].Selected_Value.toUpperCase(),
            ""
          )
        );

        if (
          this.ipModel.UserInterfaceView.UserView_Json.Filters["tags"] &&
          this.ipModel.UserInterfaceView.UserView_Json.Filters["tags"]
            .Visible_Flg &&
          this.ipModel.UserInterfaceView.UserView_Json.Filters["tags"]
            .Selected_Value != ""
        )
          clientcriteria.push(
            this.MapClientCriteria(
              this.ipModel.UserInterfaceView.UserView_Json.Filters["tags"]
                .Column_Name,
              BindingOperator.In,
              this.ipModel.UserInterfaceView.UserView_Json.Filters["tags"]
                .Selected_Value,
              ""
            )
          );

          if (
            this.ipModel.UserInterfaceView.UserView_Json.Filters["Hold"] &&
            this.ipModel.UserInterfaceView.UserView_Json.Filters["Hold"]
              .Visible_Flg &&
            this.ipModel.UserInterfaceView.UserView_Json.Filters["Hold"]
              .Selected_Value != ""
          )
            clientcriteria.push(
              this.MapClientCriteria(
                this.ipModel.UserInterfaceView.UserView_Json.Filters["Hold"]
                  .Column_Name,
                BindingOperator.Equal,
                this.ipModel.UserInterfaceView.UserView_Json.Filters["Hold"]
                  .Selected_Value,
                ""
              )
            );

    return clientcriteria;
  }

  BuildColumnOptions(): string[] {
    let columnoptions: string[] = [];
    if (
      this.ipModel.UserInterfaceView.UserView_Json &&
      this.ipModel.UserInterfaceView.UserView_Json.ColumnOptions
    ) {
      for (let column of this.ipModel.UserInterfaceView.UserView_Json
        .ColumnOptions) {
        if (column.Visible_Flg) columnoptions.push(column.Column_Name);
      }
    }
    return columnoptions;
  }

  //Comparing user view with appinterface view
  BuildUserViewJson(): UserViewModel {
    let interfaceview = JSON.parse(
      JSON.stringify(this.ipModel.AppInterfaceView)
    );
    if (!!this.ipModel.UserInterfaceView) {
      let userinterfaceview = JSON.parse(
        JSON.stringify(this.ipModel.UserInterfaceView)
      );
      let interfaceclmoptions = JSON.parse(
        JSON.stringify(userinterfaceview.UserView_Json.ColumnOptions)
      );
      let clmoptions: ColumnOptions[] = JSON.parse(
        JSON.stringify(interfaceview.UserView_Json.ColumnOptions)
      );
      let userclmoptions: ColumnOptions[] = JSON.parse(
        JSON.stringify(userinterfaceview.UserView_Json.ColumnOptions)
      );
      if (this.CheckFilters("Container", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Container"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Container"].Selected_Value;
      if (this.CheckFilters("Booking", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Booking"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Booking"].Selected_Value;
      if (this.CheckFilters("GroupCode", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["GroupCode"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["GroupCode"].Selected_Value;
      if (this.CheckFilters("ContainerStatus", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ContainerStatus"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ContainerStatus"].Selected_Value;
          
      if (this.CheckFilters("TradeType", interfaceview, userinterfaceview) && userinterfaceview.UserView_Json.Filters["TradeType"])
          interfaceview.UserView_Json.Filters["TradeType"].Selected_Value =
            userinterfaceview.UserView_Json.Filters["TradeType"].Selected_Value;
          if (this.CheckFilters("BookingStatus", interfaceview, userinterfaceview))
          interfaceview.UserView_Json.Filters["BookingStatus"].Selected_Value =
            userinterfaceview.UserView_Json.Filters["BookingStatus"].Selected_Value;
            if (this.CheckFilters("BookingLine", interfaceview, userinterfaceview))
            interfaceview.UserView_Json.Filters["BookingLine"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["BookingLine"].Selected_Value;
              if (this.CheckFilters("BookingVessel", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["BookingVessel"].Selected_Value =
                userinterfaceview.UserView_Json.Filters["BookingVessel"].Selected_Value;
            if (this.CheckFilters("BookingVoyage", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["BookingVoyage"].Selected_Value =
                userinterfaceview.UserView_Json.Filters["BookingVoyage"].Selected_Value;
            if (this.CheckFilters("BookingLioyds", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["BookingLioyds"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["BookingLioyds"].Selected_Value;
            if (this.CheckFilters("TerminalStatus", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["TerminalStatus"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["TerminalStatus"].Selected_Value;
              if (this.CheckFilters("LastFreeDate", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["LastFreeDate"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["LastFreeDate"].Selected_Value;
              if (this.CheckFilters("UnloadDate", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["UnloadDate"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["UnloadDate"].Selected_Value;
              if (this.CheckFilters("PreadviceNumber", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["PreadviceNumber"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["PreadviceNumber"].Selected_Value;
              if (this.CheckFilters("GateCode", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["GateCode"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["GateCode"].Selected_Value;
              if (this.CheckFilters("GroupCodeStatus", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["GroupCodeStatus"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["GroupCodeStatus"].Selected_Value;
              
              if (this.CheckFilters("Tags", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["Tags"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["Tags"].Selected_Value;


              if (this.CheckFilters("Job", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["Job"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["Job"].Selected_Value;
              if (this.CheckFilters("JobStatus", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["JobStatus"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["JobStatus"].Selected_Value;
              if (this.CheckFilters("JobOrigin", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["JobOrigin"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["JobOrigin"].Selected_Value;
              if (this.CheckFilters("JobDestination", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["JobDestination"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["JobDestination"].Selected_Value;
              if (this.CheckFilters("FromDate", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["FromDate"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["FromDate"].Selected_Value;
              if (this.CheckFilters("ToDate", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["ToDate"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["ToDate"].Selected_Value;
              if (this.CheckFilters("AppointmentStatus", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["AppointmentStatus"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["AppointmentStatus"].Selected_Value;
              if (this.CheckFilters("TruckingCompany", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["TruckingCompany"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["TruckingCompany"].Selected_Value;
              if (this.CheckFilters("Equipment", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["Equipment"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["Equipment"].Selected_Value;
              if (this.CheckFilters("JobPreadviceNumber", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["JobPreadviceNumber"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["JobPreadviceNumber"].Selected_Value;
              if (this.CheckFilters("ReferenceType", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["ReferenceType"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["ReferenceType"].Selected_Value;
              if (this.CheckFilters("Reference", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["Reference"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["Reference"].Selected_Value;
              if (this.CheckFilters("JobGateCode", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["JobGateCode"].Selected_Value =
              userinterfaceview.UserView_Json.Filters["JobGateCode"].Selected_Value;
              if (this.CheckFilters("CreatedBy", interfaceview, userinterfaceview))
              interfaceview.UserView_Json.Filters["CreatedBy"].Selected_Value =
                userinterfaceview.UserView_Json.Filters["CreatedBy"].Selected_Value;
      if (this.CheckFilters("Terminal", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Terminal"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Terminal"].Selected_Value;
      if (this.CheckFilters("Line", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Line"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Line"].Selected_Value;
      if (this.CheckFilters("VesselCode", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["VesselCode"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["VesselCode"].Selected_Value;
      if (this.CheckFilters("VesselName", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["VesselName"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["VesselName"].Selected_Value;
      if (this.CheckFilters("Voyage", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Voyage"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Voyage"].Selected_Value;
      if (this.CheckFilters("PortOfLoad", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["PortOfLoad"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["PortOfLoad"].Selected_Value;
      if (this.CheckFilters("EmodalPregateStatus", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["EmodalPregateStatus"].Selected_Value =
          userinterfaceview.UserView_Json.Filters[
            "EmodalPregateStatus"
          ].Selected_Value;
      if (this.CheckFilters("Origin", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Origin"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Origin"].Selected_Value;
      if (this.CheckFilters("Destination", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Destination"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["Destination"].Selected_Value;
      //Vessel Schedule
      if (this.CheckFilters("Vessel_Vessel", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["Vessel_Vessel"].Selected_Value =
          userinterfaceview.UserView_Json.Filters[
            "Vessel_Voyage"
          ].Selected_Value;
      if (this.CheckFilters("Vessel_Voyage", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ETD"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ETD"].Selected_Value;
      if (this.CheckFilters("ETA", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ETA"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ETA"].Selected_Value;
      if (this.CheckFilters("ETD", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ETD"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ETD"].Selected_Value;
      if (this.CheckFilters("ATA", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ATA"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ATA"].Selected_Value;
      if (this.CheckFilters("ATD", interfaceview, userinterfaceview))
        interfaceview.UserView_Json.Filters["ATD"].Selected_Value =
          userinterfaceview.UserView_Json.Filters["ATD"].Selected_Value;
      interfaceview.Default_Flg = userinterfaceview.Default_Flg;
      interfaceview.UserView_Id = userinterfaceview.UserView_Id;
      interfaceview.UserView_Nm = userinterfaceview.UserView_Nm;
      interfaceview.User_Uid = userinterfaceview.User_Uid;
      interfaceview.AppInterface_Id = userinterfaceview.AppInterface_Id;
      if (userclmoptions) {
        for (let column of userclmoptions) {
          let clm = clmoptions.filter(c => c.Column_Name == column.Column_Name);
          if (clm && clm.length == 0) {
            let idx = userclmoptions.indexOf(column);
            interfaceclmoptions.splice(idx, 1);
          }
        }
      }
      interfaceview.UserView_Json.ColumnOptions = interfaceclmoptions;
    }
    return interfaceview;
  }

  CheckFilters(
    keyName: string,
    interfaceview: UserViewModel,
    userinterfaceview: UserViewModel
  ): boolean {
    if (
      interfaceview.UserView_Json.Filters[keyName] &&
      interfaceview.UserView_Json.Filters[keyName].Visible_Flg &&
      userinterfaceview.UserView_Json.Filters[keyName] &&
      userinterfaceview.UserView_Json.Filters[keyName].Selected_Value
    )
      return true;

    return false;
  }
}
