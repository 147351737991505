import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { apiurls } from '../utils/globalconstants';

@Injectable({
  providedIn: "root"
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) { }
  handleError(error: HttpErrorResponse){
    return throwError(error);
   }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = this.oidcSecurityStorage.read("authorizationData");
    const token = this.oidcSecurityService.getToken();
    //if (request.url != apiurls.addCartItem)
    {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token)
      });
    }
    // request = request.clone({
    //   headers: request.headers.set("Content-Type", "application/json")
    // });
    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });
    request = request.clone({
      headers: request.headers.set("Accept", "text/html; q=1.0, text/*; q=0.8, image/gif; q=0.6, image/jpeg; q=0.6, image/*; q=0.5, */*; q=0.1,gzip, deflate")
    });
    request = request.clone({
      headers: request.headers.set("Cache-Control", "no-cache, no-store, must-revalidate, post- check=0, pre-check=0")
    });
    request = request.clone({
      headers: request.headers.set("Pragma", "no-cache")
    });
    request = request.clone({
      headers: request.headers.set("Expires", "0")
    });

   
     return next.handle(request)
     .pipe(
      catchError(this.handleError)
     )
  }
}
