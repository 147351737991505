import { Injectable } from "@angular/core";
import { APIService } from "./httpservicefactory.service";
import { UserViewModel } from "../Interfaces/customview.interface";
import { CustomViewInputModel } from "../components/custom-view/Model";
// import { IInterfaceActions } from "../Interfaces/userinfo.interface";
import { environment } from '../../../environments/environment';
import { IInterfaceActions } from 'emodal-header/common/models/userinfomodel';
import { ParentScreen } from "../enums/parentscreen";


@Injectable({
  providedIn: "root"
})
export class SharedService {
  errorMessage: any;
  _UserSavedViews: UserViewModel[];

  constructor(private apiservice: APIService) { }
  public iframeUrl: string = "";
  public accessInterfaces: IInterfacesAccess[] = [];
  public interfaceActions: IInterfaceActions[] = [];
  public CurrentScreen: ParentScreen = ParentScreen.None;
  public username: string;
  public email: string;
  public defaultViews: any;
  public customViews: any[] = [];
  loadCustomViewData(lAppInterface_Id: number, Interface_Uid: string, custromViewType: string) {
    this.apiservice.UserInfo();
    if(this.apiservice.User_Uid)
    {
      return new Promise(resolve => {
        this.apiservice.getViewsByUser(lAppInterface_Id, Interface_Uid, this.apiservice.User_Uid).subscribe(
          data => {
            this.defaultViews = "";
            this.customViews = [] = [];
            let customViewModel: CustomViewInputModel = null;
            let _DefaultView: UserViewModel = null;
  
            _DefaultView = {
              UserView_Id: 0,
              AppInterface_Id: 0,
              User_Uid: "",
              UserView_Nm: "",
              UserView_Json: "",
              Default_Flg: false,
              DefaultView_Json: "",
              FacilityView_Flg: false
            };
  
            customViewModel = {
              Interface_Uid: Interface_Uid,
              AppInterfaceView: _DefaultView,
              UserSavedViews: this._UserSavedViews,
              UserInterfaceView: _DefaultView,
              AppInterface_Id: lAppInterface_Id,
              User_Uid: this.apiservice.User_Uid,
              FilterCriteria: [],
              ColumnOptions: []
            };
  
            if (data && data.length > 0) {
              this.defaultViews = data.filter(x => x.FacilityView_Flg == true);
              this.customViews = data.filter(y => y.FacilityView_Flg == false);
              if (!!this.defaultViews && this.defaultViews.length) {
                _DefaultView.UserView_Id = 0;
                _DefaultView.AppInterface_Id = this.defaultViews[0].AppInterface_Id;
                _DefaultView.User_Uid = customViewModel.User_Uid;
                _DefaultView.UserView_Nm = "";
                _DefaultView.UserView_Json = JSON.parse(this.defaultViews[0].DefaultView_Json);
                _DefaultView.Default_Flg = false;
                _DefaultView.DefaultView_Json = JSON.parse(this.defaultViews[0].DefaultView_Json);
                customViewModel.AppInterfaceView = JSON.parse(JSON.stringify(_DefaultView));
              }
  
              if (!!this.customViews && this.customViews.length > 0) {
                customViewModel.AppInterface_Id = this.customViews[0].AppInterface_Id;
                customViewModel.UserSavedViews = this.customViews;
                customViewModel.UserInterfaceView = this.customViews.find(v => v.Default_Flg == true);
                if (!!customViewModel.UserInterfaceView) {
                  customViewModel.UserInterfaceView.UserView_Json = JSON.parse(customViewModel.UserInterfaceView.DefaultView_Json);
                }
              } else {
                customViewModel.UserSavedViews = [];
                customViewModel.UserInterfaceView = Object.assign({},_DefaultView);
              }
              localStorage.setItem("customView_" + custromViewType + "_" + environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim()).clientid, JSON.stringify(customViewModel));
              resolve(customViewModel);
            }
          },
          error => {
            this.errorMessage = <any>error;
            resolve({});
          }
        );
      });
    }
  


  }

  loadData() {
  
  }


}

export interface IInterfacesAccess {
  modShowInMenu_Flg: boolean;
  interfacesId: number;
  interfaceUId: string;
  interfaceDsc: string;
  url: string;
  showInMenu_Flg: boolean;
  interfaceCd: string;
}
