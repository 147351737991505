<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">

<div class="fixActionRow">
    <div mat-dialog-header class="vesseluploadheader">
        <div fxLayout="row">
            <div fxFlex="98">
                {{locale.data.customizeview}}
            </div>
            <!-- <span class="spacer"></span> -->
            <div>
                <mat-icon aria-label="close" style="cursor: pointer;" (click)="onNoClick()">close</mat-icon>
            </div>
            <!-- <button mat-icon-button >

            </button> -->
        </div>
    </div>
    <div mat-dialog-content class="filter-dialog-content-container">
        <div>
            <mat-form-field style="min-width: 210px; margin-left: 30px;">
                <mat-select placeholder="{{locale.data.savedviews}}" [(ngModel)]="selectedView"
                    (selectionChange)="ViewChange(selectedView)">
                    <mat-option [value]="v" *ngFor="let v of SavedNames">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button class="icon-btn remove-icon-btn" (click)="RemoveView(selectedView)"
                [disabled]="!selectedView">
                <mat-icon aria-label="delete">delete</mat-icon>
            </button>
        </div>
        <div>
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="{{locale.data.filteroptions | uppercase}}">
                    <div [style.max-height.px]="_MaxFilterOptionsheight" style="overflow-y:auto;">
                        <div fxLayout="row">
                            <span class="spacer"></span>
                            <button mat-button (click)="ResetAllFields()" class="clear-all">
                                {{locale.data.clearall}}
                            </button>
                        </div>
                        <div style="padding-left:30px;padding-right:15px;">
                            <div *ngIf="_userViewModel.UserView_Json.Filters" style="overflow-y:auto;">
                                <div fxLayout="row" class="filterclass">
                                    <!-- Customize View Of Booking   -->

                                    <!-- View Type -->
                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['ViewType'] && _userViewModel.UserView_Json.Filters['ViewType'].Visible_Flg==true">
                                    <mat-form-field class="filter-column">
                                            <mat-select
                                            placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ViewType'].Column_Alias]}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['ViewType'].Selected_Value"
                                            multiple>

                                            <mat-option *ngFor="let viewtype of viewtypes"
                                                [value]="viewtype.viewtype_cd">{{viewtype.viewtype_desc}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                    <!-- Booking Number -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Booking'] && _userViewModel.UserView_Json.Filters['Booking'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Booking'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking'].Selected_Value">
                                        </mat-form-field>
                                    </div>

                                    <!-- Booking Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['BookingStatus'] && _userViewModel.UserView_Json.Filters['BookingStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['BookingStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['BookingStatus'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let status of bookingStatus"
                                                    [value]="status.bkgstatus_desc">
                                                    {{status.bkgstatus_desc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Booking Shipping Line -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['BookingLine'] && _userViewModel.UserView_Json.Filters['BookingLine'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['BookingLine'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['BookingLine'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let line of ShippingLines"
                                                    [value]="line.shippingLine_Cd">{{line.shippingLine_Cd}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Booking Vessel -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['BookingVessel'] && _userViewModel.UserView_Json.Filters['BookingVessel'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['BookingVessel'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['BookingVessel'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <!-- Booking Voyage -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['BookingVoyage'] && _userViewModel.UserView_Json.Filters['BookingVoyage'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['BookingVoyage'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['BookingVoyage'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <!-- Booking Lioyds -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['BookingLioyds'] && _userViewModel.UserView_Json.Filters['BookingLioyds'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['BookingLioyds'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['BookingLioyds'].Selected_Value">
                                        </mat-form-field>
                                    </div>


                                    <!-- Customize View Of Container-->

                                    <!-- unit_nbr -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Container'] && _userViewModel.UserView_Json.Filters['Container'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Container'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Container'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <!-- Container  Trade Type -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['TradeType'] && _userViewModel.UserView_Json.Filters['TradeType'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['TradeType'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['TradeType'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let tradetype of containertradetype"
                                                    [value]="tradetype.tradeType_Cd">{{tradetype.display_Dsc}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- Container Drayunit Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ContainerStatus'] && _userViewModel.UserView_Json.Filters['ContainerStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ContainerStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ContainerStatus'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let status of DrayUnitStatues"
                                                    [value]="status.drayUnitStatus_Cd">{{status.drayUnitStatus_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- Container Scac Cd -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Line'] && _userViewModel.UserView_Json.Filters['Line'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Line'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Line'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let line of ShippingLines" [value]="line.scaC_Cd">
                                                    {{line.scaC_Cd}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- Conatiner Vessel Nm -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['VesselName'] && _userViewModel.UserView_Json.Filters['VesselName'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['VesselName'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['VesselName'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <!-- Conatiner Vessel Code -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['VesselCode'] && _userViewModel.UserView_Json.Filters['VesselCode'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['VesselCode'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['VesselCode'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <!-- Conatiner Voyage -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Voyage'] && _userViewModel.UserView_Json.Filters['Voyage'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Voyage'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Voyage'].Selected_Value">
                                        </mat-form-field>
                                    </div>

                                    <!-- Container Port of Load -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['PortOfLoad'] && _userViewModel.UserView_Json.Filters['PortOfLoad'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['PortOfLoad'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['PortOfLoad'].Selected_Value"
                                                multiple>
                                                <mat-option value="option1">SCP</mat-option>
                                                <mat-option value="option2">OAK</mat-option>
                                                <mat-option value="option3">LALB</mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- Container Pregate Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['EmodalPregateStatus'] && _userViewModel.UserView_Json.Filters['EmodalPregateStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['EmodalPregateStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['EmodalPregateStatus'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let prestatus of PreGateStatuses"
                                                    [value]="prestatus.preGateStatus_Cd">{{prestatus.preGateStatus_Dsc}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>

                                    </div>
                                    <!-- Container TerminalStatus -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['TerminalStatus'] && _userViewModel.UserView_Json.Filters['TerminalStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['TerminalStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['TerminalStatus'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let tosstatus of TerminalPregateStatus"
                                                    [value]="tosstatus.tosstatus_cd">{{tosstatus.tosstatus_desc}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>

                                    </div>
                                    <!-- Container Origin -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Origin'] && _userViewModel.UserView_Json.Filters['Origin'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Origin'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Origin'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalName}}</mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- Conatiner Destination -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Destination'] && _userViewModel.UserView_Json.Filters['Destination'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Destination'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Destination'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalName}}</mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                     
                                    <!-- Customize View Of Groupcode -->

                                    <!-- Groupcode Number -->
                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['GroupCode'] && _userViewModel.UserView_Json.Filters['GroupCode'].Visible_Flg==true">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GroupCode'].Column_Alias]}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['GroupCode'].Selected_Value">
                                    </mat-form-field>
                                    </div>



                                    <!-- Groupcode Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GroupCodeStatus'] && _userViewModel.UserView_Json.Filters['GroupCodeStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GroupCodeStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GroupCodeStatus'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let status of groupcodeStatus"
                                                    [value]="status.groupcodestatus_Cd">{{status.groupcodestatus_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Groupcode Facility -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Terminal'] && _userViewModel.UserView_Json.Filters['Terminal'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Terminal'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Terminal'].Selected_Value"
                                                multiple>

                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalName}}</mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>


                                    <!--Customize View Of vesselschedule -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Vessel_Vessel'] && _userViewModel.UserView_Json.Filters['Vessel_Vessel'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Vessel_Vessel'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Vessel_Vessel'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Vessel_Voyage'] && _userViewModel.UserView_Json.Filters['Vessel_Voyage'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Vessel_Voyage'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Vessel_Voyage'].Selected_Value">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ETA'] && _userViewModel.UserView_Json.Filters['ETA'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_eta"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ETA'].Column_Alias] + ' ('+ validate.AppDateTimeFormats.Date +')'}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ETA'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_eta"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_eta></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ETD'] && _userViewModel.UserView_Json.Filters['ETD'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_etd"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ETD'].Column_Alias] + ' ('+ validate.AppDateTimeFormats.Date +')'}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ETD'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_etd"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_etd></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ATA'] && _userViewModel.UserView_Json.Filters['ATA'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_ata"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ATA'].Column_Alias] + ' ('+ validate.AppDateTimeFormats.Date +')'}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ATA'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_ata"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_ata></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ATD'] && _userViewModel.UserView_Json.Filters['ATD'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_atd"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ATD'].Column_Alias] + ' ('+ validate.AppDateTimeFormats.Date +')'}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ATD'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_atd"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_atd></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <!-- Job # -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Job'] && _userViewModel.UserView_Json.Filters['Job'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput (keypress)="validate.restrictSpace($event)"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Job'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Job'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['Job'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>
                                    <!-- Job Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['JobStatus'] && _userViewModel.UserView_Json.Filters['JobStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['JobStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['JobStatus'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let jstatus of jobstatus"
                                                    [value]="jstatus.jobstatus_cd">{{jstatus.jobstatus_desc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- Origin -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['JobOrigin'] && _userViewModel.UserView_Json.Filters['JobOrigin'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['JobOrigin'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['JobOrigin'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- Destination -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['JobDestination'] && _userViewModel.UserView_Json.Filters['JobDestination'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['JobDestination'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['JobDestination'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- From Date -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['FromDate'] && _userViewModel.UserView_Json.Filters['FromDate'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input  matInput  placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['FromDate'].Column_Alias]}}"  [value]="_userViewModel.UserView_Json.Filters['FromDate'].Selected_Value | datetime: userinfoformat" readonly>
                                            <input matInput [matDatepicker]="from_date" [max]="_userViewModel.UserView_Json.Filters['ToDate'].Selected_Value != '' ? _userViewModel.UserView_Json.Filters['ToDate'].Selected_Value :today"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['FromDate'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['FromDate'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)"  class="shadow-dateInput" >
                                            <mat-datepicker-toggle matSuffix [for]="from_date">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #from_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <!-- To Date -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ToDate'] && _userViewModel.UserView_Json.Filters['ToDate'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="to_date" [min]="_userViewModel.UserView_Json.Filters['FromDate'].Selected_Value"
                                                [max]="today"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ToDate'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ToDate'].Selected_Value"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="to_date">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #to_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <!-- Appt Status -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['AppointmentStatus'] && _userViewModel.UserView_Json.Filters['AppointmentStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['AppointmentStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['AppointmentStatus'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let prestatus of PreGateStatuses"
                                                    [value]="prestatus.preGateStatus_Cd">{{prestatus.preGateStatus_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- Trucking Company -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['TruckingCompany'] && _userViewModel.UserView_Json.Filters['TruckingCompany'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['TruckingCompany'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['TruckingCompany'].Selected_Value"
                                                multiple>
                                                <mat-option *ngFor="let truckingco of TruckingCompanies"
                                                    [value]="truckingco.company_Uid">
                                                    {{truckingco.company_Nm  + " (" + truckingco.scac +")"}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Equipment No -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Equipment'] && _userViewModel.UserView_Json.Filters['Equipment'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput (keypress)="validate.restrictSpace($event)"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Equipment'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Equipment'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['Equipment'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>



                                    <!-- Last Free Date -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['LastFreeDate'] && _userViewModel.UserView_Json.Filters['LastFreeDate'].Visible_Flg===true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_lfd"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['LastFreeDate'].Selected_Value"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['LastFreeDate'].Column_Alias]}}"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_lfd"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_lfd></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!-- Unload Date -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['UnloadDate'] && _userViewModel.UserView_Json.Filters['UnloadDate'].Visible_Flg===true">
                                        <mat-form-field class="filter-column">
                                            <input matInput [matDatepicker]="picker_ud"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['UnloadDate'].Selected_Value"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['UnloadDate'].Column_Alias]}}"
                                                (dateChange)="DateChanged($event,$event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker_ud"></mat-datepicker-toggle>
                                            <mat-datepicker #picker_ud></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!-- Preadvice Receipt -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['PreadviceNumber'] && _userViewModel.UserView_Json.Filters['PreadviceNumber'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['PreadviceNumber'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['PreadviceNumber'].Selected_Value">
                                        </mat-form-field>
                                    </div>

                                    <!-- Line Hold -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['LineHold'] && _userViewModel.UserView_Json.Filters['LineHold'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['LineHold'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['LineHold'].Selected_Value"
                                                        >
                                                <mat-option *ngFor="let lhStatus of lineholdStatus"
                                                    [value]="lhStatus.holdrelease_cd">
                                                    {{lhStatus.holdrelease_desc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Custom Hold -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['CustomHold'] && _userViewModel.UserView_Json.Filters['CustomHold'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['CustomHold'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['CustomHold'].Selected_Value"
                                                        >
                                                <mat-option *ngFor="let lhStatus of lineholdStatus"
                                                    [value]="lhStatus.holdrelease_cd">
                                                    {{lhStatus.holdrelease_desc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Job Preadvice Receipt -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['JobPreadviceNumber'] && _userViewModel.UserView_Json.Filters['JobPreadviceNumber'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['JobPreadviceNumber'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['JobPreadviceNumber'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['JobPreadviceNumber'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>

                                    <!-- Gate Code -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateCode'] && _userViewModel.UserView_Json.Filters['GateCode'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateCode'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateCode'].Selected_Value">
                                        </mat-form-field>
                                    </div>


                                    <!-- Reference Type -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['ReferenceType'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value"
                                                        >
                                                <mat-option *ngFor="let ref_type of referencetypes"
                                                    [value]="ref_type.referencetype_Cd">
                                                    {{ref_type.referencetype_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Reference Number -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput (keypress)="validate.restrictSpace($event)"
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Reference'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['Reference'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['Reference'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>


                                    <!-- Job Gate Code -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['JobGateCode'] && _userViewModel.UserView_Json.Filters['JobGateCode'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['JobGateCode'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['JobGateCode'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['JobGateCode'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                        *ngIf="(_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value != '' &&
                                        _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value === '') ||
                                        (_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value === '' &&
                                        _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value != '')">
                                        <span *ngIf="(_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value != '' &&
                                            _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value === '')" style="color:red;">Please choose reference type</span>
                                        <span *ngIf="(_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value === '' &&
                                            _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value != '')" style="color:red;">Reference number is required</span>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['CreatedBy'] && _userViewModel.UserView_Json.Filters['CreatedBy'].Visible_Flg==true">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['CreatedBy'].Column_Alias]}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['CreatedBy'].Selected_Value"
                                            (ngModelChange)="_userViewModel.UserView_Json.Filters['CreatedBy'].Selected_Value = validate.trim($event)">
                                    </mat-form-field>
                                </div>


                                    <!-- TransshipFlg -->
                                    <div fxFlex="50%"  class="filter-column"
                                        *ngIf="_userViewModel.UserView_Json.Filters['TransshipFlg'] && _userViewModel.UserView_Json.Filters['TransshipFlg'].Visible_Flg==true">

                                        <mat-checkbox labelPosition='before'
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['TransshipFlg'].Selected_Value"
                                            [disabled]="disabled">
                                            {{locale.data[_userViewModel.UserView_Json.Filters['TransshipFlg'].Column_Alias]}}
                                        </mat-checkbox>
                                    </div>



                                    <!-- Order No. -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrder'] && _userViewModel.UserView_Json.Filters['GateOrder'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrder'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrder'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrder'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>
                                     <!-- Order GateOrderReferenceNumber -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrderReferenceNumber'] && _userViewModel.UserView_Json.Filters['GateOrderReferenceNumber'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrderReferenceNumber'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrderReferenceNumber'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrderReferenceNumber'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>
                                    <!-- GateOrderStatus -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrderStatus'] && _userViewModel.UserView_Json.Filters['GateOrderStatus'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrderStatus'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrderStatus'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrderStatus'].Selected_Value = validate($event)">
                                                <mat-option *ngFor="let status of gateorderStatus"
                                                    [value]="status.gateorderstatus_Dsc">{{status.gateorderstatus_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- GateOrderFacility -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrderFacility'] && _userViewModel.UserView_Json.Filters['GateOrderFacility'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrderFacility'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrderFacility'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrderFacility'].Selected_Value = validate.trimSpace($event)">
                                                <mat-option *ngFor="let facility of PortFacilities"
                                                    [value]="facility.TerminalId">{{facility.TerminalId}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- GateOrderEquipment  -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrderEquipment'] && _userViewModel.UserView_Json.Filters['GateOrderEquipment'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrderEquipment'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrderEquipment'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrderEquipment'].Selected_Value = validate.trimSpace($event)">
                                        </mat-form-field>
                                    </div>
                                    <!-- GateOrderType  -->
                                    <div fxFlex="50%"
                                        *ngIf="_userViewModel.UserView_Json.Filters['GateOrderType'] && _userViewModel.UserView_Json.Filters['GateOrderType'].Visible_Flg==true">
                                        <mat-form-field class="filter-column">
                                            <mat-select
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['GateOrderType'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['GateOrderType'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['GateOrderType'].Selected_Value = validate($event)">
                                                <mat-option *ngFor="let type of gateorderType"
                                                    [value]="type.gateordercat_Dsc">{{type.gateordercat_Dsc}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                     <!-- Tags  -->
                                     <div fxFlex="50%"
                                     *ngIf="_userViewModel.UserView_Json.Filters['tags'] && _userViewModel.UserView_Json.Filters['tags'].Visible_Flg==true">
                                     <mat-form-field class="filter-column">
                                        <input matInput
                                                placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['tags'].Column_Alias]}}"
                                                [(ngModel)]="_userViewModel.UserView_Json.Filters['tags'].Selected_Value"
                                                (ngModelChange)="_userViewModel.UserView_Json.Filters['tags'].Selected_Value = validate.trimSpace($event)">
                                     </mat-form-field>
                                     </div>
                                     <!-- Conatiner Holds -->
                                     <div fxFlex="50%"
                                     *ngIf="_userViewModel.UserView_Json.Filters['Hold'] && _userViewModel.UserView_Json.Filters['Hold'].Visible_Flg==true">
                                     <mat-form-field class="filter-column">
                                         <mat-select
                                             placeholder="{{locale.data[_userViewModel.UserView_Json.Filters['Hold'].Column_Alias]}}"
                                             [(ngModel)]="_userViewModel.UserView_Json.Filters['Hold'].Selected_Value" 
                                             multiple=false>

                                             <mat-option value="true">{{locale.data.yes}} </mat-option>
                                             <mat-option value="false">{{locale.data.no}} </mat-option>
                                         </mat-select>
                                     </mat-form-field>
                                 </div>
                                    <!-- <div fxFlex="100%" class="filter-column">
                                        <mat-slide-toggle
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['LineHold'].Selected_Value"
                                            *ngIf="_userViewModel.UserView_Json.Filters['LineHold'] && _userViewModel.UserView_Json.Filters['LineHold'].Visible_Flg==true">
                                            {{locale.data[_userViewModel.UserView_Json.Filters['LineHold'].Column_Alias]}}
                                        </mat-slide-toggle>
                                        <mat-slide-toggle
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['CustomHold'].Selected_Value"
                                            style="margin-left: 50px;"
                                            *ngIf="_userViewModel.UserView_Json.Filters['CustomHold'] && _userViewModel.UserView_Json.Filters['CustomHold'].Visible_Flg==true">
                                            {{locale.data[_userViewModel.UserView_Json.Filters['CustomHold'].Column_Alias]}}
                                        </mat-slide-toggle>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{locale.data.columnoptions | uppercase}}">
                    <div class="fixActionRow">
                        <div class="dragdrop-selectall">
                            <mat-checkbox [(ngModel)]="checked" (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                {{locale.data.select_deselectall}}
                            </mat-checkbox>

                        </div>
                        <mat-divider style="width:100%"></mat-divider>
                        <div cdkDropList class="column-option-list" (cdkDropListDropped)="drop($event)"
                            style="padding-top: 5px;overflow-y: auto;" [style.max-height.px]="_MaxColumnOptionsheight">
                            <div class="dragdrop-box" *ngFor="let column of _userViewModel.UserView_Json.ColumnOptions"
                                cdkDrag>
                                <button mat-icon-button class="header-right-menu-button row-drag-btn"
                                    style="cursor: move;" [disableRipple]="true" cdkDragHandle>
                                    <mat-icon aria-label="move item">menu</mat-icon>
                                </button>
                                <mat-checkbox [(ngModel)]="column.Visible_Flg" (click)="$event.stopPropagation()"
                                    [disabled]="column.Column_Alias =='booking_num' || column.Column_Alias =='container_unit_nbr'
                                 ||  column.Column_Alias =='groupcode_groupcode_nbr' || column.Column_Alias == 'job_nbr' "
                                    (change)="$event ? selection.toggle(column) : null"
                                    [checked]="selection.isSelected(column)">{{locale.data[column.Column_Alias]}}
                                </mat-checkbox>
                                <!-- <span class="spacer"></span> -->

                            </div>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>

    </div>
    <!-- <div class="spacer-dialog"></div> -->
    <div>
        <mat-divider style="width:100%"></mat-divider>

        <div class="alignbuttonright" >
            <button mat-button (click)="onNoClick()"
                style="min-width: 100px;">{{locale.data.cancel | uppercase}}</button>
            <button [mat-dialog-close]="applyView()" cdkFocusInitial mat-raised-button
                [disabled]="(_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference']
                && _userViewModel.UserView_Json.Filters['FromDate'] && _userViewModel.UserView_Json.Filters['ToDate']
                && (_userViewModel.UserView_Json.Filters['FromDate'].Selected_Value === '' && _userViewModel.UserView_Json.Filters['ToDate'].Selected_Value === '')
                && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value != '' &&
                _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value === '') ||
                (_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Selected_Value === '' &&
                _userViewModel.UserView_Json.Filters['ReferenceType'] && _userViewModel.UserView_Json.Filters['ReferenceType'].Selected_Value != '')"
                class="btn-submit">{{locale.data.apply | uppercase}}</button>
        </div>
    </div>

</div>
