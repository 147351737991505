<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<div fxLayout="row wrap" fxLayoutGap="grid">
    <mat-card class="page-header">
        <mat-card-content fxLayout="row wrap">
            <div fxFlex="75" fxFlex.sm="100" fxFlex.xs="100" fxLayoutGap="15px">
                <!-- <span class="headertitle">{{locale.data.container_title}}</span> -->
                <span style="margin-right:78px !important;"
                    class="headertitle">{{locale.data.truckGroupHeaderTitle}}</span>
                <button mat-raised-button class="btn-actions"
                    [matMenuTriggerFor]="actions" [disabled]="SelectedStatus().activeselected==false && SelectedStatus().disabledselected==false">{{locale.data.headeractions}}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <!-- <mat-menu #actions="matMenu">
        <button mat-menu-item >{{locale.data.headeractions_addreservation}}</button>
        <button mat-menu-item >{{locale.data.headeractions_payfees}}</button>
        <button mat-menu-item >{{locale.data.container_delete}}</button>
      </mat-menu> -->
                <div class="menudropdown" >
                    <mat-menu #actions="matMenu" >
                        <button mat-menu-item *ngIf="locale.data !=null && locale.data.truckGroupActions !=null && SelectedStatus().activeselected==true && SelectedStatus().disabledselected==false" (click)="UpdateStatusMultiple(false)">{{locale.data.truckGroupActions.disable}}</button>
                        <button mat-menu-item *ngIf="locale.data !=null && locale.data.truckGroupActions !=null && SelectedStatus().activeselected==false && SelectedStatus().disabledselected==true" (click)="UpdateStatusMultiple(true)">{{locale.data.truckGroupActions.make_active}}</button>
                        <button mat-menu-item *ngIf="locale.data !=null && locale.data.truckGroupActions !=null" (click)="UpdateGroupMultiple()">{{locale.data.truckGroupActions.delete}}</button>
                    </mat-menu>
                </div>
                <!-- <button mat-raised-button (click)="editTrucks()">{{locale.data.container_add}}</button> -->
                <button class="add-new-trucks" mat-raised-button
                    (click)="Addtrucks()">{{locale.data.newTruckGrouptext}}</button>
                <div class="btn-export" style="display:-webkit-inline-box;">
                    <mat-icon matTooltip="Mail Notification"
                        [ngClass]="{'disabled' : !selection.hasValue() ? true: false}">
                        email</mat-icon>
                    <mat-divider [vertical]="true"></mat-divider>
                    <mat-icon mat-icon-button [ngClass]="{'disabled' : IsIconDisabled() ? true: false}"
                        svgIcon="pdf-grey-scpa-icon" matTooltip="PDF" (click)="downloadtopdf()"></mat-icon>
                    <mat-divider [vertical]="true"></mat-divider>
                    <mat-icon  class="svg-xls-icon"
                        svgIcon="xls" matTooltip="Excel" (click)="downloadtoxlsx()" [ngClass]="{'disabled' : IsIconDisabled() ? true: false}"></mat-icon>
                </div>
            </div>

            <div fxFlex="25" fxFlex.sm="25" fxFlex.xs="100" style="padding:0 8px;">
                <!-- <mat-form-field style="float: right;width:100%;">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          </mat-form-field> -->
                <!-- <div class="custom-view">
            <app-custom-view [ipModel]="customViewModel" [viewName]="viewName"></app-custom-view> 
          </div> -->
            </div>
        </mat-card-content>
    </mat-card>
</div>





<div class="search-results mat-elevation-z8" id="searchres" matinfiniteScroll (scrolled)="onScrollDown()" [scrollWindow]="false"
    [style.height.px]='innerheight' [style.max-height.px]='innerheight' *ngIf="locale.data !=null && locale.data.truckGroupColumns !=null">
    <table class="myinfnitescroll table table-hover" mat-table multiTemplateDataRows [dataSource]="dataSource" id="main-table"
        class="main-table" matSort #watchlisttable (matSortChange)="sortData($event)">
        <!-- select Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell  *matHeaderCellDef class="_select" style="width:6% !important;">
                <mat-checkbox class="moveleft" [disableRipple]='true' [(ngModel)]="selectAll" (change)="onSelectAll()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="_select" style="width:6% !important;">
                <mat-checkbox class="moveleft" [disableRipple]='true' [(ngModel)]="element.IsSelected">
                </mat-checkbox>
                <mat-icon class="cell-icon moveleft" [class.example-expanded-row]="element.isCollapsed == 1"
                    (click)="element.isCollapsed = element.isCollapsed === 1 ? 0 : 1;element.tabIndex=0;element.isLoaded = false">
                    {{element.isCollapsed == 1?'keyboard_arrow_down':'keyboard_arrow_right'}}</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="GroupName">
            <th mat-header-cell mat-sort-header="GroupName" *matHeaderCellDef class="_collapse">
                {{locale.data.truckGroupColumns.grpName}}
            </th>
            <td mat-cell *matCellDef="let element" class="_collapse">
                {{element.GroupName}}
            </td>
        </ng-container>

        <!-- collapsepanelarrow Column -->
        <ng-container matColumnDef="Status">
            <th mat-header-cell mat-sort-header="Status" *matHeaderCellDef class="_collapse">
                {{locale.data.truckGroupColumns.grpStatus}}
            </th>
            <td mat-cell *matCellDef="let element" style="color:#428bca;" class="_collapse">
                <div class="text-primary"> {{element.active_flg?locale.data.truckGroupStatusText.active:locale.data.truckGroupStatusText.disabled}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="MethodOfAssigment">
            <th mat-header-cell mat-sort-header="MethodOfAssigment" *matHeaderCellDef class="_collapse">
                {{locale.data.truckGroupColumns.grpAssigmentMethod}}
            </th>
            <td mat-cell *matCellDef="let element" class="_collapse">
                {{element.MethodOfAssigment}}
            </td>
        </ng-container>


        <ng-container matColumnDef="Company">
            <th mat-header-cell mat-sort-header="Company" *matHeaderCellDef class="_collapse">
                {{locale.data.truckGroupColumns.grpCompany}}
            </th>
            <td mat-cell *matCellDef="let element" class="_collapse">
                {{element.Company}}
            </td>
        </ng-container>-


        <ng-container matColumnDef="Remarks">
            <th mat-header-cell mat-sort-header="Remarks" *matHeaderCellDef class="_collapse">
                {{locale.data.truckGroupColumns.grpRemarks}}
            </th>
            <td mat-cell *matCellDef="let element" class="_collapse">
                {{element.Remarks}}
            </td>
        </ng-container>

        <!-- dots Column -->
        <ng-container matColumnDef="dots">
            <th mat-header-cell *matHeaderCellDef class="_dots"></th>
            <td mat-cell *matCellDef="let element" class="_dots" matTooltip="Actions" [matTooltipPosition]="['left']">
                <div class="threedots">
                    <button mat-icon-button style="width:0px;" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" [overlapTrigger]="true">
                        <!-- <button mat-menu-item>
                            <span>Edit</span>
                        </button> -->
                        <button mat-menu-item (click)="OnEdit(element.GroupUID)">
                            <span>{{locale.data.truckGroupActions.edit}}</span>
                        </button>
                        <button mat-menu-item (click)="OnStatusUpdate(element.GroupUID,element.active_flg)">
                            <span>{{element.active_flg?locale.data.truckGroupActions.disable:locale.data.truckGroupActions.make_active}}</span>
                        </button>
                        <button mat-menu-item (click)="OnDeleteTruckGroup(element.GroupUID)">
                            <span>{{locale.data.truckGroupActions.delete}}</span>
                        </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="isCollapsed">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let element" colspan="6" class="padding0">
                <div class="details-view" *ngIf="element.isCollapsed == 1"
                    [@detailExpand]="element.isCollapsed == 1 ? 'expanded' : 'collapsed'">
                    <app-expandcollapsepanel [trucks]="element.trucks"></app-expandcollapsepanel>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>

        <tr mat-row *matRowDef="let row;columns:displayedColumns;let even = even;" class="listrows"
            (click)="expandchild(row,columns)" [class.example-expanded-row]="expandedElement === element"
            [ngClass]="row.active_flg==true?'':'disabledrow'">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['isCollapsed'];" class="details-row"></tr>

        <!-- <tr mat-header-row *matHeaderRowDef="['disclaimer'];sticky:true" class="second-header-row second-header"
              [ngClass]="{'hide_spinner':!loading}"></tr> -->
    </table>
</div>