import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from '../../shared/services/httpservicefactory.service';
import { TranslateService } from '../../shared/services/translateservice.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ResponseMessagesComponent } from "../../shared/components/responsemessages/responsemessages.component";
declare var jsPDF: any;
export class DialogContentExampleDialog { }

@Component({
  selector: 'app-truck-group',
  templateUrl: './truck-group.component.html',
  styleUrls: ['./truck-group.component.css']
})



export class TruckGroupComponent implements OnInit {
  filteredStates: any;
  stateCtrl: any;
  ShowLoader: boolean = false;
  color = 'accent';
  checked = true;
  TruckingCompanies: Array<any> = [];
  MethodofAssigments: Array<any> = [];
  SelectMethodOfAssigment: string = "G";
  selectedCompany: string = "";
  selectedTrucks: Array<any> = [];
  TruckGroupName: string = "";
  TruckGroupStatus: boolean = true;
  TruckGroupRemarks: string = "";
  TruckGroupUID: string = "";
  ShowValidation: boolean = false;
  changed() {
    console.log(this.checked);
  }



  displayedColumns: string[] = ['Truck_Plate', 'stauts', 'make', 'model', 'vin', 'state'];
  dataSource = new MatTableDataSource<any>();

  constructor(public dialog: MatDialog, private router: Router, private apiService: APIService,
    private activeroute: ActivatedRoute, public locale: TranslateService, private snackBar: MatSnackBar,) {
    this.activeroute.params.subscribe((res: any) => {
      if (res != null && res.id != null) {
        this.TruckGroupUID = res.id;
      }
    })
  }

  goHomepage() {
    this.router.navigateByUrl('/povtruckgroup')
  }

  ngOnInit(): void {
    this.GetUserTruckingCompany();
  }

  GetUserTruckingCompany() {
    this.ShowLoader = true;
    this.apiService.GetUserTruckingCompany("9F4C4E83-6FA1-4023-AF5F-BE00325B8233").subscribe((res: any) => {
      if (res && res.data != null) {
        this.TruckingCompanies = res.data;
        this.GetMethodofAssignments();
      }
    })
  }

  GetMethodofAssignments() {
    this.apiService.GetMethodofAssignments().subscribe((res: any) => {
      if (res && res.data != null) {
        this.MethodofAssigments = res.data;
        if (this.TruckGroupUID != null && this.TruckGroupUID != "") {
          this.GetTruckGroupDetailsById();
        }
        else {
          this.ShowLoader = false;
        }
      }
    })
  }

  GetTrucksbyTruckingCompany() {
    if (this.selectedCompany === '' || this.selectedCompany == null) {
      this.dataSource.data = [];
      return;
    }
    this.ShowLoader = true;
    this.apiService.GetTrucksbyTruckingCompany(this.selectedCompany).subscribe((res: any) => {
      this.ShowLoader = false;
      if (res && res.data != null) {
        let array: any = [];
        res.data.map((el: any) => {
          array.push({
            Truck_Plate: el.plate_Nbr, stauts: el.delete_flg == false ? 'Active' : "Un-Active", make: el.make, model: el.model, vin: el.vin, state: el.state,
            IsChecked: ((this.selectedTrucks.find((e: any) => e == el.truck_uid)) != null ? true : false),
            Truck_Uid: el.truck_uid
          });
        });
        let newarray: Array<any> = array.filter((el: any) => el.IsChecked == true);
        newarray = newarray.concat(array.filter((el: any) => el.IsChecked == false));
        this.dataSource.data = newarray;
      }
    })
  }

  onCompanySelection() {
    this.GetTrucksbyTruckingCompany();
  }

  GetTruckGroupDetailsById() {
    this.selectedTrucks = [];
    this.apiService.GetTruckGroupDetailsById(this.TruckGroupUID).subscribe((res: any) => {
      if (res && res.data != null) {
        this.ShowLoader = false;
        let data = res.data;
        if (data.truckGroupTruck != null) {
          this.selectedTrucks = data.truckGroupTruck.map((el: any) => el.truck_UId);
        }
        if (data.company != null) {
          this.selectedCompany = data.company.ssO_CompanyUid;
          this.GetTrucksbyTruckingCompany();
        }
        this.TruckGroupName = data.truckGroup_Name;
        this.TruckGroupStatus = data.active_Flg;
        this.TruckGroupRemarks = data.remarks;
        this.SelectMethodOfAssigment = data.methodOfAssignment_Cd;
      }
    })
  }


  Onsubmit() {
    let errors = this.GetValidationErrors();
    if (errors.length == 0) {
      if (this.dataSource.data.filter((el: any) => el.IsChecked == true).length > 0) {
        this.submit();
      }
      else {
       this.snackBar.open(this.locale.data.truckGroupmsg.requireOneTruck, "X", {
        duration: 5000,
        panelClass: "failedMessage",
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      }

    }
    else {
      this.ShowValidation = true;
      this.snackBar.open(this.locale.data.truckGroupmsg.fieldrequiredmsg, "X", {
        duration: 5000,
        panelClass: "failedMessage",
        horizontalPosition: "center",
        verticalPosition: "top"
      });
    }
  }

  submit() {
    let data = this.GetDataForSubmit();

    if (this.TruckGroupUID != null && this.TruckGroupUID != "") {
      this.ShowLoader = true;
      this.apiService.UpdateTruckGroup(data).subscribe((res: any) => {
        this.ShowLoader = false;
        if (res != null && res.data != null) {
          if (res.data.success == true) {
            const snackBarRef = this.snackBar.openFromComponent(ResponseMessagesComponent, {
              data: { responsemsg: [{ messageDetail: this.locale.data.truckGroupmsg.updatesuccessmsg, messageId: "10001" }], type: 'other' },
              panelClass: "responsemsgs",
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top"
            });
            snackBarRef.afterDismissed().subscribe(info => {
              this.router.navigate(['/povtruckgroup']);
            });
          }
          else {
            this.snackBar.open(res.data.message, "X", {
              duration: 5000,
              panelClass: "failedMessage",
              horizontalPosition: "center",
              verticalPosition: "top"
            });
          }
        }
      });
    }
    else {
      this.ShowLoader = true;
      this.apiService.CreateTruckGroup(data).subscribe((res: any) => {
        this.ShowLoader = false;
        if (res != null && res.data != null) {
          if (res.data.success == true) {
            const snackBarRef = this.snackBar.openFromComponent(ResponseMessagesComponent, {
              data: { responsemsg: [{ messageDetail: this.locale.data.truckGroupmsg.createsuccessmsg, messageId: "10001" }], type: 'other' },
              panelClass: "responsemsgs",
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top"
            });
            snackBarRef.afterDismissed().subscribe(info => {
              this.router.navigate(['/povtruckgroup']);
            });
          }
          else {
            this.snackBar.open(res.data.message, "X", {
              duration: 5000,
              panelClass: "failedMessage",
              horizontalPosition: "center",
              verticalPosition: "top"
            });
          }
        }
      });
    }

  }

  GetDataForSubmit() {
    let data: any = {};
    data.truckgroupname = this.TruckGroupName;
    if (this.TruckGroupUID != null && this.TruckGroupUID != "") {
      data.truckgroup_uid = this.TruckGroupUID;
    }
    else {
      data.truckingcompany_uid = this.selectedCompany;
    }
    data.methodOfAssignment_Cd = this.SelectMethodOfAssigment;
    data.active_flg = this.TruckGroupStatus;
    data.remarks = this.TruckGroupRemarks;
    data.trucks = this.dataSource.data.filter((el: any) => el.IsChecked == true).map((el: any) => { return { truck_uid: el.Truck_Uid, active_flg: true } });
    return data;
  }
  ChangeMethodOfAssig(methodOfAssigment: string) {
    this.SelectMethodOfAssigment = methodOfAssigment;
  }

  GetValidationErrors(): Array<string> {
    let validations: Array<string> = [];
    if (this.TruckGroupName == "" || this.TruckGroupName == null) {
      validations.push("Group Name");
    }
    if (this.TruckGroupUID == null || this.TruckGroupUID == "") {

      if (this.selectedCompany == null || this.selectedCompany == "") {
        validations.push("Company Name");
      }
    }
    if (this.SelectMethodOfAssigment == null || this.SelectMethodOfAssigment == "") {
      validations.push("Method of Assigment");
    }
    return validations;
  }

  GetValidationClass(fieldName: string, IsRequired: boolean) {
    let errors = this.GetValidationErrors();
    if (errors.find((el: any) => el == fieldName) != null && IsRequired && this.ShowValidation) {
      return "mat-custom-error";
    }
    return "";
  }

  IsIconDisabled(): boolean {
    return this.dataSource.data.filter((el: any) => el.IsChecked).length <= 0;
  }

  downloadtoxlsx(): void {
    if ((this.dataSource.data.filter((el: any) => el.IsChecked).length <= 0)) {
      return;
    }
    this.ShowLoader = true;
    let res: Array<any> = [];
    this.dataSource.data.filter((el: any) => el.IsChecked == true).map(item => {
      res.push(item);
    });
    let xlsxcolumnNames = [];
    let pdfcolumnNames = [{
      title: this.locale.data.truckGroupExpandColumns.truck_plate,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupExpandColumns.status,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupExpandColumns.make,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupExpandColumns.model,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupExpandColumns.vin,
      dataKey: ''
    },
    {
      title: this.locale.data.truckGroupExpandColumns.state,
      dataKey: ''
    }
    ];
    for (let i = 0; i < res.length; i++) {
      let row = [];
      row.push(res[i].Truck_Plate);
      row.push(res[i].stauts);
      row.push(res[i].make);
      row.push(res[i].model);
      row.push(res[i].vin);
      row.push(res[i].state);
      xlsxcolumnNames.push(row);
    }
    this.apiService.exportToExcelFile(
      xlsxcolumnNames,
      "trucks",
      pdfcolumnNames
    );
    this.ShowLoader = false;
  }

  downloadtopdf(): void {
    if ((this.dataSource.data.filter((el: any) => el.IsChecked).length <= 0)) {
      return;
    }
    this.ShowLoader = true;
    let res: Array<any> = [];
    this.dataSource.data.filter((el: any) => el.IsChecked == true).map(item => {
      var _tempItem = Object.assign({}, item);
      res.push(_tempItem);
    });
    let row: any;
    row = new MatTableDataSource<any>(res);
    let doc = new jsPDF("l", "in", [1200, 1401]);
    let pdfcolumnNames = [{
      title: this.locale.data.truckGroupExpandColumns.truck_plate,
      dataKey: 'truck_plate'
    },
    {
      title: this.locale.data.truckGroupExpandColumns.status,
      dataKey: 'status'
    },
    {
      title: this.locale.data.truckGroupExpandColumns.make,
      dataKey: 'make'
    },
    {
      title: this.locale.data.truckGroupExpandColumns.model,
      dataKey: 'model'
    },
    {
      title: this.locale.data.truckGroupExpandColumns.vin,
      dataKey: 'vin'
    },
    {
      title: this.locale.data.truckGroupExpandColumns.state,
      dataKey: 'state'
    }
    ];
    let statustext = this.locale.data.truckGroupStatusText;

    doc.autoTable(pdfcolumnNames, row.data, {
      createdCell: function (cell, data) {
        if (data.column != null && data.column.index != null) {
          if (data.column.dataKey === "truck_plate") {
            cell.text = res[data.row.index].Truck_Plate;
          }
          if (data.column.dataKey === "status") {
            cell.text = res[data.row.index].stauts;
          }
          if (data.column.dataKey === "make") {
            cell.text = res[data.row.index].make;
          }
          if (data.column.dataKey === "model") {
            cell.text = res[data.row.index].model;
          }
          if (data.column.dataKey === "vin") {
            cell.text = res[data.row.index].vin;
          }
          if (data.column.dataKey === "state") {
            cell.text = res[data.row.index].state;
          }
        }


      }
    });
    doc.save("trucks.pdf");
    this.ShowLoader = false;
  }

}



export class CheckboxConfigurableExample {
  color = 'accent';
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
}