import { NgModule } from "@angular/core";
//import { LoadingBackdropComponent } from "../components/loading-backdrop/loading-backdrop.component";
//import { ConfirmDialogComponent } from "../components/confirmdialog/confirmdialog.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandlerService } from "../services/errorhandler.service";
//import { CustomViewComponent } from "../components/custom-view/custom-view.component";
// import { CustomviewfilterComponent } from "../components/custom-view/customviewfilter.component";
// import { CustomviewsaveComponent } from "../components/custom-view/customviewsave.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
//import { DefaultComponent } from "../components/defaultcomponent/defaultcomponent.component";
//import { DragDirective } from "../directives/DragDirective";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@NgModule({
  declarations: [ 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  exports: [
  ],
  entryComponents: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerService, multi: true }
  ]
})
export class LibSharedModule {}
