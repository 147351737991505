import { NgModule, ModuleWithProviders } from "@angular/core";
import { AppComponent } from "./app.component";
import { Routes, RouterModule, CanActivate } from "@angular/router";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { LayoutComponent } from "./layout/layout.component";
import { SsouserprofileComponent } from "./ssouserprofile/ssouserprofile.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { DefaultGuardService } from './shared/services/defaulguard.service';
import { LogoutComponent } from './shared/logout/logout.component';
import { DefaultComponent } from './shared/components/defaultcomponent/defaultcomponent.component';
import { LoginComponent } from "./login/login.component";
import { ErrorpageComponent } from './shared/components/errorpage/errorpage.component';
import {IndexComponent} from './POVTruckGroup/index/index.component';
import { TruckGroupComponent} from './POVTruckGroup/truck-group/truck-group.component';
const appRoutes: Routes = [
  //no layout routes
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "errorpage", component: ErrorpageComponent},
  { path: "logout", component: LogoutComponent},
  {
    path: "signin-oidc",
    component: DefaultComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "default",
    component: DefaultComponent,
    canActivate: [DefaultGuardService]
  },
  // App routes goes here here
  {
    path: "",
    component: LayoutComponent,
    children: [

    
      {
        path: "adminsettings",
        component: SsouserprofileComponent,
        canActivate: [AuthGuardService]
      },
      
      {
        path: "forbidden",
        component: ForbiddenComponent
      },
      {
        path: "edit-truck-group/:id",
        component: TruckGroupComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: "add-truck-group",
        component: TruckGroupComponent,
        canActivate: [AuthGuardService]
      },
     
      { path: "povtruckgroup", component: IndexComponent, canActivate: [AuthGuardService]},
      {
        path: "**",
        component: ForbiddenComponent,
        canActivate: [AuthGuardService]
      },

      

    ]
  },
  // otherwise redirect to login
  { path: "**", redirectTo: "login" }
];



export const appRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes
);
