import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '../../shared/services/translateservice.service';

@Component({
  selector: 'app-expandcollapsepanel',
  templateUrl: './expandcollapsepanel.component.html',
  styleUrls: ['./expandcollapsepanel.component.css']
})
export class ExpandcollapsepanelComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['Truck_Plate', 'stauts', 'make', 'model', 'vin', 'state'];
  dataSource = new MatTableDataSource<any>();
  @Input("trucks") trucks: Array<any> = [];
  constructor(public locale: TranslateService) { }


  ngOnInit(): void {
this.SetDatasource();
  }

  SetDatasource() {
    let array: Array<any> = [];
    this.trucks.map((el: any) => {
      array.push({ Truck_Plate:el.truckplate, stauts: el.active_flg==true?'Active':'Un-Active', make: el.make, model: el.model, vin: el.vin, state: el.state });
    })
    this.dataSource.data = array;
  }

  ngOnChanges(changes: SimpleChanges){
    this.SetDatasource();
  }
}