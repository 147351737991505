export interface CustomViewModel{

    ViewName : string;
    Interface_Uid: string;
    View:any;
    Default:boolean;

}

export interface UserViewModel{
    UserView_Id:number;
    AppInterface_Id:number;
    User_Uid:string;
    UserView_Nm:string;
    UserView_Json:any;
    Default_Flg:boolean;
    DefaultView_Json:any;
    FacilityView_Flg:boolean;
}

export interface SaveUserViewModel{
    UserView_Id:number;
    AppInterface_Id:number;
    User_Uid:string;
    UserView_Nm:string;
    UserView_Json:any;
    Default_Flg:boolean;
    Active_Flg:boolean;
}

export interface CustomViewInputModel{
    AppInterface_Id:number;
    User_Uid:string;
    Interface_Uid:string;
    AppInterfaceView:UserViewModel;
    UserInterfaceView:UserViewModel;
    UserSavedViews:UserViewModel[];
    FilterCriteria:ClientCriteria[];
    ColumnOptions:string[];
}

export interface FilterCriteria{
    Filter_Cd:string;
    Filter_Value:any;
}


export interface ClientCriteria
{        
        include : boolean;
        mem : string;
        oper : BindingOperator;
        vLow : string;
        vHigh : string;
        required: true;
        seprator: string;     
        
}

export enum BindingOperator
{
        Equal = 0,
        NotEqual = 1,
        LessThan = 2,
        LessThanOrEqual = 3,
        GreaterThan = 4,
        GreaterThanOrEqual = 5,
        StartsWith = 6,
        EndsWith = 7,
        Contains = 8,
        Like = 9,
        In = 10,
        NotIn = 11,
        IsNull = 12,
        IsNotNull = 13,
        Between = 14
}


export interface ShippingLine{
    shippingLine_Id: number,
    shippingLine_Cd: string,
    shippingLine_Dsc: string,
    active_Flg: boolean,
    scaC_Cd: string,
    uiiA_Cd: string,
    eMail_Addr: string,
    conPool_Cd: string,
    chsPool_Cd: string,
    dispatchOrder_Flg: boolean,
    ediBookingLine_Cd: string,
    ediblLine_Cd: string,
    ediStowLine_Cd: string,
    company_Id: number
}

export interface Facility{
    facility_Id: string,
    facility_cd: string,
    facilityType_Cd: string,
    facility_name: string,
    facility_Addr: string,
    facilityOwner: number,
    facilityOwnerInfo: string,
    facilityTimeZone: number,
    facilityProfile: string,
    delete_Flg: boolean,
    facility_Alias: string,
    apptMTODisplay_Cd: string,
    apptType_Cd: string,
    port_Cd: string,
    port_Dsc: string,
    active_Flg: boolean,
    location_Cd: string,
    timeZone_Id: number,
    portFacility_Id: number,
    port_Active_Flg: boolean,
    timeZone_Nm: string,
    time: string,
    offSet: string
}

export interface ColumnOptions{
    Column_Alias:string,
    Column_Name:string,
    Sequence_Nbr:number,
    Visible_Flg:boolean
}

export interface UserLocaleModel{
    DefaultLocale_Id: number;
    User_Uid:string;
    Locale: string;
}

export interface UserLandingPageModel{
    DefaultInterface_Id: number;
    Interface_Uid: string;
    User_Uid: string;
    Interface_Uri: string;
}

export interface ITerminalPregatestatus{
    tosstatus_id: string,
    tosstatus_cd: string,
    tosstatus_desc:string
}
export interface MoveRefConfig{
    facility_Cd:string;
    mtoProfile_Cd:string;
    moveType_Cd:string;
    mtoProfile_Val:string;
}