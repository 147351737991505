import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpClient
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { httpOptions } from '../../common/utils/globalconstants';

@Injectable()
export class ErrorHandlerService implements HttpInterceptor {
    constructor(public http: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const token: string = localStorage.getItem('token');

        // if (token) {
        //     request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        // }

        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }

        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                //let errstatus = error.status;
                this.addtoerrorlist({ err_datetime: new Date(), err_user: "user", err_message: error.message });
                return throwError(error);
            }));
    }
    addtoerrorlist(value: IErrorList) {
        // var writeFile = require('write-file');
        // writeFile('./assets/apierrors.json', value, function (err) {
        //     if (err) return
        // })
    }
    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        return this.http.post('./assets/internalerrors.json', { err_datetime: new Date(), err_user: "user", err_message: errorMessage }, httpOptions).pipe(
            tap(data => {
                '';
            })
        );
    }
}
export interface IErrorList {
    err_datetime: Date,
    err_user: string,
    err_message: string
}
