import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-responsemessages',
    templateUrl: 'responsemessages.component.html',
    styleUrls: ['responsemessages.component.scss'],
})
export class ResponseMessagesComponent implements OnInit {
    type:string="";
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {responsemsg:any,type:string}, private snackBar: MatSnackBar, ) {

    }
    ngOnInit() {
        let dfg = this.data;
        this.type=this.data.type;
    }
    dismiss() {
        this.snackBar.dismiss();
    }
}