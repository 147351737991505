<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div *ngIf="ShowLoader" class="div-loader">
  <mat-spinner style="left:50%;top:45%;"></mat-spinner>
  <span style="top:60%;position: absolute">{{locale.data.pleasewait}}.....</span>
</div>

<div fxLayout="row wrap">
    <button mat-icon-button  (click)="Refresh()" class="view-btn"
    matTooltip="{{locale.data.refresh}}" [matTooltipPosition]="['below']">
      <mat-icon aria-label="Refresh">refresh</mat-icon>
    </button>
    <mat-divider [vertical]="true" class="divider-height"></mat-divider>
  <button mat-icon-button (click)="openFilterDialog()" class="view-btn" style="height:36px;line-height:initial;"
  matTooltip="{{locale.data.customizeview}}" [matTooltipPosition]="['below']">
    <mat-icon aria-label="filter options">desktop_windows</mat-icon>
  </button>
  <mat-divider [vertical]="true" class="divider-height"></mat-divider>
  <button mat-raised-button [matMenuTriggerFor]="SavedSearches" class="btn-saved-views">
    <span class="overflowspan"
      matTooltip="{{selectedViewname? (this.ipModel.UserInterfaceView && this.ipModel.UserInterfaceView.UserView_Nm)? this.ipModel.UserInterfaceView.UserView_Nm : locale.data.savedviews : locale.data.savedviews }}"
      [matTooltipPosition]="['below']">
      {{selectedViewname? (this.ipModel.UserInterfaceView && this.ipModel.UserInterfaceView.UserView_Nm)? this.ipModel.UserInterfaceView.UserView_Nm : locale.data.savedviews : locale.data.savedviews }}
    </span>
    <mat-icon style="padding-left:20px;padding-top: 6px;">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #SavedSearches="matMenu" class="saved-view-container">
    <button mat-menu-item *ngFor="let item of SavedViews" class="saved-views-btn-menu" (click)="ChangeView(item)"
      [ngClass]="(selectedViewname==item.UserView_Nm)?'selected-view':'not-selected-view'">
      <div>
        {{item.UserView_Nm + (DefaultViewName==item.UserView_Nm?'(Default)':'')}}
      </div>
    </button>
    <span mat-menu-item *ngIf="!SavedViews || SavedViews.length==0" class="no-saved-views" fxLayout="column">
      <mat-icon style="padding-left:40px;color:orange">info</mat-icon>
      <span style="font-size: 16px;">
        {{locale.data.emptyviewmessage}}
      </span>
    </span>
  </mat-menu>
  <mat-divider [vertical]="true" class="divider-height"></mat-divider>
  <button mat-icon-button (click)="openSaveDialog($event)" matTooltip="{{locale.data.save}}"
    [matTooltipPosition]="['below']" class="save-custom-view-icon-btn">
    <mat-icon aria-label="save">save</mat-icon>
  </button>
</div>
