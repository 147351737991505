<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<mat-card class="page-header" style="height:40px;padding:4px 10px;">
  <mat-card-content fxLayout="row wrap">
    <!-- <span class="headertitle">{{locale.data.container_title}}</span> -->
    <span class="headertitle">{{TruckGroupUID==null ||
      TruckGroupUID==""?locale.data.AddtruckGroupHeaderTitle:locale.data.EdittruckGroupHeaderTitle}}</span>
  </mat-card-content>
</mat-card>
<section class="edit-truck-otr">
  <mat-card class="example-card" style="box-shadow: none !important;">
    <mat-card-content>
      <div fxFlex="65" fxFlex.lt-md="100" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row wrap" class="margin-bottom">
          <div fxFlex="50" fxFlex.lt-md="100" style="padding:0 5px;" [ngClass]="GetValidationClass('Group Name',true)">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{locale.data.truckGroupFields.grpName}}</mat-label>
              <input matInput placeholder="" [(ngModel)]="TruckGroupName" maxlength="200">
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.lt-md="100" style="padding:0 5px;"
            [ngClass]="GetValidationClass('Company Name',true)">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{locale.data.truckGroupFields.grpCompany}}</mat-label>
              <mat-select style="height:16px !important;" [(ngModel)]="selectedCompany"
                (selectionChange)="onCompanySelection()" [disabled]="TruckGroupUID !=null && TruckGroupUID !=''">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let company of TruckingCompanies" [value]="company.truckingcompany_Uid">
                  {{company.truckingcompany_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap">
          <div class="status-con" fxFlex="22" fxFlex.lt-md="22" fxFlex.lt-sm="100" style="padding:0 10px 0 15px;">
            <label>
              <h4 style="font-weight:400;margin-top:0; margin-bottom:12px; color:#979797;">{{locale.data.truckGroupFields.grpStatus}}</h4>
            </label>
            <div style="display:flex;">
              <b
                style="font-weight:400;color:#909090;font-size:16px;margin:0 7px 0 0;">{{TruckGroupStatus?locale.data.truckGroupFields.grpStatusActive:locale.data.truckGroupFields.grpStatusDisable}}</b>
              <!-- <mat-slide-toggle></mat-slide-toggle> -->

              <mat-slide-toggle style="position: relative;top:0px;" [(ngModel)]="TruckGroupStatus"
                class="example-margin" [color]="color" (change)="changed()">
                <!-- Slide me! {{checked}} -->
              </mat-slide-toggle>
            </div>
          </div>
          <div class="method-assigment" fxFlex="78" fxFlex.lt-md="78" fxFlex.lt-sm="100" style="padding:0 5px;"
            [ngClass]="GetValidationClass('Method of Assigment',true)">
            <label style="margin:0 0 10px;color:#979797;">{{locale.data.truckGroupFields.grpAssigmentMethod}}</label>
            <div class="example-button-row method-group-btn" style="width:400px; margin-top:10px;">
              <button mat-raised-button *ngFor="let item of MethodofAssigments;"
                [ngClass]="SelectMethodOfAssigment==item.methodOfAssignment_Cd?'activemethod':''"
                (click)="ChangeMethodOfAssig(item.methodOfAssignment_Cd)">{{item.methodOfAssignment_Dsc}}</button>
              <!-- <button mat-raised-button>Dispatch on Demand</button> -->
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" style="margin-top:1.34375em;">
          <div fxFlex="100" style="padding:0 5px;" [ngClass]="GetValidationClass('Remarks',true)">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{locale.data.truckGroupFields.grpRemarks}}</mat-label>
              <input matInput placeholder="" [(ngModel)]="TruckGroupRemarks" maxlength="400">
            </mat-form-field>
          </div>
        </div>
      </div>


    </mat-card-content>
  </mat-card>



  <mat-card class="example-card" style="box-shadow:none; padding:5px 20px 20px;margin:0;">
    <mat-card-content class="truck-group-list">
      <mat-card>
        <div class="example-container mat-elevation-z8" style="box-shadow: none !important;">
          <div class="mat-card-header">
            <mat-card-title>{{locale.data.truckGroupExpandComponentTitlte}}</mat-card-title>
            <!-- <button mat-raised-button (click)="openDialog()" style="text-transform: uppercase;" >Add Trucks</button> -->
            <div class="btn-export" style="display:-webkit-inline-box;">
              <mat-icon mat-icon-button [ngClass]="{'disabled' : IsIconDisabled() ? true: false}"
                  svgIcon="pdf-grey-scpa-icon" matTooltip="PDF" (click)="downloadtopdf()"></mat-icon>
              <mat-divider [vertical]="true" ></mat-divider>
              <mat-icon  class="svg-xls-icon"
                  svgIcon="xls" matTooltip="Excel"  [ngClass]="{'disabled' : IsIconDisabled() ? true: false}" (click)="downloadtoxlsx()"></mat-icon>
          </div>
          </div>
          <div id="table-container" class="main-table">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: none!important;">
              <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="Truck_Plate">
                <th mat-header-cell *matHeaderCellDef>{{locale.data.truckGroupExpandColumns.truck_plate}} </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox class="example-margin" [(ngModel)]="element.IsChecked"></mat-checkbox>
                  {{element.Truck_Plate}}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="stauts">
                <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.status}} </th>
                <td mat-cell *matCellDef="let element" style="color:#428bca;">{{element.stauts}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.make}} </th>
                <td mat-cell *matCellDef="let element"> {{element.make}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="model">
                <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.model}} </th>
                <td mat-cell *matCellDef="let element"> {{element.model}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="vin">
                <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.vin}} </th>
                <td mat-cell *matCellDef="let element"> {{element.vin}} </td>
              </ng-container>


              <!-- Symbol Column -->
              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.state}} </th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

        </div>
      </mat-card>

    </mat-card-content>
  </mat-card>
  <div fxLayout="column">
    <div class="example-button-row cancel-btn" style="background-color:#fafafa;">
      <button class="closematbtn" mat-raised-button (click)="goHomepage()">{{locale.data.truckgroupbtncancelText}}</button>
      <button style="background-color:#003c5b; color:#fff;margin-left:4px;" mat-flat-button
        (click)="Onsubmit()">{{locale.data.truckgroupbtnSaveText}}</button>
    </div>
  </div>


</section>