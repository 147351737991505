import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '../../services/translateservice.service';
@Component({
    templateUrl: 'confirmdialog.component.html',
    styleUrls: ['confirmdialog.component.scss']
})
export class ConfirmDialogComponent {
    constructor(public locale: TranslateService,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
    }
    cancelClick(): void {
        this.dialogRef.close("cancel");
    }
    okClick(): void {
        this.dialogRef.close("ok");
    }
}