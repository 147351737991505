<div class="custom-tooltip-container noShadow " *ngIf="contentType && contentMsgs">
  <ng-container *ngIf="contentType == 'preAdviceErrorMsg'">
    <div *ngFor="let msg of contentMsgs;let i = index;" class="errmsgclass">
      <span [ngClass]="'priority'+msg.MessageSeverity" *ngIf="i==0 || i==1 || i==2">
          <mat-icon class="warningpriority" *ngIf="msg.MessageSeverity == 1 || msg.MessageSeverity == 2">warning</mat-icon>
          <mat-icon class="errorpriority" *ngIf="msg.MessageSeverity == 3">error</mat-icon>
          <mat-icon class="successpriority" *ngIf="msg.MessageSeverity == 0">info</mat-icon>
          {{msg.MessageText}} 
      </span>
    </div>
  </ng-container>
  
</div>

<div class="text_msg noShadow" *ngIf="!contentType && text">
  <ng-container >
    <div>
      {{text}}
    </div>
  </ng-container>
</div>

