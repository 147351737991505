import { NgModule } from '@angular/core';
import { EmodalHeaderComponent } from './emodal-header.component';
import { LibSharedModule } from '../common/modules/lib-shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';



@NgModule({
  declarations: [EmodalHeaderComponent],
  imports: [
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LibSharedModule
  ],
  exports: [EmodalHeaderComponent]
})
export class EmodalHeaderModule { }
