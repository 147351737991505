import { NA } from "./globalconstants";
import { TranslateService } from "../../shared/services/translateservice.service";
import { Injectable } from "@angular/core";
import { ReturnStatement } from "@angular/compiler";
import { environment } from "../../../environments/environment";
import { formatDate } from '@angular/common';
var moment = require('moment-timezone');
import { Moment } from 'moment';

export class TerminalEIRPreference{
  facility_cd: string;
  timeformat: string;
  weight_unit: string;
}

export interface TerminalEIRPreferences{
  eirpreferences: TerminalEIRPreference[];
  displaygatecode:boolean;
   displaytags:boolean;
}
export class TerminalTradeType{
  tradeType_Cd: string;
  tradeType_Nm: string;
  active: boolean;
  display_Dsc:string;
}

@Injectable({
  providedIn: "root"
})
export class ValidationMethods {
  constructor(private locale: TranslateService) {

   }

  AppDateTimeFormats={
    Date:"MM/DD/YYYY",
    DateTime:"MM/DD/YYYY HH:mm",
  };

  SetAppDateTimeFormats(dateFormat, timeFormat){
    if(dateFormat && timeFormat){
      dateFormat = dateFormat.replace(/y/g, 'Y');       // Fix case for years
       //  format = format.replace(/d{4}/g, '#');    // Protect 4-digit DOW sequence
       //  format = format.replace(/d{3}/g, '&');    // Protect 3-digit DOW sequence
       dateFormat = dateFormat.replace(/d/g, 'D');       // Fix case for days
       dateFormat = dateFormat.replace(/#/g, 'dddd');    // Restore our sequence
       dateFormat = dateFormat.replace(/&/g, 'ddd');     // Restore our sequence
       timeFormat = timeFormat.replace(/tt/g, 'A');

       this.AppDateTimeFormats.Date = dateFormat;
       this.AppDateTimeFormats.DateTime = dateFormat + " " + timeFormat;
    }
  }

  DatePickerLocale: string = "en-US";

  GetDatePickerLocale(){
    return this.DatePickerLocale;
  }

  getEirTickerTimeFormat(timeFormat: string, inputString: string){

    // Validation
    if(inputString === '')
      return '';
    if(inputString.includes('**'))
      return inputString;

    // Conversion
    var is12hrformat: boolean = timeFormat == '12hr';
    let format = is12hrformat ? this.AppDateTimeFormats.Date + ' ' + 'h:mm a' : this.AppDateTimeFormats.DateTime;
    return moment(inputString).format(format);
  }

  AppIsoDateTimeFormats={
    Date:"YYYY-MM-DDT00:00:00Z",
    DateTime:"YYYY-MM-DDThh:mm:ss.mmmZ"
  };
  isEmptyString(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return false;
    }
    return true;
  }
  handleEmptyString(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return NA;
    }
    return inputstring.trim();
  }
  handleEmptyDate(inputstring) {
    if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    if (inputstring.includes("*"))
    {
      return inputstring;
    }
    const regex = /-/gi;
    let formattedDate = inputstring.replace(regex, '/');
    let momentDate = moment(formattedDate);
    let result = momentDate.format(this.AppDateTimeFormats.Date);
    if (result === 'Invalid date'){
      let newDate = moment(inputstring).format(this.AppDateTimeFormats.Date);
      return newDate;
    }

    return result;
  }
  // getDateMomentLocale(){
  //   return moment(this.AppDateTimeFormats.DateTime).locale();
  // }
  handleInputFieldDate(date): string{
    return  moment(date).format(this.AppDateTimeFormats.Date);
  }
  handleInputFieldDateFormat(date): Date{
    let dateString = moment(date).format(this.AppDateTimeFormats.Date);
    return this.handleDate(dateString);
  }
  handleEmptyDateWithComma(inputstring) {
    if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "" || inputstring == NA) {
      return NA;
    }
    if(inputstring.includes("*"))
    {
      return inputstring;
    }
    let dt = inputstring.split(",");
    if (dt.length > 0) {
      return moment(dt[0]).format(this.AppDateTimeFormats.Date) +   (dt.length == 2 ? " ," + dt[1] : "");
    }

    return NA;
  }

  handleEmptyDateWithCommaForPreadiveJobs(inputstring) {
    if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "" || inputstring == NA) {
      return NA;
    }
    if(inputstring.includes("*"))
    {
      return inputstring;
    }
    let dt = inputstring.split(",");
    if (dt.length > 0) {
      return moment(dt[0]).format(this.AppDateTimeFormats.Date);
    }

    return NA;
  }

  handleDate(inputstring: string) {
    if (
      inputstring === null ||
      typeof inputstring === "undefined" ||
      inputstring.trim() === ""
    ) {
      return null;
    }
    return new Date(inputstring);
  }
  handleEmptyDateTimeWithComma(inputstring, format){
    if (inputstring === null ||   typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    if(inputstring.includes("*")){
      return inputstring;
    }
    if(inputstring.includes(',')){
      let dtobj=inputstring.split(',');
      return moment(dtobj[0]).format(format)+ " ," + dtobj[1];
    }
    else
    {
      return moment(inputstring).format(format);
    }
  }

  converttoterminaltimezone(inputstring,timezone,format){
    if (inputstring === null ||   typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    if(inputstring.includes("*")){
      return inputstring;
    }
    let date=moment(inputstring +"Z")
    return date.tz(timezone).format(format);
  }
  handleEmptyDateTime(inputstring) {
    if (inputstring === null ||   typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    var date = new Date(inputstring);
    var dateoptions = { year: "numeric", month: "2-digit", day: "2-digit"};
    var timeoptions = {
      hour12: this.locale.data.hour12,
      hour: "2-digit",
      minute: "2-digit"
    };
    let result = moment(inputstring).format(this.AppDateTimeFormats.DateTime);
    return result;
  }
  handleEmptyDateTimeWithoutOffset(inputstring) {
    if (inputstring === null || typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    var datewithoutoffset = inputstring.substring(0, this.AppDateTimeFormats.DateTime.length);
    let result = moment(datewithoutoffset).format(this.AppDateTimeFormats.DateTime);
    return result;
  }
  getYear(date: Date){
    return moment(date).year();
  }
  getMonth(date: Date){
    return moment(date).month();
  }
  getDate(date: Date){
    return moment(date).date();
  }
  handleEmptyISODateTime(inputstring) {
    if (inputstring === null ||   typeof inputstring === "undefined" || inputstring.trim() === "") {
      return NA;
    }
    var date = new Date(inputstring);
    var dateoptions = { year: "numeric", month: "2-digit", day: "2-digit"};
    var timeoptions = {
      hour12: this.locale.data.hour12,
      hour: "2-digit",
      minute: "2-digit"
    };
    return moment(inputstring).format(this.AppDateTimeFormats.Date);
  }
  isEmptyObject(inputobject: any) {
    if (
      inputobject === null ||
      typeof inputobject === "undefined" ||
      inputobject.length === 0
    ) {
      return false;
    }
    return true;
  }
  isYesOrNo(inputobject: any) {
    if (inputobject === "Y") {
      return this.locale.data.yes;
    } else if (inputobject === "N") {
      return this.locale.data.no;
    }
    return NA;
  }
  restrictSpace(e) {
    if (e.which === 32) e.preventDefault();
  }
  restrictSemiColon(e) {
    var startPos = e.currentTarget.selectionStart;
    if (e.which === 59 && startPos == 0) e.preventDefault();
  }
  trimSpace(val) {
    return val.replace(/\s/g, "");
  }

  trim(val){
    return val.trim();
  }
  validateAlphaNumericString(event, selectedOperator: number) {
    var regex = new RegExp("^[0-9a-zA-Z;]+$");
    var regex1 = new RegExp("^[0-9a-zA-Z]+$");
    var startPos = event.currentTarget.selectionStart;
    var key = String.fromCharCode(
      event.charCode ? event.which : event.charCode
    );
    if (selectedOperator == 10 || selectedOperator == 11) {
      if (startPos == 0 && !regex1.test(key)) {
        event.preventDefault();
        return false;
      } else {
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
      }
    } else {
      if (!regex1.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  }
  EnvironmentConfig(): any {
    if(localStorage.getItem("origin")){
    return   environment.portsconfigs.find(a => a.instance == localStorage.getItem("origin"));
    }
    else{
      //("localStorage.getItem(app_instance) in environment config:" + localStorage.getItem("app_instance"))
      let appinstance = JSON.parse(localStorage.getItem("app_instance"))
        .appInstance_Name;
      let portconfig = environment.portsconfigs.find(
        a => a.clientid == appinstance
      );
      return portconfig;
    }

  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

}
