<mat-card>
<mat-card-title>{{locale.data.truckGroupExpandComponentTitlte}}</mat-card-title>
<table id="truck-details" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="Truck_Plate">
      <th mat-header-cell *matHeaderCellDef >{{locale.data.truckGroupExpandColumns.truck_plate}} </th>
      <td mat-cell *matCellDef="let element" style="width:10%;"> {{element.Truck_Plate}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="stauts">
      <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.status}} </th>
      <td mat-cell *matCellDef="let element" style="color:#428bca;width:15%;">{{element.stauts}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="make">
      <th mat-header-cell *matHeaderCellDef style="width:12%;"> {{locale.data.truckGroupExpandColumns.make}} </th>
      <td mat-cell *matCellDef="let element"> {{element.make}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.model}} </th>
      <td mat-cell *matCellDef="let element"> {{element.model}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="vin">
      <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.vin}} </th>
      <td mat-cell *matCellDef="let element"> {{element.vin}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef> {{locale.data.truckGroupExpandColumns.state}} </th>
      <td mat-cell *matCellDef="let element"> {{element.state}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>
  
  