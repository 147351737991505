import { NgModule } from "@angular/core";
import { MaterialModule } from "./materialmodule.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandlerService } from "../services/errorhandlerservice.serice";
import { CustomViewComponent } from "../components/custom-view/custom-view.component";
import { CustomviewfilterComponent } from "../components/custom-view/customviewfilter.component";
import { CustomviewsaveComponent } from "../components/custom-view/customviewsave.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { DragDirective } from "../directives/dragDrop.directive";
import { DateTimePipe } from '../pipes/DateTimePipe';
import { ToolTipRendererDirective } from '../directives/tool-tip-renderer.directive';
import { MatInfiniteScrollModule } from '../../libraries/matscroller/mat-scroll-module';
import { ConfirmDialogComponent } from "../components/confirmdialog/confirmdialog.component";
import { LoadingBackdropComponent } from "../components/loading-backdrop/loading-backdrop.component";
import { ResponseMessagesComponent } from "../components/responsemessages/responsemessages.component";
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [
    LoadingBackdropComponent,
    CustomViewComponent,
    CustomviewfilterComponent,
    CustomviewsaveComponent,
    DragDirective,
    DateTimePipe,
    ToolTipRendererDirective,
    ConfirmDialogComponent,
    ResponseMessagesComponent,
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInfiniteScrollModule,
    MatIconModule
  ],
  exports: [
    LoadingBackdropComponent,
    CustomViewComponent,
    CustomviewfilterComponent,
    CustomviewsaveComponent,
    DateTimePipe,
    ToolTipRendererDirective,
    ConfirmDialogComponent,
    ResponseMessagesComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    CustomviewfilterComponent,
    CustomviewsaveComponent,
    ResponseMessagesComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerService, multi: true }
  ]
})
export class SharedModule {}
