
<div>
  <div *ngIf="type!='error'" class="muilti-tenant-msg">
    <p>This application cannot be accessed in multiple browser sessions or tabs.
    Please  <a (click)="logout()" style="cursor: pointer; color: dodgerblue;">Click Here</a> to logout from your earlier instance inorder to launch the current application</p> 
  </div>
  <div *ngIf="error_cd=='500'" class="error-msg">
    <img  src="../../assets/images/code500.svg" alt="Landing Background Image">
  </div>
  <div *ngIf="error_cd=='401'" class="error-msg">
    <img  src="../../assets/images/code500.svg" alt="Landing Background Image">
  </div>
  <div *ngIf="error_cd=='404'" class="error-msg">
    <img  src="../../assets/images/code500.svg" alt="Landing Background Image">
  </div>
  <div *ngIf="error_cd=='403'" class="error-msg">
    <img  src="../../assets/images/code500.svg" alt="Landing Background Image">
  </div>
  <div>
    <img style="height: -webkit-fill-available;" src="../../assets/images/500bg.png" alt="Landing Background Image">
  </div>
</div>

