import {
  Component,
  OnInit,
  Pipe,
  ViewChild,
  PipeTransform,
  AfterViewInit,
  OnChanges,
  HostListener
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { map, startWith, switchMap } from "rxjs/operators";
import { APIService } from '../shared/services/httpservicefactory.service';

//@Pipe({ name: "safe" })
@Component({
  moduleId: module.id,
  selector: "ssouserprofile",
  templateUrl: "ssouserprofile.component.html",
  styleUrls: ["ssouserprofile.component.scss"]
})
export class SsouserprofileComponent implements OnInit, AfterViewInit, OnChanges {
  profileUrl: SafeResourceUrl;
  isValid: boolean = false;
  innerheight: any;
  //headerTitle = "User Profile";
  @ViewChild('iframesso') iframesso;
  constructor(
    public apiService: APIService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.route.queryParams.subscribe(params => {
      if (params["name"] != undefined) {
        this.isValid = true;
        this.profileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          params["name"]
        );

      } else {
        this.isValid = false;
      }
    });
    this.onResize(Event);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerheight = window.innerHeight;
    this.innerheight = this.innerheight - 170;
  }
  ngOnChanges() {
    //this.headerTitle = this.apiService.adminsettingsTitle;
  }
  ngAfterViewInit() { }

  ngOnInit() { }
}
