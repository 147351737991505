import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UserViewModel, SaveUserViewModel, UserLocaleModel, UserLandingPageModel, ITerminalPregatestatus, MoveRefConfig } from '../Interfaces/customview.interface';
import { environment } from '../../../environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { apiurls, httpOptions, NA, TradeTypes } from '../../common/utils/globalconstants';
// import { IUserInfo } from '../Interfaces/userinfo.interface';
import { ValidationMethods } from '../../common/utils/validationmethods';
import { CookieService } from "ngx-cookie-service";
import { IMoveFieldMap, IMoveFieldMask, IFacility, IPort } from '../Interfaces/fieldmap.interface';
import { IUserInfo } from 'emodal-header/common/models/userinfomodel';
//import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class APIService implements OnInit {
  //need to add config file for these urls as base url will be change for QA/UAT/PROD
  private getAppInterfaceurl = '/AppInterface/getappinterface';
  private insertUserView = "/CustomView/insertuserview";
  private getUserViews = "/CustomView/getuserviews";
  private deleteUserView = "/CustomView/deleteuserview";
  private getappinstance = '/AppInstance/getappinstance';
  private getappinstancetradetypes = '/AppTradeTypes';
  private getUserViewsByUserUid = '/CustomView/getuserviewsbyuseruid';
  // private getUserLocale = '/UserLocale/getuserlocale';
  // private saveUserLocale = '/UserLocale/saveuserlocale';
  private getUserLandingPage = '/UserLandingPage/getuserlandingpage';
  private saveUserLandingPage = '/UserLandingPage/saveuserlandingpage';
  public lApp_Id = "";
  public User_Uid = "";
  public UserFacilities = [];
  public adminsettingsTitle = "";
  public usercompanies: {
    app_Id: number,
    companyType_Cd: string,
    company_Id: number,
    company_Nm: string,
    company_Uid: string,
    scac: string,
    interfaces: string[]
  }[] = [];
  preadvicetype: string = "";
  movefieldmaplist: IMoveFieldMap[] = [];
  public facilities: IFacility[] = [];
  public ports: IPort[] = [];
  hideDomesticTradeType: boolean;
  moveRefConfigs: MoveRefConfig[] = [];
  //eModalPlatform API
  constructor(private httpClient: HttpClient, private validate: ValidationMethods, private cookieService: CookieService) {

  }
  ngOnInit() {
  }
  UserInfo() {
    if (this.validate.isEmptyObject(localStorage.getItem("userInfo" + "_" + environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim()).clientid)) && (this.User_Uid == "" || this.ports.length == 0)) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + "_" + environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim()).clientid));
      this.User_Uid = userInfo.user_Uid;
      this.UserFacilities = userInfo.datascopes;
      this.usercompanies = userInfo.claims.userAffiliations;
      this.ports = [];
      this.facilities = [];
      this.ports = Array.from(new Set(this.UserFacilities.map((item: any) => item.port_Cd)));
      this.facilities = Array.from(new Set(this.UserFacilities.map((item: any) => item.facility_Cd)));
    }
  }
  //Common header attribute
  headers = new HttpHeaders().set("Content-Type", "application/json");

  //Excel Download
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'DisplayList': worksheet }, SheetNames: ['DisplayList'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportToExcelFile(json: any[], excelFileName: string, columnNames: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'DisplayList': worksheet }, SheetNames: ['DisplayList'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_list' + EXCEL_EXTENSION);
  }
  //#region  PortCheck API
  getInterfaceView(Interface_Uid: string): Observable<any> {
    if (this.validate.isEmptyObject(localStorage.getItem("app_instance")) && this.lApp_Id == "") {
      let appinstance = JSON.parse(localStorage.getItem("app_instance"));
      this.lApp_Id = appinstance.appInstance_Id;
    }
    return this.httpClient.get<any[]>(environment.portcheckapibaseurl + this.getAppInterfaceurl + '?Interface_Uid=' + Interface_Uid + '&lApp_Id=' + this.lApp_Id).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  saveUserInterfaceView(view: SaveUserViewModel): Observable<any> {
    return this.httpClient.post<string>(environment.portcheckapibaseurl + this.insertUserView, view, { headers: this.headers }).pipe(
      tap(data => {
        return JSON.parse(data);
      }),
      catchError(this.handleError)
    );
  }
  getUserSavedViews(lAppInterface_Id: number, sUser_Uid: string): Observable<any> {
    return this.httpClient.get<any[]>(environment.portcheckapibaseurl + this.getUserViews + '?lAppInterface_Id=' + lAppInterface_Id + '&sUser_Uid=' + sUser_Uid).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  deleteUserViewById(UserView_Id: number): Observable<any> {
    return this.httpClient.delete<any[]>(environment.portcheckapibaseurl + this.deleteUserView + '?lUserView_Id=' + UserView_Id).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  //#endregion



  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  getuserManagement(url: string): Observable<IUserInfo> {
    return this.httpClient.get<IUserInfo>(url).pipe(
      tap(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  jobequipment(drayunit_uid: string): Observable<any> {
    return this.httpClient.get<any>(environment.emodaldatahubapibaseurl + apiurls.searchequipmentapi + "/" + drayunit_uid
    ).pipe(
      tap(data => ""), catchError(this.handleError)
    );
  }

  getAppInstance(InstanceUrl: string): Observable<any> {
    return this.httpClient.get<any[]>(environment.portcheckapibaseurl + this.getappinstance + '?InstanceUrl=' + InstanceUrl.trim()).pipe(
      tap(data => {

        return data;


      }),
      catchError(this.handleError)
    );
  }

  getViewsByUser(
    lAppInterface_Id: number,
    Interface_Uid: string,
    sUser_Uid: string
  ): Observable<any> {
    if (
      this.validate.isEmptyObject(localStorage.getItem("app_instance")) &&
      this.lApp_Id == ""
    ) {
      let appinstance = JSON.parse(localStorage.getItem("app_instance"));
      this.lApp_Id = appinstance.appInstance_Id;
    }
    return this.httpClient.get<any[]>(environment.portcheckapibaseurl
      + this.getUserViewsByUserUid + '?lApp_Id= ' + this.lApp_Id
      + '&Interface_Uid=' + Interface_Uid
      + '&lAppInterface_Id=' + lAppInterface_Id
      + '&sUser_Uid=' + sUser_Uid).pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }


  GetUserLandingPage(): Observable<UserLandingPageModel> {
    return this.httpClient.get<UserLandingPageModel>(environment.portcheckapibaseurl
      + this.getUserLandingPage
      + '?sUser_Uid=' + this.User_Uid)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }


  redirectPost(url, args: any): void {
    let frm = document.createElement("form");
    frm.method = "post";
    frm.action = url;
    if (Array.isArray(args) || args instanceof Array) {
      args.forEach(element => {
        let childele = document.createElement("input");
        childele.type = "hidden";
        childele.name = element.key;
        childele.value = element.value;
        frm.appendChild(childele);
      });
    } else if (typeof args === 'object') {
      let keys = Object.keys(args);
      for (let key of keys) {
        let childele = document.createElement("input");
        childele.type = "hidden";
        childele.name = key;
        childele.value = args[key];
        frm.appendChild(childele);
      }

    }

    document.body.appendChild(frm).submit();

  }

  GetTruckGroupsByUser(userId: string) {
    return this.httpClient.get(`${environment.dtr2apibaseurl}TruckGroupManagement/GetTruckGroupsByUser?User_Uid=${userId}`);
  }

  GetTrucksbyTruckingCompany(spCompany_Uid: string) {
    return this.httpClient.get(`${environment.dtr2apibaseurl}TruckGroupManagement/GetTrucksbyTruckingCompany_V1?spCompany_Uid=${spCompany_Uid}`);
  }
  GetUserTruckingCompany(spUser_Uid: string) {
    return this.httpClient.get(`${environment.dtr2apibaseurl}TruckGroupManagement/GetUserTruckingCompany?User_Uid=${spUser_Uid}`);
  }

  GetMethodofAssignments() {
    return this.httpClient.get(`${environment.dtr2apibaseurl}TruckGroupManagement/GetMethodofAssignments`);
  }

  GetTruckGroupDetailsById(Truckgroup_Uid: string) {
    return this.httpClient.get(`${environment.dtr2apibaseurl}TruckGroupManagement/GetTruckGroupDetailsById?Truckgroup_Uid=${Truckgroup_Uid}`);
  }
  UpdateTruckGroup(data: any) {
    return this.httpClient.post(`${environment.dtr2apibaseurl}TruckGroupManagement/UpdateTruckGroup`, data);
  }

  CreateTruckGroup(data: any) {
    return this.httpClient.post(`${environment.dtr2apibaseurl}TruckGroupManagement/CreateTruckGroup`, data);
  }

  UpdateTruckGroupStatus(data: any) {
    return this.httpClient.post(`${environment.dtr2apibaseurl}TruckGroupManagement/UpdateTruckGroupStatus`,data)
  }

  DeleteTruckGroup(data: any) {
    return this.httpClient.post(`${environment.dtr2apibaseurl}TruckGroupManagement/DeleteTruckGroup`,data)
  }
}
