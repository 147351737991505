import { NgModule, OnInit, Directive } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/modules/materialmodule.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/modules/sharedmodule.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInfiniteScrollModule } from '../libraries/matscroller/mat-scroll-module';
import {IndexComponent} from './index/index.component';
import { ExpandcollapsepanelComponent } from './expandcollapsepanel/expandcollapsepanel.component';
import { TruckGroupComponent } from './truck-group/truck-group.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import { FlexLayoutModule } from '@angular/flex-layout';


//@Directive()
@NgModule({
    declarations: [
        IndexComponent,
        ExpandcollapsepanelComponent,
        TruckGroupComponent,
    
     ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MaterialModule,
        SharedModule,
        BrowserAnimationsModule,
        MatInfiniteScrollModule,
        MatFormFieldModule,
        MatCardModule,
        MatTabsModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDialogModule,
        FlexLayoutModule
    ],
    exports: [
        IndexComponent,
        TruckGroupComponent
     ],
     entryComponents: [
    
   ]
})

export class POVTruckGroupModule implements OnInit{
    constructor() { }
    ngOnInit() { }  
}
