import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss']
})
export class ErrorpageComponent implements OnInit {
  error_cd: any;
  type: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService, private authService: AuthService,
    private oidcSecurityService: OidcSecurityService,) {
    this.route.queryParams.subscribe(params => {
      this.error_cd = params["error"];
      this.type = params["type"];
    })
  }

  ngOnInit() {
  }

  logout() {
    if (this.cookieService.get("AuthCookie")) {
      let acookie = JSON.parse(this.cookieService.get("AuthCookie"));
      if (acookie) {
        this.cookieService.set("Clientid", acookie.client_id)
        let cookie_clietid = this.cookieService.get("Clientid");
        if (cookie_clietid == acookie.client_id) {
          let filterinstance= environment.portsconfigs.filter(a => a.clientid == cookie_clietid);//empty
          if (filterinstance && filterinstance.length) {
            let instance = environment.portsconfigs.find(a => a.clientid == cookie_clietid).instance;
            let appinstance = environment.portsconfigs.find(a => a.instance == window.location.origin).clientid
            window.open(instance + "/logout", "_blank");
            if (appinstance == "PCGWRR" || appinstance == "PCNYNJ" || appinstance == 'PCPOV') {
              window.location.href = environment.portsconfigs.find(a => a.clientid == appinstance).cmsRedirectUri;
            }
            else {
              window.location.href = environment.portsconfigs.find(a => a.clientid == appinstance).instance;
            }
          }
          else{
            let legacyapp=environment.legacyapprul
            window.open(legacyapp,"_blank");
            window.location.href=window.location.origin + "/logout";  
          }
        }
      }
    }
  }

}
