import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-backdrop',
  templateUrl: './loading-backdrop.component.html',
  styleUrls: ['./loading-backdrop.component.scss']
})
export class LoadingBackdropComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
