<!-- <div class="div-loader">
    <mat-spinner style="left:50%;top:45%;"></mat-spinner>
    <span style="top:60%;position: absolute">Please Wait.....</span>
  </div> -->


      <div class="loading-screen-wrapper">
        <div class="loading-screen-icon" style="font-size: 31px;
        font-weight: 600;
        opacity: 0.9;">
          Loading...
        </div>
      </div>