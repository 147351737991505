import { Injectable } from '@angular/core';
import { IInterfacesAccess, IInterfaceActions } from '../models/userinfomodel';
import { INotificationViewModel } from '../models/notificationmodel';

@Injectable({
  providedIn: "root"
})
export class SharedService {
  public accessInterfaces: IInterfacesAccess[] = [];
  public interfaceActions: IInterfaceActions[] = [];
  facilities = [];
  sizetypes = [];
  public currentUri: string = "";
  public email: string;
  public notificationModel: INotificationViewModel;
  constructor() { }

  EnvironmentConfig(environment): any {
    if(localStorage.getItem("origin")){
    return   environment.portsconfigs.find(a => a.instance == localStorage.getItem("origin"));
    }
    else{      
      let appinstance = JSON.parse(localStorage.getItem("app_instance"))
        .appInstance_Name;
      let portconfig = environment.portsconfigs.find(
        a => a.clientid == appinstance
      );
      return portconfig;
    }

  }

}
