import { HttpHeaders } from '@angular/common/http';
export const NA: string = "N/A"
export const ContainerInactive="Container is Inactive.";
export const apiurls =
{
  drayunitstatusapi: '/drayunit-status',
  portfacilityapi: '/portfacility/userportfacilities',
  allportfacilityapi: '/portfacility',
  movetypesapi: '/pregate/movetypes',
  facilityisoarchtypesapi: '/pregate/facilityisoarchtypes',
  movefieldmaskapi: '/pregate/movefieldmask',
  pregatestatusapi: '/pregate-status',
  shippinglineapi: '/shippinglines',
  containerwatchlistapi: '/container/accesslist',
  Addcontainerwatchlistapi: '/container/AddContainers',
  jobWatchlistApi: '/job/accesslist',
  vesselscheduleapi: "/vesselschedule",
  bookingwatchlistapi: '/booking/accesslist',
  equipmentwatchlistapi: '/drayunits/containers',
  searchequipmentapi: '/drayunit',
  groupcodeapi: '/groupcode/accesslist',
  gateorderapi: '/gateorder/accesslist',
  containerdashboardapi: '/container/accesslist/query',
  containerstatus: '/drayunit-status',
  globalsearchgroupcodeapiapi: '/groupcode',
  globalsearchbookingapi: "/booking",
  preadviceaddapi: "/pregate",
  notificationconfigbyappuidapi: "/notifications/",
  setusernotificationsapi: "/notifications/subscription",
  getusernotificationsapi: "/notifications/subscriptions",
  sendnotificationbroadcastapi: "/notifications/broadcast",
  getCartItems: "http://paymentcart.pe4-emodalqa.com/Integration/CartItems?_=",
  addCartItem: "http://paymentcart.pe4-emodalqa.com/Integration/AddItems",
  getreservationstatus: "/pregate/status",
  movefieldmap: "/pregate/movefieldmap",
  jobeditapi: "/job/update",
  chassispoolapi: "/chassispool",
  unitsizetypeapi: "/unitsizetype",
  statesapi: "/Common/GetStatesByCountry",
  companytypeapi: "/Common/GetCompanyType",
  broadcastemailidapi: "/Common/GetBroadcastEmailId",
  appointmentcancelapi: "/pregate/move",
  getusercompanywiseapi: "/SecurityGroup/GetUserCompanyWise",
  terminalpinconfigapi: "/pregate/terminalpinconfig",
  hazmatclassapi:"/hazmathrefdata/GetHazmathClassInfoAsync",
  hazmatpkggrpapi:"/hazmathrefdata/GetPackageGroupAsync",
  payfeegetdrayunitinstance: "/",
  getcontainerfeedue: "/fee/containerfeesdue",
  getcontainerfee: "/fee/containerfee/",
  getcontainerfeebydrayunituids: "/fee/containerfeesbydrayunituids",
  getdrayunitids: "/fee/containerfeeinventory/",
  terminalpregatestatus: "/tosappointmentstatus/GetTOSAppointmentStatusInfoAsync",
  referbooking:"/booking",
  moveRefConfig:"/MoveRefConfig",
  addbookingswithtags:"/booking/addbookingswithtags",
  addgroupcodewithtags:"/groupcode/addgroupcodeswithtags",
  updatetagsforcontainer:"/container/UpdateTagsAsync",
  updatetagsforbooking:"/booking/UpdateTagsAsync",
  updatetagsforgroupcode:"/groupcode/UpdateTagsAsync"
};
export const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
export const dateFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric'
}

export const ColorScheme = {
  "NOTREADYFORSPEEDGATE": "#E60F07",
  "REBOOKED": "#44BC30",
  "CONFIRMED": "#5CB85C",
  "COMPLETED": "#76A22B",
  "NOTCONFIRMED": "#FF4500",
  "INPROGRESS": "#0088E8",
  "TROUBLED": "#E60F07",
  "RESCHEDULED": "#0088E8",
  "INTERMEDIATE": "#14A8C7",
  "MISSED": "#FAB032",
  "PENDING": "#FF9800",
  "ERROR": "#E60F07",
  "*CONFIRMED": "#F98831",
  "READYFORSPEEDGATE": "#5ABD4A",
  "CANCELED": "#E60F07",
  "CANCELEDBYTERMINAL": "#E60F07",
  "READY FOR PREGATE": "#008000",
  "NOT READY FOR PREGATE": "#E60F07",
  "N/A": "#3C3C3C"
}

export const JobStatusColorScheme = {
  "ASSIGNED": "#8F495C",
  "ACCEPTED": "#AB2FB5",
  "COMPLETED": "#008000",
  "IN PROGRESS": "#0088E8",
  "PENDING": "#FFA500",
  "CANCELED": "#EF0000",
  "N/A": "#3C3C3C"
}

export const FacilityColorScheme = {
  "LBCT": "#E60F07",
  "LBCTE": "#44BC30",
  "MATNAV": "#CD5C5C",
  "T30": "#76A22B",
  "SCSPA": "#FF4500",
  "ETSOAK": "#001B52",
  "TRPOAK": "#E4CF2F",
  "B58": "#69D2E7",
  "ITS": "#14A8C7",
  "TRP1": "#FAB032",
  "HUSKY": "#FFFF00",
  "APLGGS": "#2E8B57",
  "PCT": "#008B8B",
  "T18": "#5ABD4A",
  "PA": "#DDA0DD",
  "ETSLAX": "#1E90FF",
  "CUTLAX": "#DA70D6",
  "PNCT": "#9400D3",
  "M60": "#3C3C3C",
  "PET": "#7B68EE",
  "YTI-LA": "#008000",
  "WUT": "#0000FF",
  "STL": "#CD853F",
  "STA": "#F4A460",
  "B63": "#EE0E81",
  "SMT":"#EE0E81",
  "STS":"#EE0E81",
  "DMT ":"#EE0E81",
  "PAMT":"#EE0E81",
  "STO":"#EE0E81",
  "FCP":"#EE0E81",
  "WT":"#FF4500",
  "NC":"#2E8B57",
  "901PAGE":"#EE0E81",
  "APDB":"#EE0E81",
  "APMT-NWK":"#EE0E81",
  "ATLNTC":"#EE0E81",
  "AVLBST":"#EE0E81",
  "AVLIND":"#EE0E81",
  "BIJ":"#EE0E81",
  "BRMBNA":"#EE0E81",
  "CEVAHY":"#EE0E81",
  "CEVANW":"#EE0E81",
  "CFRAD":"#EE0E81",
  "CFRRL":"#EE0E81",
  "DMT":"#EE0E81",
  "DWELL":"#EE0E81",
  "ETSTCM":"#EE0E81",
  "ETSTIW":"#EE0E81",
  "FIT":"#EE0E81",
  "GCTB":"#EE0E81",
  "GCTN":"#EE0E81",
  "GF1":"#EE0E81",
  "GF1IR":"#EE0E81",
  "GLOBAL":"#EE0E81",
  "GSA":"#EE0E81",
  "GT":"#EE0E81",
  "HGSLS":"#EE0E81",
  "HGSNT":"#EE0E81",
  "LTHRPMC":"#EE0E81",
  "LTHRPSW":"#EE0E81",
  "MAHR":"#EE0E81",
  "MCT":"#EE0E81",
  "MDLBST":"#EE0E81",
  "MDLRD":"#EE0E81",
  "MDLTRC":"#EE0E81",
  "MTLFS":"#EE0E81",
  "NYCT":"#EE0E81",
  "OECIRG":"#EE0E81",
  "OECLYF":"#EE0E81",
  "OECTYL":"#EE0E81",
  "OMNIG":"#EE0E81",
  "PRLFC":"#EE0E81",
  "PRLIND":"#EE0E81",
  "PRLLD":"#EE0E81",
  "RED":"#EE0E81",
  "RHCTB":"#EE0E81",
  "RHCTN":"#EE0E81",
  "T6":"#EE0E81",
  "TRD":"#EE0E81",
  "TRPJAX":"#EE0E81",
  "TSLBG":"#EE0E81",
  "TSLDLM":"#EE0E81",
  "TSLFMRB":"#EE0E81",
  "TSLFNWD":"#EE0E81",
  "TSLL":"#EE0E81",
  "TSLMNV":"#EE0E81",
  "TSLSD":"#EE0E81",
  "TSLSPDC":"#EE0E81",
  "TSTFAC":"#EE0E81",
  "VITAP":"#EE0E81",
  "VITNI":"#EE0E81",
  "SMS":"#EE0E81",
  "NTS":"#EE0E81",
  "CM":"#EE0E81",
  "SB63":"#EE0E81",
  "CMJ":"#EE0E81",
  "SA":"#EE0E81",
  "NCT":"#DDB0DD",
  "PSA":"#EDDE81",
  "CCT":"#EE0E81",
  "MIT":"#DDA0DD",
  "PPC":"#EE5E81"
}
// for building dynamic menu in job action
export const jobmenu={
  job_edit: "Edit",
  job_assign_priority: "Assign Dispatch Priority",
  job_assign_carrier: "Assign Motor Carrier",
  job_activate_job: "Activate Job",
  job_confirm_pickup: "Confirm Pickup",
  job_confirm_dropoff: "Confirm Dropoff",
  job_complete:"Complete"
}

export const TradeTypes = {
  Import: 'i',
  Export: 'x',
  Domestic: 'd'
}
