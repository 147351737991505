import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'forbidden',
    templateUrl: 'forbidden.component.html',
    styleUrls: ['forbidden.component.scss']
})
export class ForbiddenComponent {

}
