import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { APIService } from './httpservicefactory.service';
import { AuthService } from './auth.service';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { CookieService } from "ngx-cookie-service";
@Injectable()
export class DefaultGuardService implements CanActivate {
  constructor(private router: Router,private aPIService: APIService,private authService: AuthService,
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService) {}
  public canActivate(): Observable<boolean> | boolean {

    //navigate to user default landing page
    this.aPIService.getuserManagement(environment.userinfoendpoint).subscribe(
      async Response => {
        if (Response && Response.claims && Response.claims.menu.length) {
          if(Response.userPreferences && Response.userPreferences.homePage){
              let instancedomain=environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name")).domainname;
              let expiredDate = new Date();
              expiredDate.setDate(expiredDate.getDate() + environment.expiredays);
              let ref_token=this.oidcSecurityService.getRefreshToken();
                this.cookieService.set(
                  "AuthCookie",
                  JSON.stringify({
                    bearer: this.oidcSecurityService.getToken(),
                    refresh_token:ref_token ? ref_token : "" ,
                    client_id: localStorage.getItem("appInstance_Name")
                  }),
                  expiredDate,
                  "/",
                  instancedomain,
                  false,
                  "Lax"
                    );
                    console.log("AuthCookie:+=" +this.cookieService.get("AuthCookie"));
                if(window.location.origin.includes("http://localhost:55040")){//for dev environment
                  window.location.href="http://localhost:55040/povtruckgroup";
                }
                // else if(window.location.origin.includes("http://localhost:4000")){
                //   window.location.href="http://localhost:4000/mycontainer";
                // }
                else {
                  window.location.href=Response.userPreferences.homePage;
                }
               
          }
        } else {
         this.authService.clearsession();
         this.oidcSecurityService.logoff();
        }
      },
      error => {
        console.log(error);
        console.log(error.status);
        if(error!=null && error.status){
          this.router.navigate(["/errorpage"], {
            queryParams: { error:error.status,type:"error"}
          });
        }
      }
    );
    


    return true;
  }
}
