<!-- <mat-card class="page-header">
    <mat-card-content fxLayout="row wrap">
        <span class="headertitle">{{headerTitle}}</span>
    </mat-card-content>
</mat-card> -->
<iframe *ngIf="isValid" id="ssoprofileframe" name="ssoprofileframe" [style.height.px]='innerheight' [style.max-height.px]='innerheight' [src]="profileUrl" frameborder="0"
    allowFullScreen="true"></iframe>
<forbidden *ngIf="!isValid"></forbidden>


