import {version} from '../../package.json'
export const environment = {
  production: true,
  emodaldtrapibaseurl: "http://pe1apigateway.uatemodal.com/DTR",
  portcheckapibaseurl: "http://pe1apigateway.uatemodal.com/UIManager",
  dtr2apibaseurl:"http://10.20.40.242:8085/",
  emodaldatahubapibaseurl: "http://pe1apigateway.uatemodal.com/DataHub",
  emodalnotificationapibaseurl: "http://pe1apigateway.uatemodal.com/Notifications",
  emodalpregateapibaseurl: "http://pe1apigateway.uatemodal.com/Pregate",
  emodalsecuritymgrurl: "http://pe1apigateway.uatemodal.com/SecurityMgr/api",
  portcheckurl: "/signin-oidc",
  portchecklogouturl: "/signin-oidc",
  identityserverurl: "http://pe1id.uatemodal.com",
  autouserinfo: true,
  silentrenew: true,
  max_id_token_iat_offset_allowed_in_seconds: 180,
  registrationUri: "http://pe1account.uatemodal.com/Registration/Registration/Index?Client_id=",
  postloginroute: '/default',
  responsetype: "id_token token",
  scopessupported: "openid profile sso_auth_api security_web_auth_api sso_custom_endpoint",
  userinfoendpoint: "http://pe1id.uatemodal.com/identity/userinfo",
  expiredays: 5,
  historycleanupoff: true,
  isauthorizedrace_timeout_in_seconds:15,
  interfaces:{
    mycontainer:'b5b7cb3c-f2dd-4543-8ea3-d058511cf826',
    mybooking:'d347c2ce-a155-46d0-b64f-1c71b1261789',
    groupcode:'e75334b6-0644-4512-aa99-f930c5b59f46',
    vesseschedule:'1f740763-d01c-4e5a-8017-ec9d633d7128',
    myjobs: "efaa2965-c710-407f-99ed-60c293478c0c",
    mygateorder: "B1DC68FB-9827-4662-A3B2-ED8AAF1C6CB4"
  },
  portsconfigs: [
    {
      instance: "http://localhost:55040ggg",//http://localhost:55040
      clientid: "PCEMODAL",
      title:"eModal",
      iconpath:"../../assets/images/emodal-brand.png",
      cookiebotid:"c8c67ac1-dcbb-46ce-8033-7e9a851fd3c9",
      enablecookiebot:false,
      datablockingmode:'none',
      Portcodes: ["OAK"],
      ExludePregateStatus:["A","I","S","B","E","F"],
      theme: "emodal",
      legacypregateurl:"http://pe1appointments.uatemodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl:"http://pe1availability.uatemodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-90096803-18",
      analyticsTrackingId: "UA-90096803-18",
      mybooking_InterfaceId: 19,
      mycontainer_InterfaceId: 20,
      vesselschedule_InterfaceId: 21,
      groupcodes_InterfaceId: 22,
      myjobs_InterfaceId: 33,
      showglobalsearch:false,
      supportmail:"helpdesk@eModal.com",
      emodalvideotutorial: "https://www.emodal.com/Support/HelpLinks.aspx",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired:true,
      paymentcarturl: "http://pe1paymentcart.uatemodal.com",
      cart: "http://pe1paymentcart.uatemodal.com/cart",
      paymentSource: "http://pe1paymentcart.uatemodal.com/PaymentSource",
      userPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
      showpregatedate:true,
      showProviderForBarePick:true,
      showterminalstatus:true,
      domainname: ".uatemodal.com",
      versionnumber:version,
      paynowbutton:true,
      hideDomesticTradeType:false,
      hideTradeType: false,
      showexternalvisitref_nbr:true,
      thresholdcount:500,
      emodalHeaderConfigs: {
        title: "Port Manager",
        //title: "eModal Community Portal",
        enableCart: true,
        enableNotification: true,
        languagefile:"pcemodal",
        enableHelp: true,
        enableSetting: true,
        cartConfigs: {
          // cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
          // cart: "http://pe1paymentcart.uatemodal.com/cart",
          // cartItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/cartitemsinformation",
          // addItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/additems"
          cart: "http://pe1paymentcart.uatemodal.com/cart",
          cartItemsUrl: "http://pe1paymentcart.uatemodal.com/Integration/CartItemsInformation",
          addItemsUrl: "http://pe1paymentcart.uatemodal.com/Integration/AddItems"
        }
      }
    },
    {
      instance: "http://localhost:550466",
      clientid: "PCSCPA",
      title:"SCPA Port Manager",
      iconpath:"../../assets/images/scpa.png",
      cookiebotid:"",//42ae14b2-0725-4b36-8deb-d75c13868c35
      enablecookiebot:false,
      datablockingmode:'auto',
      Portcodes: ["OAK"],
      ExludePregateStatus:["A","I","S","B","E","F"],
      theme: "scpa",
      legacypregateurl:"http://pe1appointments.uatemodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl:"http://pe1availability.uatemodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-140684522-1",
      analyticsTrackingId: "UA-140684522-1",
      mybooking_InterfaceId: 35,
      mycontainer_InterfaceId: 36,
      vesselschedule_InterfaceId: 37,
      groupcodes_InterfaceId: 38,
      myjobs_InterfaceId: 39,
      showglobalsearch:false,
      supportmail:"solutionscenter@scspa.com",
      emodalvideotutorial: "http://scpaportmanager.zendesk.com",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired:true,
      paymentcarturl: "http://pe1paymentcart.uatemodal.com",
      cart: "http://pe1paymentcart.uatemodal.com/cart",
      paymentSource: "http://pe1paymentcart.uatemodal.com/PaymentSource",
      userPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
      showpregatedate:false,
      showProviderForBarePick:false,
      showterminalstatus:false,
      domainname: ".uatemodal.com",
      versionnumber:version,
      paynowbutton:false,
      cmsRedirectUri:"http://scpacms.uatemodal.com/",
      hideDomesticTradeType:false,
      hideTradeType: false,
      thresholdcount:6000,
      cmsEnabled:true,
      showexternalvisitref_nbr:false,
      emodalHeaderConfigs: {
        title: "Port Manager",
        logopath: "./assets/images/scpa-logo.svg",
        enableCart: true,
        enableNotification: true,
        languagefile:"emodal",
        enableHelp: true,
        enableSetting: true,
        cartConfigs: {
          cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
          cart: "http://pe1paymentcart.uatemodal.com/cart",
          cartItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/cartitemsinformation",
          addItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/additems"
        }
      }
    },
    {
      instance: "http://gwrr.uatemodal.com",
      clientid: "PCGWRR",
      title:"G&W Intermodal Manager",
      iconpath:"../../assets/images/g&w-logo.svg",
      cookiebotid:"",
      enablecookiebot:false,
      datablockingmode:'auto',
      Portcodes: ["OAK"],
      ExludePregateStatus:["A","I","S","B","E","F"],
      theme: "genesee",
      legacypregateurl:"http://pe1appointments.uatemodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl:"http://pe1availability.uatemodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-140684522-1",
      analyticsTrackingId: "UA-140684522-1",
      mybooking_InterfaceId: 46,
      mycontainer_InterfaceId: 47,
      vesselschedule_InterfaceId: 48,
      groupcodes_InterfaceId: 49,
      myjobs_InterfaceId: 50,
      gateorder_InterfaceId:67,
      showglobalsearch:false,
      supportmail:"helpdesk@eModal.com",
      emodalvideotutorial: "https://www.emodal.com/Support/HelpLinks.aspx",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired:false,
      paymentcarturl: "http://pe1paymentcart.uatemodal.com",
      cart: "http://pe1paymentcart.uatemodal.com/cart",
      paymentSource: "http://pe1paymentcart.uatemodal.com/PaymentSource",
      userPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
      showpregatedate:true,
      showProviderForBarePick:true,
      showterminalstatus:false,
      domainname: ".uatemodal.com",
      versionnumber:version,
      paynowbutton:false,
      cmsRedirectUri:"http://csmgwrr.uatemodal.com/",
      hideDomesticTradeType:false,
      hideTradeType: true,
      showexternalvisitref_nbr:false,
      thresholdcount:6000,
      emodalHeaderConfigs: {
        title: "G&W Port Manager",
        logopath: "./assets/images/g&w-logo.svg",
        enableCart: true,
        enableNotification: true,
        languagefile:"g&w",
        enableHelp: true,
        enableSetting: true,
        cartConfigs: {
          cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
          cart: "http://pe1paymentcart.uatemodal.com/cart",
          cartItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/cartitemsinformation",
          addItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/additems"
        }
      }
    },
    {
      instance: "http://ptpportmanager.uatemodal.com",
      clientid: "PCNYNJ",
      title:"NYNJ Port Manager",
      iconpath:"../../assets/images/ptp-favicon.svg",
      cookiebotid:"",
      enablecookiebot:false,
      datablockingmode:'none',
      Portcodes: ["OAK"],
      ExludePregateStatus:["A","I","S","B","E","F"],
      theme: "nynj",
      legacypregateurl:"http://ptpappointments.uatemodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl:"http://ptpavailability.uatemodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-140684522-1",
      analyticsTrackingId: "UA-140684522-1",
      mybooking_InterfaceId: 51,
      mycontainer_InterfaceId: 52,
      vesselschedule_InterfaceId: 53,
      groupcodes_InterfaceId: 54,
      myjobs_InterfaceId: 55,
      showglobalsearch:false,
      supportmail:"helpdesk@eModal.com",
      emodalvideotutorial: "https://www.emodal.com/Support/HelpLinks.aspx",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired: true,
      paymentcarturl: "http://pe1paymentcart.uatemodal.com",
      cart: "http://pe1paymentcart.uatemodal.com/cart",
      paymentSource: "http://pe1paymentcart.uatemodal.com/PaymentSource",
      userPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
      showpregatedate:false,
      showProviderForBarePick:true,
      showterminalstatus:false,
      domainname: ".uatemodal.com",
      versionnumber:version,
      paynowbutton:false,
      cmsRedirectUri:"http://qaporttruckpass.com",
      defaultroute:"http://ptpportmanager.uatemodal.com/login",
      hideDomesticTradeType:false,
      hideTradeType: false,
      showexternalvisitref_nbr:false,
      thresholdcount:6000,
      emodalHeaderConfigs: {
        title: "NYNJ Port Manager",
        logopath: "./assets/images/ptp-logo.png",
        enableCart: true,
        enableNotification: true,
        languagefile: "pcemodal",
        enableHelp: true,
        enableSetting: true,
        cartConfigs: {
          // cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
          // cart: "http://pe1paymentcart.uatemodal.com/cart",
          // cartItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/cartitemsinformation",
          // addItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/additems"
          cart: "http://pe1paymentcart.uatemodal.com/cart",
          cartItemsUrl: "http://pe1paymentcart.uatemodal.com/Integration/CartItemsInformation",
          addItemsUrl: "http://pe1paymentcart.uatemodal.com/Integration/AddItems"
        }
        
      }
    },
    {
      instance: "http://panamaportmanager.uatemodal.com",
      clientid: "PCPANAMA",
      title:"Panama Port Manager",
      iconpath:"../../assets/images/panama-favicon.svg",
      cookiebotid:"",
      enablecookiebot:false,
      datablockingmode:'none',
      Portcodes: ["OAK"],
      ExludePregateStatus:["A","I","S","B","E","F"],
      theme: "panama",
      legacypregateurl:"http://pe1appointments.uatemodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl:"http://pe1availability.uatemodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-140684522-1",
      analyticsTrackingId: "UA-140684522-1",
      mybooking_InterfaceId: 61,
      mycontainer_InterfaceId: 62,
      vesselschedule_InterfaceId: 63,
      groupcodes_InterfaceId: 64,
      myjobs_InterfaceId: 65,
      showglobalsearch:false,
      supportmail:"helpdesk@eModal.com",
      emodalvideotutorial: "https://www.emodal.com/Support/HelpLinks.aspx",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired:false,
      paymentcarturl: "http://pe1paymentcart.uatemodal.com",
      cart: "http://pe1paymentcart.uatemodal.com/cart",
      paymentSource: "http://pe1paymentcart.uatemodal.com/PaymentSource",
      userPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "http://pe1paymentcart.uatemodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
      showpregatedate:false,
      showProviderForBarePick:true,
      showterminalstatus:false,
      domainname: ".uatemodal.com",
      showemodalusericon: true,
      versionnumber:version,
      paynowbutton:false,
      hideDomesticTradeType:false,
      hideTradeType: false,
      showexternalvisitref_nbr:false,
      thresholdcount:6000,
      emodalHeaderConfigs: {
        title: "Panama Port Manager",
        logopath: "./assets/images/panama-logo.svg",
        enableCart: false,
        enableNotification: true,
        languagefile:"emodal",
        enableHelp: true,
        enableSetting: true,
        enableEmodalProfileImg: true,
        cartConfigs: {
          cartUrl: "http://pe1paymentcart.uatemodal.com",
          cartIntegrationKey: "35655311-7231-4183-8CAB-0009D7D3E507",
          cart: "http://pe1paymentcart.uatemodal.com/cart",
          cartItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/cartitemsinformation",
          addItemsUrl: "http://pe1paymentcart.uatemodal.com/integration/additems"
        }
      }
    },
    {
      instance: "https://dtr2.uatemodal.com",//"https://demopropassva.emodal.com",
      clientid: "PCPOV",
      title: "Port of Virginia",
      iconpath: "../../assets/images/PoV-favicon.svg",
      cookiebotid: "fd8f05ac-18bf-4680-bce9-c30cfe63445e",
      enablecookiebot: false,
      datablockingmode: 'none',
      Portcodes: ["POV"],
      ExludePregateStatus: ["A", "I", "S", "B", "E", "F"],
      theme: "pov",
      legacypregateurl: "https://demopropassvaappointments.emodal.com/XModuleLanding.aspx?dest=v2Appt%2fMassEdit.aspx",
      ecppayfeeurl: "https://demopropassvaavailability.emodal.com/payfee/Manual",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-90096803-26",
      analyticsTrackingId: "UA-90096803-26",
      mybooking_InterfaceId: 68,
      mycontainer_InterfaceId: 69,
      vesselschedule_InterfaceId: 70,
      groupcodes_InterfaceId: 71,
      myjobs_InterfaceId: 72,
      showglobalsearch: false,
      supportmail: "helpdesk@eModal.com",
      emodalvideotutorial: "https://www.emodal.com/Support/HelpLinks.aspx",
      emodalhelpcenter: "https://emodal.zendesk.com/hc/en-us",
      paymentcarticonrequired: false,
      paymentcarturl: "https://pqpreprod.emodal.com/",
      cart: "https://pqpreprod.emodal.com/cart",
      paymentSource: "https://pqpreprod.emodal.com/PaymentSource",
      userPurchaseHistory: "https://pqpreprod.emodal.com/PurchaseHistory?s=u",
      companyPurchaseHistory: "https://pqpreprod.emodal.com/PurchaseHistory?s=c",
      paymentCartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
      showpregatedate: false,
      showProviderForBarePick: true,
      showterminalstatus: true,
      domainname: ".emodal.com",
      versionnumber: version,
      paynowbutton: false,
      cmsRedirectUri:"https://demopropassva.emodal.com/signin-oidc",
      hideDomesticTradeType: false,
      showexternalvisitref_nbr:false,
      hideTradeType: false,
      thresholdcount: 6000,
      authConfig:
      {
        portcheckurl: "/signin-oidc",
        portchecklogouturl: "/signin-oidc",
        identityserverurl: "http://pe1id.uatemodal.com",
        autouserinfo: true,
        silentrenew: true,
        max_id_token_iat_offset_allowed_in_seconds: 180,
        registrationUri: "http://demoaccount.emodal.com/Registration/Registration/Index?Client_id=",
        postloginroute: '/default',
        responsetype: "code",
        scopessupported: "openid profile sso_auth_api security_web_auth_api sso_custom_endpoint offline_access",
        userinfoendpoint: "http://demoaccount.emodal.com/identity/userinfo",
        expiredays: 5,
        historycleanupoff: true,
        isauthorizedrace_timeout_in_seconds:5,
        useRefreshToken:true
      },
      emodalHeaderConfigs: {
        title: "Port of Virginia",
        logopath: "./assets/images/PoV-logo-appheader.svg",
        enableCart: false,
        enableNotification: true,
        languagefile: "pcpov",
        enableHelp: true,
        enableSetting: true,
        cartConfigs: {
          cartUrl: "https://pqpreprod.emodal.com/",
          cartIntegrationKey: "7F1083BC-FC70-47B1-86AE-97921557F7C8",
          cart: "https://pqpreprod.emodal.com/cart",
          cartItemsUrl: "https://pqpreprod.emodal.com/integration/cartitemsinformation",
          addItemsUrl: "https://pqpreprod.emodal.com/integration/additems"
        },
        portcheckapibaseurl: "https://demoapigateway.emodal.com/PortCheck",
        emodaldatahubapibaseurl: "https://demoapigateway.emodal.com/datahub",
        emodalnotificationapibaseurl: "https://demoapigateway.emodal.com/Notifications",
        emodalpregateapibaseurl: "https://demoapigateway.emodal.com/Pregate",
        emodalsecuritymgrurl: "https://demoapigateway.emodal.com/SecurityMgr/API",
        userinfoendpoint: "http://pe1id.uatemodal.com/identity/userinfo",
      }
    }
  ],
  userprofile: "http://pe1account.uatemodal.com/profile/profile/UserProfile",
  notificationbroadcasrsubject: "Notification Broadcast",
  legacyapprul:"http://test.uatemodal.com",
  ispaymentcartrequired: true,
  isDTRautofillrequired: true,
  isDriverNameautofillrequired:true
};
