import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { APIService } from "../../services/httpservicefactory.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { SelectionModel } from "@angular/cdk/collections";
import { UserViewModel, CustomViewInputModel, ShippingLine, Facility, ITerminalPregatestatus } from "../../Interfaces/customview.interface";
import { ConfirmDialogComponent } from "../confirmdialog/confirmdialog.component";
import { TranslateService } from "../../services/translateservice.service";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { ValidationMethods,TerminalTradeType} from '../../../common/utils/validationmethods';
import { NA } from '../../../common/utils/globalconstants';
import { environment } from '../../../../environments/environment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

export interface ReferenceTypes {
  referencetype_Cd: string;
  referencetype_Dsc: string;
}

export interface GroupcodeStatus {
  groupcodestatus_Cd: string;
  groupcodestatus_Dsc: string;
}

export interface GateorderStatus {
  gateorderstatus_Cd: string;
  gateorderstatus_Dsc: string;
}

export interface GateorderTypes {
  gateordercat_cd: string;
  gateordercat_Dsc: string;
}

export interface BookingStatus {
  bookingstatus_cd: string;
  bkgstatus_desc: string;
}

export interface HoldRelease {
  holdrelease_cd: boolean;
  holdrelease_desc: string;
}

// export interface ContainerTradeType {
//   tradetype_cd: string;
//   tradetype_desc: string;
// }

export interface JobStatus {
  jobstatus_cd: string;
  jobstatus_desc: string;
}

export interface ViewTypes {
  viewtype_cd: string;
  viewtype_desc: string;
}

@Component({
  selector: "app-customviewfilter",
  templateUrl: "./customviewfilter.component.html",
  styleUrls: ["./customviewfilter.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ]
})
export class CustomviewfilterComponent implements OnInit {
  Interface_Uid: string = "";
  errorMessage = "";
  selection = new SelectionModel<any>(true, []);
  customViewModel: CustomViewInputModel;
  _userViewModel: UserViewModel;
  _InitialModel: any;
  _EmptyViewModel: any;
  SavedViews: UserViewModel[];
  SavedNames: string[];
  selectedView: any;
  hasSelectedView: boolean = false;
  checked: any;
  ShowLoader: boolean = false;
  _MaxFilterOptionsheight: any = window.innerHeight - 280;
  _MaxColumnOptionsheight: any = window.innerHeight - 350;
  lUserView_Id: number;
  DrayUnitStatues: any[] = [];
  today: Date = new Date();
  groupcodeStatus: GroupcodeStatus[] = [];
  gateorderStatus: GateorderStatus[] = []
  gateorderType: GateorderTypes[] = [];
  bookingStatus: BookingStatus[] = [];
  referencetypes: ReferenceTypes[] = [];
  lineholdStatus: HoldRelease[] = [];
  containertradetype: TerminalTradeType[] = [];
  jobstatus: JobStatus[] = [];
  PortFacilities: Facility[] = [];
  PreGateStatuses: any[] = [];
  TerminalPregateStatus: ITerminalPregatestatus[] = [];
  ShippingLines: ShippingLine[] = [];
  viewtypes: ViewTypes[] = [];
  viewName: string = "";
  statusDray: boolean = false;
  statusGroupcode: boolean = false;
  statusBooking: boolean = false;
  containertrade: boolean = false;
  TruckingCompanies = [];
  instanceSuffix: string;
  userinfoformat: string;
  hideDomesticTradeType: boolean;
  @HostListener("window:resize") onResize() {
    // guard against resize before view is rendered
    this._MaxFilterOptionsheight = window.innerHeight - 280;
    this._MaxColumnOptionsheight = window.innerHeight - 340;
  }

  constructor(
    public dialog: MatDialog,
    public locale: TranslateService,
    public dialogRef: MatDialogRef<CustomviewfilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomViewInputModel,
    private ApiService: APIService,
    private sharedService: SharedService,
    private router: Router,
    private adapter: DateAdapter<any>,
    public validate: ValidationMethods,
  ) {
    this.customViewModel = data;
    this.adapter.setLocale(this.validate.GetDatePickerLocale());
    dialogRef.disableClose = true;
    this.userinfoformat = validate.AppDateTimeFormats.Date;
    this._userViewModel = JSON.parse(JSON.stringify(data.UserInterfaceView));
    this.Interface_Uid = data.Interface_Uid;
    if (
      !this._userViewModel.Default_Flg &&
      !!this._userViewModel.FacilityView_Flg &&
      this._userViewModel.UserView_Id != 0
    ) {
      this._userViewModel.UserView_Json = JSON.parse(
        this._userViewModel.DefaultView_Json
      );
    }
    this.LoadInitialColumnOptions();
    this.SavedViews = data.UserSavedViews;
    this.SavedNames = this.GetSavedViewNames();
    if (this._userViewModel && this._userViewModel.UserView_Nm) {
      this.selectedView = this._userViewModel.UserView_Nm;
    }

    this._InitialModel = this._userViewModel;
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.close();
    });
    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.instanceSuffix = "_" + instanceConfig.clientid;
    this.loadInitialData();
  }
  ngOnInit() {
    this.viewName = this.router.url.replace("/", "");
    let element = document.getElementsByClassName("cdk-overlay-backdrop")[0];
    this.hideDomesticTradeType = environment.portsconfigs.find(a => a.instance == window.location.origin).hideDomesticTradeType;
    element.setAttribute("style", "opacity:1;");
    this.referencetypes.push(
      { referencetype_Cd: "", referencetype_Dsc: "" },
      { referencetype_Cd: "BKG", referencetype_Dsc: "Booking Number" },
      { referencetype_Cd: "GCD", referencetype_Dsc: "Group Code" }
    );

    this.groupcodeStatus.push(
      { groupcodestatus_Cd: "A", groupcodestatus_Dsc: "ACTIVE" },
      { groupcodestatus_Cd: "N", groupcodestatus_Dsc: "NOT FOUND" },
      { groupcodestatus_Cd: "H", groupcodestatus_Dsc: "HOLD" }
    );
    this.gateorderStatus.push(
      { gateorderstatus_Cd: "A", gateorderstatus_Dsc: "ACTIVE" },
      { gateorderstatus_Cd: "N", gateorderstatus_Dsc: "NOT FOUND" },
    );
    this.gateorderType.push(
      { gateordercat_cd: "P", gateordercat_Dsc: "PICK" },
      { gateordercat_cd: "D", gateordercat_Dsc: "DROP" },
    );
    this.bookingStatus.push(
      { bookingstatus_cd: "F", bkgstatus_desc: "FOUND" },
      { bookingstatus_cd: "N", bkgstatus_desc: "NOT FOUND" }
    );
    this.lineholdStatus.push(
      { holdrelease_cd: null, holdrelease_desc: "" },
      { holdrelease_cd: true, holdrelease_desc: "Yes" },
      { holdrelease_cd: false, holdrelease_desc: "No" },
    );
    // this.containertradetype.push(
    //   { tradetype_cd: "I", tradetype_desc: "IMPORT" },
    //   { tradetype_cd: "X", tradetype_desc: "EXPORT" }
    // );
    // if (!this.hideDomesticTradeType) {
    //   this.containertradetype.push(
    //     { tradetype_cd: "D", tradetype_desc: "DOMESTIC" }
    //   );
    // }
    this.containertradetype.push(
      { tradeType_Cd: "N/A", display_Dsc: "N/A", tradeType_Nm:null,active:true }
    );
    this.jobstatus.push(
      { jobstatus_cd: "X", jobstatus_desc: "CANCELLED" },
      { jobstatus_cd: "W", jobstatus_desc: "PENDING" },
      { jobstatus_cd: "A", jobstatus_desc: "ACCEPTED" },
      { jobstatus_cd: "S", jobstatus_desc: "ASSIGNED" },
      { jobstatus_cd: "I','P','D", jobstatus_desc: "IN PROGRESS" },
      { jobstatus_cd: "C", jobstatus_desc: "COMPLETED" },
    )
    if (this.viewName == "mycontainer") {
      this.viewtypes.push(
        { viewtype_cd: "C", viewtype_desc: "Shared Containers" },
        { viewtype_cd: "U", viewtype_desc: "My Containers" }
      );
    }
    if (this.viewName == "mybooking") {
      this.viewtypes.push(
        { viewtype_cd: "C", viewtype_desc: "Shared Bookings" },
        { viewtype_cd: "U", viewtype_desc: "My Bookings" }
      );
    }
    if (this.viewName == "mygroupcodes") {
      this.viewtypes.push(
        { viewtype_cd: "C", viewtype_desc: "Shared Group Codes" },
        { viewtype_cd: "U", viewtype_desc: "My Group Codes" }
      );
    }
if(this._userViewModel.UserView_Json.Filters['ViewType'] && this._userViewModel.UserView_Json.Filters['ViewType'].Visible_Flg==true && this._userViewModel.UserView_Json.Filters['ViewType'].Selected_Value==""){
  this._userViewModel.UserView_Json.Filters['ViewType'].Selected_Value = ['U'];
}
   
  }

  loadInitialData() {
    this.ShowLoader = true;
    this.ApiService.UserInfo();
    this.ShowLoader = false;
  }

  ViewChange(name: string) {
    let item = this.SavedViews.find(i => i.UserView_Nm == name);
    if (typeof item.UserView_Json == "string")
      item.UserView_Json = JSON.parse(item.UserView_Json);
    else item.UserView_Json = JSON.parse(item.DefaultView_Json);
    this._userViewModel = item;
    this.customViewModel.UserInterfaceView = item;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this._userViewModel.UserView_Json.ColumnOptions,
      event.previousIndex,
      event.currentIndex
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    this.LoadInitialColumnOptions();
    const numSelected = this.selection.selected.length;
    const numRows = this._userViewModel.UserView_Json.ColumnOptions.length;
    if (numSelected === numRows) {
      this.checked = true;
      return true;
    } else return false;
  }

  SelectAll(value: boolean) {
    if (value) {
      for (let element of this._userViewModel.UserView_Json.ColumnOptions) {
        this.selection.select(element);
        element.Visible_Flg = true;
      }
    } else {
      this.selection.clear();
      for (let element of this._userViewModel.UserView_Json.ColumnOptions) {
        element.Visible_Flg = false;
      }
    }
  }

  LoadInitialColumnOptions() {
    this.selection.clear();
    for (let element of this._userViewModel.UserView_Json.ColumnOptions) {
      if (element.Visible_Flg) this.selection.select(element);
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.SelectAll(false) : this.SelectAll(true);
  }

  ResetAllFields() {
    this._userViewModel = JSON.parse(
      JSON.stringify(this.customViewModel.AppInterfaceView)
    );
    this.selectedView = undefined;
    if(this._userViewModel.UserView_Json.Filters['ViewType'] && this._userViewModel.UserView_Json.Filters['ViewType'].Visible_Flg==true && this._userViewModel.UserView_Json.Filters['ViewType'].Selected_Value==""){
      this._userViewModel.UserView_Json.Filters['ViewType'].Selected_Value = ['U'];
    }
  }

  RemoveView(name: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: this.locale.data.custom_view_delete_message,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "deletepanelclass"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") {
        this.ShowLoader = true;
        this.lUserView_Id = 0;
        this.lUserView_Id = this.SavedViews.find(
          i => i.UserView_Nm == name
        ).UserView_Id;
        this.ApiService.deleteUserViewById(this.lUserView_Id).subscribe(
          data => {
            let interfaceName = this.router.url.replace("/", "");
            this.selectedView = undefined;
            this._userViewModel = this.customViewModel.AppInterfaceView;
            this.loadUserViews(
              this.customViewModel,
              this.lUserView_Id,
              interfaceName
            );
          },
          error => {
            this.errorMessage = <any>error;
            this.ShowLoader = false;
          }
        );
      }
    });
  }

  applyView(): CustomViewInputModel {
    this.customViewModel.UserInterfaceView = JSON.parse(
      JSON.stringify(this._userViewModel)
    );
    return this.customViewModel;
  }

  loadUserViews(
    customModel: CustomViewInputModel,
    IUserView_Id: number,
    InterfaceName: string
  ) {
    if (customModel.UserSavedViews.length > 0) {
      let deletedViews = customModel.UserSavedViews.find(
        i => i.UserView_Id == IUserView_Id
      );
      const index: number = this.customViewModel.UserSavedViews.indexOf(
        deletedViews
      );
      this.customViewModel.UserSavedViews.splice(index, 1);

      this.customViewModel.UserSavedViews = this.customViewModel.UserSavedViews;
      this.SavedViews = this.customViewModel.UserSavedViews;
      if (this.SavedViews.length > 0) {
        let filterValue = this.SavedViews.find(x => x.Default_Flg == true);
        if (!!filterValue) {
          this._userViewModel = filterValue;
          this.customViewModel.UserInterfaceView = JSON.parse(
            JSON.stringify(this._userViewModel)
          );
        }
        else {
          this.customViewModel.UserInterfaceView = JSON.parse(
            JSON.stringify(this._userViewModel)
          );
        }
      } else {
        this._userViewModel = this.customViewModel.AppInterfaceView;
        this.customViewModel.UserInterfaceView = JSON.parse(
          JSON.stringify(this._userViewModel)
        );
      }

      this.SavedNames = this.GetSavedViewNames();

      localStorage.setItem(
        "customView_" + InterfaceName + this.instanceSuffix,
        JSON.stringify(this.customViewModel)
      );
    }
    this.ShowLoader = false;
    this.dialogRef.close(this.customViewModel);
  }

  GetSavedViewNames(): string[] {
    let names: string[] = [];
    for (let element of this.SavedViews) {
      names.push(element.UserView_Nm);
    }
    return names;
  }
  DateChanged(event: MatDatepickerInputEvent<Date>, date) {
    if (event.value == null) {
      date.targetElement.value = "";
    }
    else {
      date.targetElement.value = this.validate.handleInputFieldDate(event.value);
      if (this._userViewModel.UserView_Json.Filters['FromDate'].Selected_Value == null || this._userViewModel.UserView_Json.Filters['FromDate'].Selected_Value === "") {
        this._userViewModel.UserView_Json.Filters['FromDate'].Selected_Value = this._userViewModel.UserView_Json.Filters['ToDate'].Selected_Value;
      }
    }
  }
}
