import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { APIService } from "../../shared/services/httpservicefactory.service";
import { ValidationMethods } from "../../common/utils/validationmethods";
import { log } from "util";
import {
  SharedService,
  IInterfacesAccess
} from "../../shared/services/shared.service";
import { url } from "inspector";

@Injectable()
export class AuthGuardService implements CanActivate {
  expiredDate: Date;
  constructor(
    private router: Router,
    private aPIService: APIService,
    private oidcSecurityService: OidcSecurityService,
    private cookieService: CookieService,
    private validate: ValidationMethods,
    private sharedService: SharedService
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    let x = this.oidcSecurityService.getToken();
    let y = this.oidcSecurityService.getIdToken();
    let z = this.oidcSecurityService.getState();

    return this.oidcSecurityService.checkAuth().pipe(
      map((isAuthorized: boolean) => {
        if (isAuthorized) {
          console.log('isAuthorized' + isAuthorized);
        let instancedomain=environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name")).domainname;
          this.expiredDate = new Date();
          this.expiredDate.setDate(this.expiredDate.getDate() + environment.expiredays);
          if (route.url[0].path.toLowerCase() != "logout") {
            let ref_token=this.oidcSecurityService.getRefreshToken();
            this.cookieService.set(
              "AuthCookie",
              JSON.stringify({
                bearer: this.oidcSecurityService.getToken(),
                refresh_token: ref_token ? ref_token : "" ,
                client_id: localStorage.getItem("appInstance_Name")
              }),
              this.expiredDate,
              "/",
              instancedomain,
              false,
              "Lax"
                );
          }
          if(route.url[0].path.toLowerCase() != "logout"){
            this.cookieService.set(
              "AuthToken",
              JSON.stringify(this.oidcSecurityService.getToken()),
              this.expiredDate,
                "/",
                instancedomain,
                false,
                "Lax"
                );
          }
          

          let isAccess: boolean = false;
          if (route.url[0].path == "signin-oidc" || route.url[0].path.toLowerCase() == "logout") {
            isAccess = true;
            return isAccess;
          } else if (this.HasPermission("/" + route.url[0].path)) {
            isAccess = true;
          } else {
            if (route.url[0].path == "adminsettings") {
              isAccess = true;
              return isAccess;
            } else {
              this.router.navigate(["/forbidden"]);
              return false;
            }
          }
          return isAccess;
        }
        
        this.router.navigate(["/login"]);
        return false;
      })
    );
  }

  HasPermission(path: string): boolean {
    if(window.location.origin.includes("http://localhost:55040")){//for dev environment
      return true;
    }
    else{
      this.sharedService.accessInterfaces = [];
      if (!localStorage.getItem("userIntefaces"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)) {
        return true;
      }
      this.sharedService.accessInterfaces = JSON.parse(
        localStorage.getItem("userIntefaces"+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
      );
      let hasPermission: boolean = false;
      if (
        !!this.sharedService.accessInterfaces &&
        this.sharedService.accessInterfaces.length > 0
      ) {
        let accessData = this.sharedService.accessInterfaces.filter(
          x => {
            if(x.url){
              console.log(x.url);
              console.log(path);
              return x.url.includes(path);
            }
            return false;
          }
        );
        if (accessData.length > 0) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
      }
     console.log('haspermission:' + hasPermission);
     return hasPermission;
    }
    
  }
  getSessionData() {
    if (this.validate.isEmptyString(localStorage.getItem("appInstance_Name"))) {
      localStorage.setItem("authorizationResult_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationResult_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("session_state_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("session_state_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("authorizationDataIdToken_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationDataIdToken_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("_isAuthorized_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("_isAuthorized_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("authorizationData_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationData_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("userData_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("userData_" + localStorage.getItem("appInstance_Name")));
    }
  }
}
