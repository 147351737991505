import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { httpOptions, apiurls } from '../models/globalconstants';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
//import { IMoveFieldMask } from '../Interfaces/fieldmap.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentCartService {
  public cartitems = [];
  isCartOpen: boolean = false;
  moveTypes = [];
  
  //movefieldmask: IMoveFieldMask[] = [];
  pinConfigurations = [];
  constructor(private httpClient: HttpClient) { }
  // getmovetypes(): Observable<IMoveTypes[]> {
  //   return this.httpClient.get<IMoveTypes[]>(environment.emodaldatahubapibaseurl + apiurls.movetypesapi,
  //     httpOptions
  //   ).pipe(tap(data => ""), catchError(this.handleError));
  // }
  
  // getmovefieldmask(): Observable<IMoveFieldMask[]> {
  //   return this.httpClient.get<IMoveFieldMask[]>(environment.emodaldatahubapibaseurl + apiurls.movefieldmaskapi,
  //     httpOptions
  //   ).pipe(tap(data => {
  //   }), catchError(this.handleError));
  // }

  

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
