<!-- SCPA LANDING PAGE -->
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">



<div *ngIf="appInstance == 'PCSCPA' ">
  <div id="my-splash-screen">
  </div>
  <!-- <mat-toolbar class='fixed-toolbar'>
    <div class="logo"></div>
    <span class="scpa-headercontainer">
        <span class="headertext" >
          <img style="height: 18px; vertical-align: middle;" src="../../assets/images/pcscpa/training.svg">
          <span style="margin-left: 10px;vertical-align: middle;">Training Courses Available</span>
          <button (click)="openTrainingPage()" target="_blank" mat-raised-button color="warn" style="margin-left: 10px;">CLICK HERE</button> 
          <a style="margin-left: 40px;">Have an account?</a>
          <a (click)="login()" class="a_login">Log In</a>
          <span style="font-size: 22px">|</span>
          <a (click)="register()" class="a_login">Register Now</a>
        </span>
    </span>
  </mat-toolbar>
  <div   [style.height.px]='innerheight' [style.max-height.px]='innerheight'>
    <landing-template *ngIf="appInstance" appInstance="{{appInstance}}"></landing-template>
  </div>
  
  
  <footer class="footer">
    <div class="support">Logistics Solutions Center: 843-579-4433 or SolutionsCenter@SCSPA.com. </div>
    <div class="footer-logo">
        <img class="eModalLogo" src="../../assets/images/eModal-logo.png" alt="footerlogo" />
    </div>
  </footer> -->
</div>

<!-- EMODAL LANDING PAGE -->
<div *ngIf="appInstance == 'PCEMODAL'">
  <div id="my-splash-screen">
  </div>     

  <!-- <mat-card class="headercard">
    <mat-card-content>
        <div class="emodal-headercontainer">
            <div class="container2">
                <a (click)="login()" class="a_login">Log In</a>
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="contentcard">
    <mat-card-content>
        <div class="titleheader">
            <div>
              <img src="assets/images/eModal-logo.svg" style="height:90px" alt="Emodal Logo">
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  <div>
    <img class="loginimg" src="assets/images/login_bg.jpg" alt="Landing Background Image">
  </div> -->
  
</div>

<!-- GENESEE & WYOMING LANDING PAGE  style="display:none"-->
<div  style="display: none;" *ngIf="appInstance == 'PCGWRR'">
  <mat-card class="headercard">
    <mat-card-content>
        <div class="gw-headercontainer">
            <div class="container2">
                <a (click)="login()" class="a_login">Log In</a>
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="contentcard" style="background: none;">
    <mat-card-content>
        <div class="titleheader">
            <div style="padding-top: 308px;">
              <img src="assets/images/gw-loginimg.png" style="height: 318px;" alt="G&W Logo">
            </div>
        </div>
    </mat-card-content>
  </mat-card>

  <div style="height: 785px;">
    <img class="loginimg" src="assets/images/gw-bgimg.jpg"  alt="Landing Background Image">
  </div>
</div>


<!-- PTP(NYNJ) LANDING PAGE -->
<div style="display: none;" *ngIf="appInstance == 'PCNYNJ'">
  <mat-card class="headercard">
    <mat-card-content>
        <div class="emodal-headercontainer">
            <div class="container2">
                <a (click)="login()" class="a_login">Log In</a>
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="contentcard" style="background: none;">
    <mat-card-content>
        <!-- <div class="titleheader">
            <div style="padding-top: 308px;">
              <img src="assets/images/gw-loginimg.png" style="height: 318px;" alt="G&W Logo">
            </div>
        </div> -->
    </mat-card-content>
  </mat-card>

  <div style="max-height: 800px;overflow: auto;">
    <img class="loginimg" src="assets/images/ptp-landingimg.png"  alt="Landing Background Image">
  </div>
</div>

<!-- POV LANDING PAGE -->
<div style="display: none;" *ngIf="appInstance == 'PCPOV'">
  <mat-card class="headercard">
    <mat-card-content>
        <div class="emodal-headercontainer">
            <div class="container2">
                <a (click)="login()" class="a_login">Log In</a>
            </div>
        </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="contentcard" style="background: none;">
    <mat-card-content>
        <!-- <div class="titleheader">
            <div style="padding-top: 308px;">
              <img src="assets/images/gw-loginimg.png" style="height: 318px;" alt="G&W Logo">
            </div>
        </div> -->
    </mat-card-content>
  </mat-card>

  <div style="max-height: 800px;overflow: auto;">
    <img class="loginimg" src="assets/images/ptp-landingimg.png"  alt="Landing Background Image">
  </div>
</div>
<!-- PANAMA LANDING PAGE -->
<div *ngIf="appInstance == 'PCPANAMA'">
  
  <mat-toolbar class='fixed-toolbar'>
    <div class="logo"></div>
    <span class="scpa-headercontainer">
        <span class="headertext" >
          <img style="height: 18px; vertical-align: middle;" src="../../assets/images/pcscpa/training.svg">
          <span style="margin-left: 10px;vertical-align: middle;">Training Courses Available</span>
          <button (click)="openTrainingPage()" target="_blank" mat-raised-button color="warn" style="margin-left: 10px;">CLICK HERE</button> 
          <a style="margin-left: 40px;">Have an account?</a>
          <a (click)="login()" class="a_login">Log In</a>
          <span style="font-size: 22px">|</span>
          <a (click)="register()" class="a_login">Register Now</a>
        </span>
    </span>
  </mat-toolbar>
  <div   [style.height.px]='innerheight' [style.max-height.px]='innerheight'>
    <landing-template *ngIf="appInstance" appInstance="{{appInstance}}"></landing-template>
  </div>
  
  
  <!-- <footer class="footer">
    <div class="support">Logistics Solutions Center: 843-579-4433 or SolutionsCenter@SCSPA.com. </div>
    <div class="footer-logo">
        <img class="eModalLogo" src="../../assets/images/eModal-logo.png" alt="footerlogo" />
    </div>
  </footer> -->
  
</div>
